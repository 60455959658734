<h3 mat-dialog-title>{{ 'images.upload.edit_image' | translate }}</h3>
<div mat-dialog-content>
  <div class="cropper-wrap">
    <div class="spinner my-3" *ngIf="!showCropper">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <p *ngIf="failedImageLoading">{{ 'images.upload.error_load' | translate }}</p>
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [containWithinAspectRatio]="false"
      [aspectRatio]="aspectRatio"
      [cropperMinWidth]="cropperMinWidth"
      [resizeToWidth]="resizeToWidth"
      [onlyScaleDown]="true"
      [roundCropper]="false"
      [transform]="transform"
      [style.display]="showCropper ? null : 'none'"
      [format]="format"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</div>
<div mat-dialog-actions>
  <div class="d-flex flex-row button-wrapper">
    <div>
      <button mat-icon-button [matTooltip]="'images.upload.zoom_out' | translate" (click)="zoomOut()" class="mx-1">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button mat-icon-button [matTooltip]="'images.upload.zoom_in' | translate" (click)="zoomIn()" class="mx-1">
        <mat-icon>zoom_in</mat-icon>
      </button>
    </div>
    <div class="ml-auto justify-content-end">
      <button mat-flat-button color="primary" (click)="onConfirm()" class="mx-1">
        {{ 'common.done' | translate }}
      </button>
      <button mat-button mat-dialog-close>
        {{ 'common.cancel' | translate }}
      </button>
    </div>
  </div>
</div>
