import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/bases/base.component';
import { formatDate } from '@app/shared/helpers/date';
import { EventLog } from '@app/shared/models/event-log.model';
import { LanguageService } from '@app/shared/services/language/language.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cash-register-status',
  templateUrl: './cash-register-status.component.html',
  styleUrls: ['./cash-register-status.component.scss'],
})
export class CashRegisterStatusComponent extends BaseComponent implements OnInit {
  @Input() eventLog: Observable<EventLog>;

  log: EventLog;
  user: string;
  state: string;
  timestamp: string;
  text: string;

  translationsByState = {
    open: _('dashboard.cash_register.opened_detail'),
    close: _('dashboard.cash_register.closed_detail'),
  };

  constructor(private languageService: LanguageService) {
    super();
  }

  ngOnInit() {
    if (this.eventLog != null) {
      this.eventLog.pipe(takeUntil(this.onDestroy$)).subscribe(eventLog => {
        this.log = eventLog;
        if (eventLog != null) {
          this.state = eventLog.eventBundle.state;
          this.text = this.translationsByState[this.state];
          this.user = eventLog.config.value;
        }
      });
    }
  }

  formatTimestamp() {
    return formatDate({
      date: this.log.eventTime,
      format: 'medium',
      locale: this.languageService.getCurrentLocale(),
    });
  }
}
