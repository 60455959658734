import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BaseGraphView } from './base.graph-view';

export abstract class BaseRevenueGraphView extends BaseGraphView {
  /**
   * Sorted data and if number of items exceeds dataLimit them reduce and group them into a single "Others" data
   */
  protected limitAndReorderData<T>(translateService: TranslateService, data: T[], dataLimit: number = 7): T[] {
    let sortedData = data.sort((a: any, b: any) => a.revenue && b.revenue ? 
      b.revenue - a.revenue : b.revenue ? b.revenue : 0 - a.revenue ? a.revenue : 0
    );
    if (sortedData.length <= dataLimit) {
      return sortedData;
    }

    // group and sum data with index > dataLimit as "Others"
    const slicedData = sortedData.slice(dataLimit);
    const revenueOther =
      slicedData.length > 1
        ? slicedData.reduce((a: T, b: T) => {
            return {
              name: translateService.instant(_('graphs.others')),
              revenue: (a as any).revenue + (b as any).revenue,
            } as any;
          })
        : slicedData[0];

    sortedData = sortedData.slice(0, dataLimit - 1);
    sortedData.push(revenueOther as any);
    return sortedData.sort((a: any, b: any) => b.revenue - a.revenue);
  }
}
