import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { OrganisedGraphData } from '@app/dashboard/interfaces/graph.interface';
import { GraphOrganiserService } from '@app/dashboard/services/graph-organiser/graph-organiser.service';

/**
 * Organise graphs by setting their visibilities and order of appearance.
 */
@Component({
  selector: 'app-graph-organiser-dialog',
  templateUrl: './graph-organiser-dialog.component.html',
  styleUrls: ['./graph-organiser-dialog.component.scss'],
})
export class GraphOrganiserDialogComponent implements OnInit {
  graphs: OrganisedGraphData[] = [];
  constructor(
    private dialogRef: MatDialogRef<GraphOrganiserDialogComponent>,
    private graphOrganiserService: GraphOrganiserService
  ) {}

  ngOnInit() {
    this.loadGraphs();
  }

  private loadGraphs() {
    this.graphOrganiserService.getGraphs().then(result => (this.graphs = result));
  }

  drop(event: CdkDragDrop<OrganisedGraphData>) {
    moveItemInArray(this.graphs, event.previousIndex, event.currentIndex);
  }

  onSave() {
    this.graphOrganiserService.saveGraphs(this.graphs).then(() => {
      this.dialogRef.close(true);
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
