import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import {SearchOption} from '@app/shared/components/search-table/search-table.component';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  @Input('title') set title(value: string) {
    this.title$.next(value || '');
  }
  // @Output() onSearchPage = new EventEmitter<SearchOption>();

  get title() {
    return this.title$.getValue();
  }

  title$ = new BehaviorSubject<string>('');

  constructor() {}

  ngOnInit() {}

  // onSearch(results: SearchOption) {
  //   this.onSearchPage.emit(results);
  // }
}
