import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DevicesService } from '@app/shared/services/devices/devices.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdentityIdInterceptor implements HttpInterceptor {
  constructor(private devicesService: DevicesService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.devicesService.identityId) {
      request = request.clone({
        setHeaders: {
          'x-identity-id': this.devicesService.identityId,
        },
      });
    }

    return next.handle(request);
  }
}
