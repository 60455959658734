import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { SessionService } from '@app/core/services/session/session.service';
import { getBrowserGMTOffset } from '@app/shared/helpers/date';
import { getCurrentLanguageCode } from '@app/shared/helpers/language';
import { saveToLocalStorage } from '@app/shared/helpers/local-storage';
import { getCurrentLocale } from '@app/shared/helpers/locale';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private session: SessionService
  ) {}

  public langagueObservable = new BehaviorSubject(this.getCurrentLocale());

  /**
   * Changes from one language to another
   * @param language locale id, language to use
   * @param disableReload boolean if disable session reload page
   */
  public switchLanguage(code: string, disableReload = false) {
    saveToLocalStorage('lang', code);
    this.translateService.use(code);
    this.adapter.setLocale(code);
    this.langagueObservable.next(this.getCurrentLocale());
    if (disableReload) {
      this.session.reloadPage();
    }
  }

  public getCurrentLanguageCode(): string {
    return getCurrentLanguageCode();
  }

  public loadSelectedLanguageToken(): string {
    return `language.${getCurrentLanguageCode()}`;
  }

  public getLanguageListCodes() {
    return environment.languageCodes;
  }

  public getCurrentLocale(): string {
    return getCurrentLocale();
  }

  public getBrowserGMTOffset(): string {
    return getBrowserGMTOffset();
  }
}
