/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "../../node_modules/ng-http-loader/ng-http-loader.ngfactory";
import * as i5 from "ng-http-loader";
import * as i6 from "@angular/common";
import * as i7 from "./app.component";
import * as i8 from "ngx-cookieconsent";
import * as i9 from "./shared/services/language/language.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./shared/services/navigation/navigation.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "lazy-route-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("app.loading")); _ck(_v, 2, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ng-http-loader", [], null, null, null, i4.View_NgHttpLoaderComponent_0, i4.RenderType_NgHttpLoaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.NgHttpLoaderComponent, [i5.PendingRequestsInterceptor, i5.SpinnerVisibilityService], { backgroundColor: [0, "backgroundColor"], debounceDelay: [1, "debounceDelay"], filteredUrlPatterns: [2, "filteredUrlPatterns"], minDuration: [3, "minDuration"], spinner: [4, "spinner"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.backgroundColor; var currVal_1 = _co.debounceDelay; var currVal_2 = _co.filteredUrlPatterns; var currVal_3 = _co.minDuration; var currVal_4 = _co.spinkit.skCubeGrid; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.showLazyRouteLoader; _ck(_v, 5, 0, currVal_5); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i8.NgcCookieConsentService, i9.LanguageService, i10.Title, i2.TranslateService, i3.Router, i11.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
