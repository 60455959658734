import { Injectable } from '@angular/core';
import { BaseGraphView } from '@app/dashboard/graphs/base.graph-view';
import { GraphFilterData, OrganisedGraphData } from '@app/dashboard/interfaces/graph.interface';
import { DEFAULT_ORGANISED_GRAPHS } from '@app/dashboard/organised-graphs.data';
import { getFromLocalStorage, saveToLocalStorage } from '@app/shared/helpers/local-storage';

/**
 * Service to retrieve and store organised graph views from and into browser's storage.
 */
@Injectable({
  providedIn: 'root',
})
export class GraphOrganiserService {
  constructor() {}

  getGraphs(): Promise<OrganisedGraphData[]> {
    // get default registered graphs first
    const defaultGraphs = DEFAULT_ORGANISED_GRAPHS;

    // get saved graphs preferences
    const savedGraphs = JSON.parse(getFromLocalStorage('graphs')) as OrganisedGraphData[];

    // merge both data (order and visibility)
    if (savedGraphs) {
      const result: OrganisedGraphData[] = [];

      savedGraphs.forEach(graph => {
        const graphView = this.getGraphViewInstance(defaultGraphs, graph.id);

        // assign BaseGraphView instance, only if graphView != null
        if (graphView) {
          graph.graphView = graphView;
          result.push(graph);
        }
      });

      // there is possibility that new graph(s) are added and not yet saved
      // into browser's storage yet.
      defaultGraphs.forEach(defaultGraph => {
        const find = result.find(resultGraph => resultGraph.id === defaultGraph.id);
        if (!find && defaultGraph.graphView) {
          result.push(defaultGraph);
        }
      });

      return Promise.resolve(result);
    } else {
      this.saveGraphs(defaultGraphs);
    }

    return Promise.resolve(defaultGraphs);
  }

  /**
   * Return BaseGraphView instance from the given array of OrganisedGraphData,
   * with id equals to the given id.
   * @param graphs array of OrganisedGraphData
   * @param id string of registered graph's id
   */
  private getGraphViewInstance(graphs: OrganisedGraphData[], id: string): BaseGraphView | null {
    if (graphs) {
      const find = graphs.find(graph => graph.id === id);
      if (find) {
        return find.graphView;
      }
    }
    return null;
  }

  /**
   * Save graphs preferences into localStorage
   */
  saveGraphs(graphs: OrganisedGraphData[]): Promise<any> {
    // copy values so it won't mess with the given graphs parameter
    const toBeSaved: OrganisedGraphData[] = [];

    // we won't save BaseGraphView instance to localStorage
    graphs.forEach(g =>
      toBeSaved.push({
        id: g.id,
        column: g.column,
        visibility: g.visibility,
      })
    );

    saveToLocalStorage('graphs', JSON.stringify(toBeSaved));
    return Promise.resolve();
  }

  /**
   * Get saved filters that were set on graph widgets from localStorage
   * @returns array of GraphFilterData
   */
  getGraphsFilter(): GraphFilterData[] {
    const filters = JSON.parse(getFromLocalStorage('graphsFilter')) as GraphFilterData[];
    return filters ? filters : [];
  }

  /**
   * Get saved filters for a graph widget based on the graph's id
   * @param id ID of the graph set on OrganisedGraphData
   * @returns GraphFilterData
   */
  getGraphsFilterById(id: string): GraphFilterData | null {
    return this.getGraphsFilter().find(item => item.id === id);
  }

  /**
   * Save active filters of a graph widget into localStorage
   * @param data GraphFilterData to be saved
   */
  saveGraphsFilterData(data: GraphFilterData) {
    const filters = this.getGraphsFilter();
    const graph = filters.find(item => item.id === data.id);
    if (graph != null) {
      // merge with existing
      graph.filters = { ...graph.filters, ...data.filters };
    } else {
      filters.push(data);
    }
    saveToLocalStorage('graphsFilter', JSON.stringify(filters));
  }

  /**
   * Save a specific additional graph filter into localStorage
   * @param id ID of the saved graph filters - which should be the OrganisedGraphData.id
   * @param filterId the ID or key of the filter
   * @param filterValue value of the filter
   */
  saveGraphsFilter(id: string, filterId: string, filterValue: string) {
    let graph = this.getGraphsFilterById(id);
    if (graph != null) {
      if (!graph.filters) {
        graph.filters = {};
      }
    } else {
      graph = { id, filters: {} };
    }
    graph.filters[filterId] = filterValue;
    this.saveGraphsFilterData(graph);
  }
}
