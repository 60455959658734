<mat-card class="select-device-card" *ngIf="loaded">
  <div class="d-flex justify-content-between py-2 border-bottom">
    <mat-label class="device-info-label">{{
      'devices.current_id' | translate
    }}</mat-label>
    <span class="device-info-value">{{ selectedDeviceId }}</span>
  </div>
  <div class="d-flex justify-content-between py-2 border-bottom">
    <mat-label class="device-info-label">{{
      'devices.current_app_ver' | translate
    }}</mat-label>
    <span class="device-info-value">{{ deviceAppVersion }}</span>
  </div>
  <app-form-select-search
    [label]="'devices.select_device'"
    [options]="deviceOptions"
    [control]="formControl"
    [errorTranslations]="errorTranslations"
    (selectionChange)="selectDevice($event)"
  >
  </app-form-select-search>
</mat-card>
