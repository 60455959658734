import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
const ɵ0 = () => import("./core/core.module.ngfactory").then(m => m.CoreModuleNgFactory), ɵ1 = () => import("./login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ2 = () => import("./password/password.module.ngfactory").then(m => m.PasswordModuleNgFactory);
const routes = [
    {
        path: '',
        component: AppComponent,
        loadChildren: ɵ0,
    },
    {
        path: 'login',
        loadChildren: ɵ1,
    },
    {
        path: 'set-password',
        loadChildren: ɵ2,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2 };
