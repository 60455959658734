<!-- 'classic' style -->
<mat-toolbar color="primary" [ngClass]="classNames" *ngIf="headerStyle === 'classic'">
  <div class="navbar__part">
    <button mat-icon-button (click)="toggleMenu()">
      <mat-icon *ngIf="!menuOpened">menu</mat-icon>
      <mat-icon *ngIf="menuOpened">menu_open</mat-icon>
    </button>
    <a [routerLink]="['/dashboard']" class="navbar__title_link">
      <span class="app-logo app-login--menu"></span>
      <span class="app-title" *ngIf="showAppTitle">
        {{ 'app_title' | translate }}
      </span>
    </a>
  </div>
  <div class="navbar__part" *ngIf="pageTitleMode === 'header'">
    <a class="page-title as-link" [routerLink]="currentUrl">{{ title$ | async }}</a>
  </div>
  <div class="navbar__part">
    <button class="clear-outline" mat-button [matMenuTriggerFor]="languageDropdown">
      <mat-icon class="icon_spacer">language</mat-icon>
      <span class="small-screen-lang">{{ languageService.getCurrentLanguageCode() }}</span>
      <span class="big-screen-lang">{{ 'language.' + languageService.getCurrentLanguageCode() | translate }}</span>
    </button>
    <mat-menu #languageDropdown="matMenu">
      <button
        *ngFor="let code of languageService.getLanguageListCodes()"
        (click)="languageService.switchLanguage(code)"
        class="clear-outline"
        mat-menu-item
      >
        {{ 'language.' + code | translate }}
      </button>
    </mat-menu>

    <button class="user-menu-button" mat-button [matMenuTriggerFor]="userDropdown">
      <mat-icon>account_circle</mat-icon>
      <span class="username">{{ authService.username }}</span>
      <mat-icon class="icon_spacer">arrow_drop_down</mat-icon>
    </button>

    <mat-menu #userDropdown="matMenu">
      <button class="clear-outline" mat-menu-item (click)="logout()" [routerLink]="['/login']">
        <mat-icon>exit_to_app</mat-icon>
        {{ 'common.logout' | translate }}
      </button>

      <!-- <button class="clear-outline" mat-menu-item [routerLink]="['/user-profile']">
        <mat-icon>security</mat-icon>
        {{ "common.profile" | translate }}
      </button> -->
    </mat-menu>
  </div>
</mat-toolbar>

<!-- 'modern' or 'modern-dark' style -->
<mat-toolbar [ngClass]="classNames" *ngIf="headerStyle === 'modern' || headerStyle === 'modern-dark'">
  <div class="navbar__part">
    <a [routerLink]="['/dashboard']" class="navbar__title_link">
      <span class="app-logo app-login--menu"></span>
      <span class="app-title" *ngIf="showAppTitle">
        {{ 'app_title' | translate }}
      </span>
    </a>
  </div>
  <div class="navbar__part" *ngIf="pageTitleMode === 'header'">
    <a class="page-title as-link" [routerLink]="currentUrl">{{ title$ | async }}</a>
  </div>
  <div class="navbar__part">
    <!-- show/hide left sidenav -->
    <button mat-icon-button type="button" (click)="toggleMenu()" [matTooltip]="'header.show_hide_sidenav' | translate">
      <mat-icon *ngIf="!menuOpened">menu</mat-icon>
      <mat-icon *ngIf="menuOpened">menu_open</mat-icon>
    </button>

    <!-- current user's menus -->
    <button
      class="user-menu-button ml-1 toolbar-button"
      mat-icon-button
      [matMenuTriggerFor]="userDropdown"
      matTooltip="{{ 'header.user_menu' | translate }}"
    >
      <mat-icon>account_circle</mat-icon>
    </button>

    <mat-menu #userDropdown="matMenu">
      <div class="username">{{ authService.username }}</div>
      <button class="clear-outline" mat-menu-item [matMenuTriggerFor]="languageDropdown">
        <mat-icon class="icon_spacer">language</mat-icon>
        {{ 'language.' + languageService.getCurrentLanguageCode() | translate }}
      </button>
      <button class="clear-outline" mat-menu-item (click)="logout()" [routerLink]="['/login']">
        <mat-icon>exit_to_app</mat-icon>
        {{ 'common.logout' | translate }}
      </button>
    </mat-menu>
    <mat-menu #languageDropdown="matMenu">
      <button
        *ngFor="let code of languageService.getLanguageListCodes()"
        (click)="languageService.switchLanguage(code, true)"
        class="clear-outline"
        mat-menu-item
      >
        {{ 'language.' + code | translate }}
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
