import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getFromLocalStorage } from '../helpers/local-storage';
import { AuthService } from '../services/auth/auth.service';
import { DevicesService } from '../services/devices/devices.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, private deviceService: DevicesService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (getFromLocalStorage('user') && this.authService.isAuthenticated && this.deviceService.currentDevice != null) {
      // token exists and is valid
      return true;
    } else {
      this.deviceService.clearCurrentDevice();
      // token expired or non existed
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
