import { TableDataSource } from '@app/shared/bases/table.datasource';
import { OverallStatInfo } from '../interfaces/widget.interface';
import { GraphDataService } from '../services/graph-data/graph-data.service';

export class OverallStatisticDataSource extends TableDataSource<OverallStatInfo> {
  constructor(private graphDataService: GraphDataService, private startDate: Date, private endDate: Date) {
    super();
  }

  setDateRange(from: Date, to: Date) {
    this.startDate = from;
    this.endDate = to;
  }

  loadData(): Promise<OverallStatInfo[]> {
    const get$ = this.graphDataService.getOverallStatistics(this.startDate, this.endDate);

    get$.subscribe((res: OverallStatInfo[]) => {
      this.rowSubject.next(res);
      this.countSubject.next(res.length);
    });

    return get$.toPromise();
  }

  get isPaginated(): boolean {
    return false;
  }
}
