<h3 mat-dialog-title>{{ title | translate }}</h3>
<div mat-dialog-content>
  <p>{{ message | translate: { item: item } }}</p>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-flat-button color="primary" (click)="onConfirm()" class="mx-2">
    {{ 'common.yes' | translate }}
  </button>
  <button mat-button (click)="onDismiss()">
    {{ 'common.no' | translate }}
  </button>
</div>
