import { menu } from '@app/core/data/menu';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../../warehouse/services/catalog-items/catalog-items.service";
export class MenuService {
    constructor(catalogItemService) {
        this.catalogItemService = catalogItemService;
        this.initialMenus = menu;
        /** Observable for menu items, it will send the initial menus first and loads dynamic menu items later on refresh() */
        this.menus$ = new BehaviorSubject(this.initialMenus);
    }
    refresh() {
        this.catalogItemService
            .getReturnablePackagingGroup()
            .toPromise()
            .then(data => {
            const menus = menu;
            // hide returnable packaging (Warehouse -> Returnable packaging)
            const warehouseMenu = menus.find(item => item.id === 'warehouse');
            if (warehouseMenu) {
                const rpMenu = warehouseMenu.items.find(item => item.id === 'warehouse_returnable_packaging');
                if (rpMenu) {
                    rpMenu.hidden = data == null || (data && data.deleted === true);
                }
            }
            this.menus$.next(menus);
        })
            .catch(error => {
            console.log(error);
        });
    }
}
MenuService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.ɵɵinject(i1.CatalogItemsService)); }, token: MenuService, providedIn: "root" });
