import { ConfigItem } from '@app/shared/models/config-item.model';
import { DeserializableModel } from './deserializable.model';

export interface EventBundle {
  state?: string;
}

export class EventLog extends DeserializableModel {
  // tslint:disable-next-line:variable-name
  _id: string;
  // tslint:disable-next-line:variable-name
  _m: string;
  // tslint:disable-next-line:variable-name
  _v: number;
  eventBundle?: EventBundle;
  eventTime?: number;
  identityId?: string;
  logMessage?: string;
  type?: string;
  userId?: string;
  config?: ConfigItem;
}
