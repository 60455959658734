import { Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { ModulesGuard } from '@app/shared/guards/modules.guard';
import { EmptyComponent } from './components/empty/empty.component';
import { CoreComponent } from './core.component';
const ɵ0 = () => import("./../dashboard/dashboard.module.ngfactory").then(m => m.DashboardModuleNgFactory), ɵ1 = () => import("./../statistics/statistics.module.ngfactory").then(m => m.StatisticsModuleNgFactory), ɵ2 = () => import("./../warehouse/warehouse.module.ngfactory").then(m => m.WarehouseModuleNgFactory), ɵ3 = () => import("./../settings/settings.module.ngfactory").then(m => m.SettingsModuleNgFactory), ɵ4 = () => import("./../wirecard/wirecard.module.ngfactory").then(m => m.WirecardModuleNgFactory), ɵ5 = () => import("./../partners/partners.module.ngfactory").then(m => m.PartnersModuleNgFactory);
const routes = [
    {
        path: '',
        component: CoreComponent,
        canActivate: [AuthGuard, ModulesGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                loadChildren: ɵ0,
            },
            {
                path: 'statistics',
                loadChildren: ɵ1,
            },
            {
                path: 'warehouse',
                loadChildren: ɵ2,
            },
            {
                path: 'settings',
                loadChildren: ɵ3,
            },
            {
                path: 'wirecard',
                loadChildren: ɵ4,
            },
            {
                path: 'partners',
                loadChildren: ɵ5,
            },
            {
                path: 'empty',
                component: EmptyComponent,
                pathMatch: 'full',
            },
        ],
    },
];
export class CoreRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
