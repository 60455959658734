/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../title/title.component.ngfactory";
import * as i3 from "../title/title.component";
import * as i4 from "../../services/title/title.service";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./page.component";
var styles_PageComponent = [i0.styles];
var RenderType_PageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageComponent, data: {} });
export { RenderType_PageComponent as RenderType_PageComponent };
export function View_PageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-title", [], null, null, null, i2.View_TitleComponent_0, i2.RenderType_TitleComponent)), i1.ɵdid(1, 49152, null, 0, i3.TitleComponent, [i4.TitleService], { title: [0, "title"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.title$)))); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page", [], null, null, null, View_PageComponent_0, RenderType_PageComponent)), i1.ɵdid(1, 114688, null, 0, i7.PageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageComponentNgFactory = i1.ɵccf("app-page", i7.PageComponent, View_PageComponent_Host_0, { title: "title" }, {}, ["*"]);
export { PageComponentNgFactory as PageComponentNgFactory };
