import { getBrowserGMTOffset } from '../helpers/date';
import * as i0 from "@angular/core";
export class TimezoneOffsetInterceptor {
    constructor() { }
    intercept(request, next) {
        request = request.clone({
            setHeaders: {
                'x-timezone-offset': getBrowserGMTOffset(''),
            },
        });
        return next.handle(request);
    }
}
TimezoneOffsetInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TimezoneOffsetInterceptor_Factory() { return new TimezoneOffsetInterceptor(); }, token: TimezoneOffsetInterceptor, providedIn: "root" });
