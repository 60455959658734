import { formatDate } from '@app/shared/helpers/date';
import { formatCurrencyDefault, formatNumberDefault } from '@app/shared/helpers/helpers';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { OverallStatisticDataSource } from '../datasources/overall-stat.datasource';
import { GraphDisplayOption, GraphTimePeriod, GraphTimePeriodRange, GraphType } from '../interfaces/graph.interface';
import { OverallStatInfo } from '../interfaces/widget.interface';
import { GraphDataService } from '../services/graph-data/graph-data.service';
import { BaseGraphView } from './base.graph-view';

export class OverallGraphView extends BaseGraphView {
  private options: GraphDisplayOption = {};

  constructor() {
    super();
  }

  onInit(graphDataService: GraphDataService, translateService: TranslateService, range: GraphTimePeriodRange) {
    this.options.tableDataSource = new OverallStatisticDataSource(graphDataService, range.from, range.to);
    this.options.tableColumns = new BehaviorSubject([
      {
        key: 'date',
        name: _('graphs.date'),
        getValue: (data: OverallStatInfo) => formatDate({ date: data.date, format: 'mediumDate' }),
      },
      {
        key: 'revenue',
        name: _('graphs.revenue'),
        getValue: (data: OverallStatInfo) => formatCurrencyDefault(data.totalRevenue, translateService.currentLang),
      },
      {
        key: 'receipts',
        name: _('graphs.receipts'),
        getValue: (data: OverallStatInfo) =>
          formatNumberDefault(data.numberOfReceipts, translateService.currentLang, '1.0-0'),
      },
      {
        key: 'items',
        name: _('graphs.items'),
        getValue: (data: OverallStatInfo) =>
          formatNumberDefault(data.numberOfItems, translateService.currentLang, '1.0-0'),
      },
      {
        key: 'average',
        name: _('graphs.average'),
        getValue: (data: OverallStatInfo) =>
          formatCurrencyDefault(data.averagePerReceipt, translateService.currentLang),
      },
    ]);
  }

  get title(): string {
    return _('graphs.overall');
  }

  get allowVisibilityChanged(): boolean {
    return true;
  }

  get graphType(): GraphType {
    return 'TABLE';
  }

  getTimePeriod(translateService: TranslateService): GraphTimePeriod {
    const startDay = moment().startOf('day');
    const endDay = moment().endOf('day');
    return {
      type: 'SELECT',
      ranges: [
        {
          name: translateService.instant(_('graphs.period.days'), { value: '8' }),
          from: moment(startDay).subtract(7, 'days').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '2' }),
          from: moment(startDay).subtract(2, 'weeks').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '4' }),
          from: moment(startDay).subtract(4, 'weeks').toDate(),
          to: endDay.toDate(),
        },
      ],
    };
  }

  get displayOption(): GraphDisplayOption {
    return this.options;
  }

  fetchData(
    graphDataService: GraphDataService,
    translateService: TranslateService,
    range: GraphTimePeriodRange
  ): Promise<any> {
    const datasource = <OverallStatisticDataSource>this.options.tableDataSource;
    datasource.setDateRange(range.from, range.to);
    return <Promise<OverallStatInfo[]>>datasource.refresh();
  }
}
