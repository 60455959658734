import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const user = this.authService.user;

        if (user && user.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${user.type} ${user.token}`
                },
                // withCredentials: true,
            });
        }

        return next.handle(request);
    }
}
