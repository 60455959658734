import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@app/shared/helpers/date';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  transform(value: any, format?: string): any {
    if (value) {
      if (!format) {
        format = 'mediumDate';
      }
      return formatDate({ date: value, format });
    }
    return '';
  }
}
