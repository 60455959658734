/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../image-gallery/image-gallery.component.ngfactory";
import * as i5 from "../image-gallery/image-gallery.component";
import * as i6 from "@angular/forms";
import * as i7 from "../../../services/gallery/gallery.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./image-gallery-picker-dialog.component";
var styles_ImageGalleryPickerDialogComponent = [];
var RenderType_ImageGalleryPickerDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageGalleryPickerDialogComponent, data: {} });
export { RenderType_ImageGalleryPickerDialogComponent as RenderType_ImageGalleryPickerDialogComponent };
function View_ImageGalleryPickerDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("gallery.select_image")); _ck(_v, 1, 0, currVal_0); }); }
function View_ImageGalleryPickerDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform("images.picker.select-images")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ImageGalleryPickerDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h2", [["class", "my-0"], ["style", "top: -12px; position: relative"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageGalleryPickerDialogComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImageGalleryPickerDialogComponent_2)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-image-gallery", [], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ImageGalleryComponent_0, i4.RenderType_ImageGalleryComponent)), i0.ɵdid(8, 114688, null, 0, i5.ImageGalleryComponent, [i6.FormBuilder, i7.GalleryService, i1.TranslateService, i8.DomSanitizer], { mode: [0, "mode"], selection: [1, "selection"], initialSelected: [2, "initialSelected"] }, { selected: "selected" }), (_l()(), i0.ɵeld(9, 0, null, null, 9, "div", [["class", "d-flex flex-row-reverse mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 3, "button", [["class", "mx-2"], ["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i0.ɵdid(12, 180224, null, 0, i10.MatButton, [i0.ElementRef, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(13, 0, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i0.ɵdid(16, 180224, null, 0, i10.MatButton, [i0.ElementRef, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(17, 0, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selection === "single"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.selection === "multiple"); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.mode; var currVal_3 = _co.selection; var currVal_4 = _co.selectedImages; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); var currVal_7 = "primary"; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_5 = (i0.ɵnov(_v, 12).disabled || null); var currVal_6 = (i0.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_5, currVal_6); var currVal_8 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform("common.done")); _ck(_v, 13, 0, currVal_8); var currVal_9 = (i0.ɵnov(_v, 16).disabled || null); var currVal_10 = (i0.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_9, currVal_10); var currVal_11 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform("common.cancel")); _ck(_v, 17, 0, currVal_11); }); }
export function View_ImageGalleryPickerDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-gallery-picker-dialog", [], null, null, null, View_ImageGalleryPickerDialogComponent_0, RenderType_ImageGalleryPickerDialogComponent)), i0.ɵdid(1, 49152, null, 0, i13.ImageGalleryPickerDialogComponent, [i3.MAT_DIALOG_DATA, i3.MatDialogRef], null, null)], null, null); }
var ImageGalleryPickerDialogComponentNgFactory = i0.ɵccf("app-image-gallery-picker-dialog", i13.ImageGalleryPickerDialogComponent, View_ImageGalleryPickerDialogComponent_Host_0, { mode: "mode" }, {}, []);
export { ImageGalleryPickerDialogComponentNgFactory as ImageGalleryPickerDialogComponentNgFactory };
