import { environment as sharedEnvironment } from './environment.test';

export const environment = {
  ...sharedEnvironment,
  project: 'galeb',
  cookieDomain: 'ecr-dev-sr.tvc-info.hr',
  apiUrl: '/backoffice',

  // default language if not already selected
  defaultLanguage: 'sr-Cyrl',
  defaultLocale: 'sr-Cyrl',
  localStoragePrefix: 'bo_test_sr',

  // RegExp for valid VAT ID format
  vatIdRegex: '.*',
  // min length for VAT ID
  vatIdMinLength: 1,
  // max length for VAT ID
  vatIdMaxLength: 12,

  // # DEFAULT CURRENCY #
  defaultCurrency: 'RSD',
  defaultCurrencySymbol: 'din',

  appearances: {
    // login page style
    login: 'modern', // "classic", "modern"

    // to show/hide app's title on login page
    showTitleOnLogin: false,

    // app's header style
    header: 'modern-dark', // "classic", "modern", "modern-dark"

    // to show/hide app's title on main header (logged in)
    showAppTitleOnHeader: true,

    // where to show the active page's title
    pageTitle: 'body', // "header", "body"
  },

  // takeaway setting is disabled
  takeawayEnabled: false,

  // is BKP enabled
  bkpEnabled: false,

  // is FIK enabled
  fikEnabled: false,

  // is returnable packaging enabled
  returnablePackagingEnabled: false,

  // currency setting module is disabled
  currencySettingEnabled: false,

  // POS user settings
  posUser: {
    // is user TIN field enabled
    tinEnabled: true,
    // max length for user TIN
    tinMaxLength: 20,
  },

  // config for partner
  partner: {
    // Buyer cost center id is enabled
    costCenterIdEnabled: true,
    // max length for buyer cost center
    costCenterMaxLength: 20,
  },
};
