import { BaseComponent } from '@app/shared/bases/base.component';
import { defaultValue } from '@app/shared/helpers/helpers';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import {
  GraphAdditionalFilter,
  GraphDisplayOption,
  GraphTimePeriod,
  GraphTimePeriodRange,
  GraphType,
  MultiGraphData,
  OrganisedGraphData,
  SingleGraphData,
} from '../interfaces/graph.interface';
import { GraphDataService } from '../services/graph-data/graph-data.service';
import { GraphOrganiserService } from '../services/graph-organiser/graph-organiser.service';

/**
 * Abstract base class for graphs that will be organised by
 * GraphOrganiserComponent.
 */
export abstract class BaseGraphView extends BaseComponent {
  /** flag for data loading completion */
  finished: boolean;

  /** The graph's title */
  abstract get title(): string;

  /** Is this graph's visibility is allowed to be changed */
  abstract get allowVisibilityChanged(): boolean;

  /** Returns the type of graph */
  abstract get graphType(): GraphType;

  /** The time period info that will be displayed */
  abstract getTimePeriod(translateService: TranslateService): GraphTimePeriod;

  /** Fetch and set this.data with single or multi series of graph data */
  abstract fetchData(
    graphDataService: GraphDataService,
    translateService: TranslateService,
    range: GraphTimePeriodRange,
    additionalFilters?: { [key: string]: any }
  ): Promise<SingleGraphData[] | MultiGraphData[] | any>;

  /** Graph's display option */
  abstract get displayOption(): GraphDisplayOption;

  /** Returns displayOption with default values */
  get normalizedDisplayOption(): GraphDisplayOption {
    const option = this.displayOption;
    // set default values
    option.showXAxis = defaultValue(option.showXAxis, true);
    option.showYAxis = defaultValue(option.showYAxis, true);
    option.showXAxisLabel = defaultValue(option.showXAxisLabel, true);
    option.showYAxisLabel = defaultValue(option.showYAxisLabel, true);
    option.xAxisLabel = defaultValue(option.xAxisLabel, '');
    option.yAxisLabel = defaultValue(option.yAxisLabel, '');
    option.showLabels = defaultValue(option.showLabels, true);
    option.showLegend = defaultValue(option.showLegend, false);
    option.legendTitle = defaultValue(option.legendTitle, _('graphs.legend'));
    option.colorSchemeType = defaultValue(option.colorSchemeType, 'ordinal');
    option.colorGradient = defaultValue(option.colorGradient, false);
    option.tableWrapperClasses = defaultValue(
      option.tableWrapperClasses,
      'mat-table-wrapper mat-elevation-z0 responsive-table'
    );
    return option;
  }

  /** will be called upon OnInit() on graph renderer component */
  onInit(graphDataService: GraphDataService, translateService: TranslateService, range: GraphTimePeriodRange) {}

  /** override this to set additional filters (other than time period) */
  getAdditionalFilters(
    organisedGraphData: OrganisedGraphData,
    graphDataService: GraphDataService,
    graphOrganiserService: GraphOrganiserService,
    translateService: TranslateService
  ): GraphAdditionalFilter[] {
    return [];
  }
}
