<!-- graph's filter -->
<div class="graph-header" [class.small]="smallWidth" [class.x-small]="extraSmallWidth">
  <div class="d-flex graph-title graph-title-select">
    <div class="card-title d-flex align-items-center mr-auto">{{ graphView.title | translate }}</div>
    <div class="graph-filters d-flex">
      <div class="additional-filters mr-3" *ngIf="graphAdditionalFilters.length">
        <app-graph-filter
          *ngFor="let graphFilter of graphAdditionalFilters"
          [graphId]="organisedGraphData.id"
          [graphFilter]="graphFilter"
          (valueChanged)="onAdditionalFilterChanged(graphFilter, $event)"
        ></app-graph-filter>
      </div>
      <div class="daterange-select">
        <app-form-select-search
          [useSearch]="false"
          [fieldClasses]="'no-label'"
          [options]="selectPeriodData"
          [control]="selectPeriodControl"
          (selectionChange)="onSelectPeriodChanged($event)"
        ></app-form-select-search>
      </div>
    </div>
  </div>
  <p class="mt-0 d-flex align-items-center">
    <ng-container *ngIf="timePeriod.type === 'DAILY' || currentTimePeriodRange.singleDay === true; else rangedDate">
      <mat-icon class="mr-2">calendar_today</mat-icon>
      <span>{{ currentTimePeriodRange.from | localizedDate }}</span>
    </ng-container>
    <ng-template #rangedDate>
      <mat-icon class="mr-2">date_range</mat-icon>
      <span>{{ currentTimePeriodRange.from | localizedDate }} - {{ currentTimePeriodRange.to | localizedDate }}</span>
    </ng-template>
  </p>
</div>

<!-- the graph -->
<div
  class="graph-wrapper"
  [class.d-none]="!graphView.finished"
  [class.d-flex]="graphView.finished"
  [class.no-data-wrapper]="!hasData"
  appResized
  (resized)="onContainerResized($event)"
>
  <div class="graph-inner-wrapper" #graphInnerWrapper>
    <ng-container *ngIf="!hasData; else theGraph">
      <div class="no-data text-center">{{ 'common.no_results_found' | translate }}</div>
    </ng-container>
    <ng-template #theGraph>
      <ng-container [ngSwitch]="graphView.graphType">
        <!-- Vertical Bar graph -->
        <ngx-charts-bar-vertical
          *ngSwitchCase="'VERTICAL_BAR'"
          [view]="[graphWidth, graphHeight]"
          [results]="data"
          [xAxis]="option.showXAxis"
          [yAxis]="option.showYAxis"
          [showXAxisLabel]="option.showXAxisLabel"
          [xAxisTickFormatting]="option.xAxisTickFormatting"
          [yAxisTickFormatting]="option.yAxisTickFormatting"
          [showYAxisLabel]="option.showYAxisLabel"
          [xAxisLabel]="option.xAxisLabel | translate"
          [yAxisLabel]="option.yAxisLabel | translate"
          [legend]="option.showLegend"
          [legendTitle]="option.legendTitle ? '' : (option.legendTitle | translate)"
          [scheme]="colorScheme"
          [schemeType]="option.colorSchemeType"
          [gradient]="option.colorGradient"
          (select)="onSelect($event)"
        >
          <ng-template #tooltipTemplate let-model="model">
            <div *ngIf="model.extra; else tooltipStandard">
              <h3>{{ model.name | dynamicPipe: model.extra.pipe }}</h3>
              <h3>{{ model.extra.prefix }} {{ model.value | formatNumberDefault }} {{ model.extra.suffix }}</h3>
            </div>
            <ng-template #tooltipStandard>
              <div>
                <h3>{{ model.name }}</h3>
                <h3>{{ model.value }}</h3>
              </div>
            </ng-template>
          </ng-template>
        </ngx-charts-bar-vertical>

        <!-- Horizontal Bar graph -->
        <ngx-charts-bar-horizontal
          *ngSwitchCase="'HORIZONTAL_BAR'"
          [view]="[graphWidth, graphHeight]"
          [results]="data"
          [xAxis]="option.showXAxis"
          [yAxis]="option.showYAxis"
          [showXAxisLabel]="option.showXAxisLabel"
          [showYAxisLabel]="option.showYAxisLabel"
          [xAxisTickFormatting]="option.xAxisTickFormatting"
          [yAxisTickFormatting]="option.yAxisTickFormatting"
          [xAxisLabel]="option.xAxisLabel | translate"
          [yAxisLabel]="option.yAxisLabel | translate"
          [legend]="option.showLegend"
          [legendTitle]="option.legendTitle ? '' : (option.legendTitle | translate)"
          [scheme]="colorScheme"
          [schemeType]="option.colorSchemeType"
          [gradient]="option.colorGradient"
          (select)="onSelect($event)"
        >
          <ng-template #tooltipTemplate let-model="model">
            <div *ngIf="model.extra; else tooltipStandard">
              <h3>{{ model.name | dynamicPipe: model.extra.pipe }}</h3>
              <h3>{{ model.extra.prefix }} {{ model.value | formatNumberDefault }} {{ model.extra.suffix }}</h3>
            </div>
            <ng-template #tooltipStandard>
              <div>
                <h3>{{ model.name }}</h3>
                <h3>{{ model.value }}</h3>
              </div>
            </ng-template>
          </ng-template>
        </ngx-charts-bar-horizontal>

        <!-- Pie Chart -->
        <div
          *ngSwitchCase="'PIE_CHART'"
          class="pie-chart d-flex"
          [class.small]="smallWidth"
          [class.x-small]="extraSmallWidth"
        >
          <div class="pie-chart-wrapper">
            <ngx-charts-pie-chart
              [view]="[graphWidth, graphHeight]"
              [scheme]="colorScheme"
              [results]="data"
              [gradient]="option.colorGradient"
              [legend]="false"
              [labels]="option.showLabels"
              [explodeSlices]="false"
              [doughnut]="true"
              [arcWidth]="0.4"
              (select)="onSelect($event)"
            >
              <ng-template #tooltipTemplate let-model="model">
                <div *ngIf="model.extra; else tooltipStandard">
                  <h3>{{ model.name | dynamicPipe: model.extra.pipe }}</h3>
                  <h3>{{ model.extra.prefix }} {{ model.value | formatNumberDefault }} {{ model.extra.suffix }}</h3>
                </div>
                <ng-template #tooltipStandard>
                  <div>
                    <h3>{{ model.name }}</h3>
                    <h3>{{ model.value }}</h3>
                  </div>
                </ng-template>
              </ng-template>
            </ngx-charts-pie-chart>
          </div>

          <!-- custom legend -->
          <div *ngIf="option.showLegend" class="pie-chart-legend pl-0 pl-xl-2">
            <h4 *ngIf="option.legendTitle">{{ option.legendTitle ? '' : (option.legendTitle | translate) }}</h4>
            <div
              *ngFor="let legend of legendData; let i = index"
              class="pie-chart-legend-item d-flex align-items-center p-1"
            >
              <span class="custom-legend-color-item" [style.backgroundColor]="legend.color"></span>
              <ng-container *ngIf="legend.extra; else legendStandard">
                <span class="custom-legend-name pr-2">{{ legend.name | dynamicPipe: legend.extra.pipe }}</span>
                <span class="custom-legend-value ml-auto text-right">
                  {{ legend.extra.prefix }} {{ legend.value | formatNumberDefault }} {{ legend.extra.suffix }}
                </span>
              </ng-container>
              <ng-template #legendStandard>
                <span class="custom-legend-name">{{ legend.name }}</span>
                <span class="custom-legend-value ml-auto text-right">{{ legend.value | formatNumberDefault }}</span>
              </ng-template>
              <span class="custom-legend-percentage text-right ml-3" [class.ml-auto]="extraSmallWidth">
                {{ legend.percentage | formatNumberDefault }}%
              </span>
            </div>
          </div>
        </div>

        <!-- Line chart -->
        <ngx-charts-line-chart
          *ngSwitchCase="'LINE_CHART'"
          [view]="[graphWidth, graphHeight]"
          [scheme]="colorScheme"
          [results]="data"
          [xAxis]="option.showXAxis"
          [yAxis]="option.showYAxis"
          [showXAxisLabel]="option.showXAxisLabel"
          [showYAxisLabel]="option.showYAxisLabel"
          [xAxisLabel]="option.xAxisLabel | translate"
          [yAxisLabel]="option.yAxisLabel | translate"
          [gradient]="false"
          [legend]="option.showLegend"
          [legendTitle]="option.legendTitle ? '' : (option.legendTitle | translate)"
          [roundDomains]="true"
          (select)="onSelect($event)"
        >
          <ng-template #tooltipTemplate let-model="model">
            <div *ngIf="model.extra; else tooltipStandard">
              <h3>{{ model.name | dynamicPipe: model.extra.pipe }}</h3>
              <h3>{{ model.extra.prefix }} {{ model.value | formatNumberDefault }} {{ model.extra.suffix }}</h3>
            </div>
            <ng-template #tooltipStandard>
              <div>
                <h3>{{ model.name }}</h3>
                <h3>{{ model.value | formatNumberDefault }}</h3>
              </div>
            </ng-template>
          </ng-template>
          <ng-template #seriesTooltipTemplate let-model="model">
            <ng-container *ngFor="let item of model">
              <div class="py-2" *ngIf="item.extra; else tooltipStandard">
                <span class="mr-1">{{ item.series | dynamicPipe: item.extra.pipe }}:</span>
                <span>{{ item.extra.prefix }} {{ item.value | formatNumberDefault }} {{ item.extra.suffix }}</span>
              </div>
              <ng-template #tooltipStandard>
                <div class="py-2">
                  <span class="mr-1">{{ item.series }}:</span>
                  <span>{{ item.value }}</span>
                </div>
              </ng-template>
            </ng-container>
          </ng-template>
        </ngx-charts-line-chart>

        <!-- Table chart -->
        <div class="table-chart" *ngSwitchCase="'TABLE'">
          <app-table
            [columns]="option.tableColumns"
            [dataSource]="option.tableDataSource"
            [tableWrapperClasses]="option.tableWrapperClasses"
            [useSearch]="false"
            [responsiveNow]="smallWidth || extraSmallWidth"
          ></app-table>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
<!-- spinner -->
<div class="spinner" [class.d-none]="graphView.finished">
  <div class="spinner-box">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
