import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '@app/shared/helpers/local-storage';
import { DevicesService } from '@app/shared/services/devices/devices.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { empty, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../company/company.service';

const API_URL = environment.apiUrl;
const AUTHENTICATION_URL = `${environment.apiUrl}/${environment.authenticationPath}`;
const JWT_HELPER = new JwtHelperService();

interface LoginRequestConfigInterface {
  http: HttpClient;
  url: string;
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // tslint:disable-next-line:variable-name
  private _user: any = null;

  constructor(
    private http: HttpClient,
    private devicesService: DevicesService,
    private companiesService: CompanyService,
    private router: Router
  ) {
    const user = JSON.parse(getFromLocalStorage('user'));
    if (user) {
      this._user = user;
    }
  }

  get user() {
    if (this._user !== null) {
      return this._user;
    }

    return JSON.parse(getFromLocalStorage('user'));
  }

  get username() {
    const user = this.user;

    return user && user.username ? user.username : '';
  }

  get companyId() {
    return this.user && this.user.icpRefNo ? this.user.icpRefNo : null;
  }

  get userId() {
    return this.user && this.user.icpRefNo ? this.user.icpRefNo : null;
  }

  getDataFromToken(token: string, type: string = 'Bearer', byPhoneNumber = false) {
    const data = JWT_HELPER.decodeToken(token);

    return {
      icpRefNo: byPhoneNumber ? data.phoneNumber : data.icpRefNo,
      userDetails: data.userDetails,
      username: byPhoneNumber ? data.phoneNumber : data.sub,
      token,
      iat: data.iat,
      exp: data.exp,
      type,
    };
  }

  getAdminDataFromToken(token: string, type: string = 'Bearer') {
    const data = JWT_HELPER.decodeToken(token);

    return {
      icpRefNo: null,
      userDetails: data.userDetails,
      companyId: data.companyId,
      username: 'Admin',
      token: token,
      iat: data.iat,
      exp: data.exp,
      type: type,
    };
  }

  isAuthenticated(): boolean {
    if (this.user === null) {
      return false;
    }

    const userData = this.getDataFromToken(this.user.token);
    if (userData.userDetails !== '[USER]') {
      return false;
    }

    return !JWT_HELPER.isTokenExpired(this.user.token);
  }

  async login(username: string, password: string) {
    const result = await this.loginProcedure({
      http: this.http,
      url: AUTHENTICATION_URL,
      username,
      password,
    }).toPromise();

    return Promise.resolve(result);
  }

  async loginAdmin(token: string) {
    const data = this.loginWithToken(token);

    return Promise.resolve(data);
  }

  async otpSendCodeToNumber(phoneNumber: string) {
    const result = await this.otpSendCodeToNumberProcedure(phoneNumber).toPromise();

    return Promise.resolve(result);
  }

  async otpValidateCode(phoneNumber: string, code: string) {
    const result = await this.otpValidateCodeProcedure(phoneNumber, code).toPromise();

    return Promise.resolve(result);
  }

  clear() {
    removeFromLocalStorage('user');
    this._user = null;
    this.companiesService.clear();
    this.devicesService.clear();
    return Promise.resolve(true);
  }

  logout() {
    this.clear();
    this.router.navigate(['/login']);
  }

  /**
   * Send a reset password request from the given username/email
   */
  resetPassword(email: string): Observable<any> {
    return this.http.patch(`${API_URL}/${environment.resetPasswordPath}`, { email });
  }

  private setUser(data) {
    this._user = data;
    saveToLocalStorage('user', JSON.stringify(data));
  }

  private loginProcedure(config: LoginRequestConfigInterface): Observable<any> {
    const { url, http, password, username } = config;

    if (url && http && password && username) {
      return http.post<any>(url, { username, password }).pipe(
        map(
          res => {
            if (res && res.accessToken) {
              const user = this.getDataFromToken(res.accessToken, res.tokenType);
              this.setUser(user);

              return [true, user.icpRefNo, null];
            }
            return [false, null, null];
          },
          catchError(error => {
            return [false, null, error];
          })
        )
      );
    }

    return empty();
  }

  private loginWithToken(token: string) {
    const user = this.getAdminDataFromToken(token);

    if (user && user.companyId && user.userDetails === '[ADMIN]') {
      this.setUser(user);
      return [true, { companyId: user.companyId }];
    }

    return [false, null];
  }

  private otpSendCodeToNumberProcedure(phoneNumber: string) {
    if (phoneNumber) {
      return this.http.post<any>(`${API_URL}/${environment.authSendSms}`, { phone_number: phoneNumber }).pipe(
        map(
          res => {
            if (res['success'] === true) {
              return { success: true };
            }
            return { success: false };
          },
          catchError(error => {
            console.error(error);
            return of({ success: false });
          })
        )
      );
    }

    return of({
      success: false,
    });
  }

  private otpValidateCodeProcedure(phoneNumber: string, code: string) {
    if (code) {
      return this.http
        .post<any>(`${API_URL}/${environment.authVerifyCode}`, { phone_number: phoneNumber, otp: code })
        .pipe(
          map(
            res => {
              if (res['success'] === true && res['credentials'] && res['credentials']['accessToken']) {
                const { accessToken, tokenType } = res['credentials'];
                const user = this.getDataFromToken(accessToken, tokenType, true);
                this.setUser(user);
                return { success: true, phoneNumber: user.icpRefNo };
              }

              console.error(res);
              return { success: false };
            },
            catchError(error => {
              console.error(error);
              return of({ success: false });
            })
          )
        );
    }

    return of({
      success: false,
    });
  }
}
