<mat-card class="dashboard-widget fiscalization-status-widget full-height">
  <mat-card-content>
    <div class="status-row d-flex">
      <div class="status-row-icon">
        <span [ngClass]="fiscalizationOn === true ? 'status-indicator active' : 'status-indicator inactive'"></span>
      </div>
      <div class="status-row-text">
        {{ (fiscalizationOn === true ? 'common.fiscalization.active' : 'common.fiscalization.inactive') | translate }}
      </div>
    </div>
  </mat-card-content>
</mat-card>
