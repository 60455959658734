import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root',
})
export class TitleService {

  title$ = new BehaviorSubject('');
  url$: Observable<UrlSegment[]>;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private translateService: TranslateService
  ) {
    this.url$ = this.route.url;
  }

  /**
   * Sets value of any tipe to title.
   *
   * @param {*} value
   * @memberof TitleService
   */
  async setTitle(value: any) {
    const titleToken = value.toString();

    const titleString = await this.translateService.get(titleToken).toPromise();
    const titleCommon = await this.translateService.get('app_title').toPromise();

    let titleCombined = titleCommon;

    if (titleString) {
      titleCombined = `${titleString} / ${titleCombined}`
    };

    this.title$.next(titleString);
    this.title.setTitle(titleCombined);
  }

}
