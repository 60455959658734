/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./devices.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/material/form-field";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../shared/components/generic-forms/form-select-search.component.ngfactory";
import * as i8 from "../../../shared/components/generic-forms/form-select-search.component";
import * as i9 from "@angular/common";
import * as i10 from "./devices.component";
import * as i11 from "../../../shared/services/devices/devices.service";
import * as i12 from "../../../shared/services/config/config.service";
var styles_DevicesComponent = [i0.styles];
var RenderType_DevicesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DevicesComponent, data: {} });
export { RenderType_DevicesComponent as RenderType_DevicesComponent };
function View_DevicesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "mat-card", [["class", "select-device-card mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "d-flex justify-content-between py-2 border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "mat-label", [["class", "device-info-label"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i5.MatLabel, [], null, null), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "device-info-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, 0, 6, "div", [["class", "d-flex justify-content-between py-2 border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "mat-label", [["class", "device-info-label"]], null, null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i5.MatLabel, [], null, null), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "device-info-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), (_l()(), i1.ɵeld(16, 0, null, 0, 1, "app-form-select-search", [], null, [[null, "selectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChange" === en)) {
        var pd_0 = (_co.selectDevice($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormSelectSearchComponent_0, i7.RenderType_FormSelectSearchComponent)), i1.ɵdid(17, 770048, null, 0, i8.FormSelectSearchComponent, [i1.ChangeDetectorRef], { control: [0, "control"], errorTranslations: [1, "errorTranslations"], label: [2, "label"], options: [3, "options"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.formControl; var currVal_6 = _co.errorTranslations; var currVal_7 = "devices.select_device"; var currVal_8 = _co.deviceOptions; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("devices.current_id")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.selectedDeviceId; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("devices.current_app_ver")); _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.deviceAppVersion; _ck(_v, 15, 0, currVal_4); }); }
export function View_DevicesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DevicesComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DevicesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-devices", [], null, null, null, View_DevicesComponent_0, RenderType_DevicesComponent)), i1.ɵdid(1, 245760, null, 0, i10.DevicesComponent, [i11.DevicesService, i12.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DevicesComponentNgFactory = i1.ɵccf("app-devices", i10.DevicesComponent, View_DevicesComponent_Host_0, {}, {}, []);
export { DevicesComponentNgFactory as DevicesComponentNgFactory };
