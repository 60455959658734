import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatInfo } from '@app/dashboard/interfaces/widget.interface';
import { BaseApiService } from '@app/shared/bases/base-api.service';
import { EventLog } from '@app/shared/models/event-log.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WidgetService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getStatInfo(eventLog: EventLog): Observable<StatInfo> {
    const params = new HttpParams()
      .set('date', new Date().toISOString())
      .set('lastEventLogDate', new Date(eventLog.eventTime).toISOString())
      .set('state', eventLog.eventBundle.state);

    return this.get(`${environment.statisticInfoForDashboardPath}`, params);
  }
}
