import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getFromLocalStorage, removeFromLocalStorage } from '@app/shared/helpers/local-storage';
import { BoResponse } from '@app/shared/interfaces/response.interface';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private _currentCompany = null;
  private _currentEstablishment = null;

  companies = [];

  get currentCompany() {
    if (this._currentCompany !== null) {
      return this._currentCompany;
    }

    const currentDevice = this.getStoredDevice();
    if (currentDevice) {
      this._currentCompany = currentDevice.companyId;
    }
    return this._currentCompany;
  }

  set currentCompany(value) {
    this._currentCompany = value;
  }

  get currentEstablishment() {
    if (this._currentEstablishment !== null) {
      return this._currentEstablishment;
    }

    const currentDevice = this.getStoredDevice();
    if (currentDevice) {
      this._currentEstablishment = currentDevice.establishmentId;
    }
    return this._currentEstablishment;
  }

  set currentEstablishment(value) {
    this._currentEstablishment = value;
  }

  constructor(private http: HttpClient) {}

  getStoredDevice() {
    return JSON.parse(getFromLocalStorage('currentDevice'));
  }

  getCompaniesForUser(icpRefNo) {
    return this.http.get<BoResponse>(`${API_URL}/${environment.companiesForUserPath}/${icpRefNo}`).pipe(
      map(res => {
        if (res.success) {
          this.companies = res.data || [];
          return res.data;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        return of([]);
      })
    );
  }

  getCompaniesForUserByUserId(userId) {
    return this.http.get<BoResponse>(`${API_URL}/${environment.companiesForUserByUserId}/${userId}`).pipe(
      map(res => {
        if (res.success) {
          this.companies = res.data || [];
          return res.data;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        return of([]);
      })
    );
  }

  getCompaniesForUserByPhoneNumber(phoneNumber) {
    return this.http.get<BoResponse>(`${API_URL}/${environment.companiesForUserByPhoneNumber}/${phoneNumber}`).pipe(
      map(res => {
        if (res.success) {
          this.companies = res.data || [];
          return res.data;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        return of([]);
      })
    );
  }

  hasWirecard() {
    return this.http.get<BoResponse>(`${API_URL}/${environment.wirecardPath}/exists`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        return of([]);
      })
    );
  }

  clear() {
    removeFromLocalStorage('currentDevice');
    this.currentCompany = null;
    this.currentEstablishment = null;
    this.companies = [];
  }
}
