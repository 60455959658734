import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@app/shared/bases/base-api.service';
import { BoResponse } from '@app/shared/interfaces/response.interface';
import { IconCategory } from '@app/shared/models/catalog-item.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ImageGroup {
  id: string;
  name: string;
  group?: ImageGroup;
}

export interface GalleryImagesFilter {
  name?: string;
  locale?: string;
  fileName?: string;
  categoryId?: string;
  completed?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GalleryService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http);
  }

  getIcons(page?: number, size?: number, filter?: GalleryImagesFilter): Observable<any> {
    let params = new HttpParams();

    if (page !== null && size !== null) {
      params = params.append('page', String(page));
      params = params.append('size', String(size));
    }

    if (filter != null) {
      if (filter.name) params = params.append('name', filter.name);
      if (filter.locale) params = params.append('locale', filter.locale);
      if (filter.categoryId) params = params.append('categoryId', filter.categoryId);
    }

    params = params.append('completed', String(true));
    return this.get<BoResponse>(`${environment.imageGalleryPath}`, params);
  }

  getAllIconCategories(): Observable<IconCategory[]> {
    return this.get<any>(`${environment.imageGalleryCategoryPath}`);
  }
}
