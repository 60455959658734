export function AutoUnsubscribe(blackList = []) {

  return constructor => {
    const original = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      original && typeof original === 'function' && original.apply(this, arguments);
      for (let prop in this) {
        const property = this[prop];
        if (!blackList.includes(prop)) {
          if (property && (typeof property.unsubscribe === "function")) {
            property.unsubscribe();
            this[prop] = undefined;
          }
        }
      }
    };

  }

};
