import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Icon } from '@app/shared/models/catalog-item.model';
import { TranslateService } from '@ngx-translate/core';
import { ImageEnlargeDialogComponent } from '../image-enlarge-dialog/image-enlarge-dialog.component';

@Component({
  selector: 'app-image-gallery-detail',
  templateUrl: './image-gallery-detail.component.html',
  styleUrls: ['./image-gallery-detail.component.scss'],
})
export class ImageGalleryDetailComponent implements OnChanges {
  @Input() image: Icon;
  @Input() readonly = false;
  @Output() deleteImage = new EventEmitter<Icon>();
  @Output() replaceImage = new EventEmitter<Icon>();
  @Output() closePanel = new EventEmitter();
  @Output() imageNameChanged = new EventEmitter<Icon>();
  imageName = '';
  resourceUrl: SafeResourceUrl;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges(change: SimpleChanges) {
    if (change.image.currentValue) {
      this.imageName = change.image.currentValue.name[this.translateService.currentLang];
      const unsafeResource = `data:${change.image.currentValue.contentType};base64,`.concat(change.image.currentValue.file.data); 
      this.resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeResource);
    }
  }

  emitReplaceImage() {
    this.replaceImage.emit(this.image);
  }

  emitDeleteImage() {
    this.deleteImage.emit(this.image);
  }

  save() {
    this.image.name[this.translateService.currentLang] = this.imageName;
    this.imageNameChanged.emit(this.image);
  }

  close() {
    this.closePanel.emit();
  }

  enlarge() {
    this.dialog.open(ImageEnlargeDialogComponent, {
      data: {
        image: this.image,
      },
      panelClass: 'slim-dialog', // for a min padding and only image inside
    });
  }
}
