<mat-card [ngClass]="widgetClasses">
  <mat-card-content>
    <div class="info-widget-header mb-3 d-flex flex-row">
      <div class="info-widget-title">{{ title | translate }}</div>
      <div
        class="info-widget-percentage ml-auto"
        *ngIf="showPercentage === true && percentage != null && displayStyle !== 'INLINE'"
      >
        <span [ngClass]="percentageIconClasses"></span>
        {{ percentageValue }}
      </div>
    </div>

    <ng-container *ngIf="state === 'LOADING' || state === undefined">
      <div class="spinner mt-2">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngIf="state === 'LOADED'">
      <div class="info-widget-text mt-auto" [innerHtml]="displayedText"></div>
    </ng-container>

    <ng-container *ngIf="state === 'ERROR'">
      <p>{{ 'common.generic_error' | translate }}</p>
    </ng-container>
  </mat-card-content>
</mat-card>
