<ng-container [ngSwitch]="graphFilter.type">
  <div *ngSwitchCase="'SELECT'">
    <app-form-select-search
      [useSearch]="false"
      [fieldClasses]="'no-label'"
      [options]="graphFilter.options | async"
      [control]="formControl"
      (selectionChange)="onSelectChanged($event)"
    ></app-form-select-search>
  </div>
</ng-container>
