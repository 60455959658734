<h3 mat-dialog-title>
  {{ 'graphs.organise_graphs' | translate }}
</h3>
<div mat-dialog-content class="py-3">
  <div cdkDropList class="graph-list mb-3" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let graph of graphs">
      <div class="graph-box" cdkDrag *ngIf="graph.graphView">
        <span class="graph-title mr-auto">{{
          graph.graphView.title | translate
        }}</span>
        <mat-select
          class="graph-width mx-3"
          [(value)]="graph.column"
          [matTooltip]="'graphs.set_width_hint' | translate"
        >
          <mat-option [value]="'1'">{{
            'graphs.width.full' | translate
          }}</mat-option>
          <mat-option [value]="'1/2'">{{
            'graphs.width.half' | translate
          }}</mat-option>
          <mat-option [value]="'1/3'">{{
            'graphs.width.one_third' | translate
          }}</mat-option>
          <mat-option [value]="'2/3'">{{
            'graphs.width.two_third' | translate
          }}</mat-option>
        </mat-select>
        <mat-checkbox
          class="graph-visibility"
          [matTooltip]="
            (graph.graphView.allowVisibilityChanged
              ? 'graphs.set_visibility_hint'
              : 'graphs.set_visibility_disabled_hint'
            ) | translate
          "
          [(ngModel)]="graph.visibility"
          [disabled]="!graph.graphView.allowVisibilityChanged"
        ></mat-checkbox>
      </div>
    </ng-container>
  </div>
  <small class="text-muted">{{ 'graphs.reorder_hint' | translate }}</small>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-flat-button color="primary" (click)="onSave()" class="mx-2">
    {{ 'common.save' | translate }}
  </button>
  <button mat-button (click)="onCancel()">
    {{ 'common.cancel' | translate }}
  </button>
</div>
