import { Component, Input, OnInit } from '@angular/core';
import { OrganisedGraphData, OrganisedGraphGridColumn } from '@app/dashboard/interfaces/graph.interface';
import { GraphOrganiserService } from '@app/dashboard/services/graph-organiser/graph-organiser.service';
import { BaseComponent } from '@app/shared/bases/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * This component is a render outlet to all graphs being organised by
 * AppGraphOrganiserComponent.
 */
@Component({
  selector: 'app-graph-outlet',
  templateUrl: './graph-outlet.component.html',
  styleUrls: ['./graph-outlet.component.scss'],
})
export class GraphOutletComponent extends BaseComponent implements OnInit {
  /** To observe refresh graphs request */
  @Input() refresh: Observable<boolean>;

  /** array of organised graphs that will bee rendered */
  graphs: OrganisedGraphData[] = [];

  constructor(private graphOrganiserService: GraphOrganiserService, private translateService: TranslateService) {
    super();
  }

  columnClasses(column: OrganisedGraphGridColumn): string {
    switch (column) {
      case '1':
        return 'col-12 mb-4';
      case '1/2':
        return 'col-12 col-lg-6 mb-4';
      case '1/3':
        return 'col-12 col-lg-4 col-md-6 mb-4';
      case '2/3':
        return 'col-12 col-lg-8 col-md-6 mb-4';
      default:
        return 'col-12 col-lg-6 mb-4';
    }
  }

  ngOnInit() {
    this.loadGraphs();
    this.listenForRefresh();

    this.translateService.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.loadGraphs();
    });
  }

  /**
   * Load organised graps data from browser's storage or default data
   */
  private loadGraphs() {
    this.graphOrganiserService.getGraphs().then(result => {
      this.graphs = result;
    });
  }

  /**
   * Subscribe and refresh graphs data when necessary
   */
  private listenForRefresh() {
    this.refresh.pipe(takeUntil(this.onDestroy$)).subscribe(needRefresh => {
      if (needRefresh) {
        this.loadGraphs();
      }
    });
  }
}
