import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@app/core/interfaces/device.interface';
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '@app/shared/helpers/local-storage';
import { BoResponse } from '@app/shared/interfaces/response.interface';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_PATH = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  // tslint:disable-next-line:variable-name
  private _currentDevice = null;
  devices: Device[];

  get currentDevice() {
    if (this._currentDevice !== null) {
      return this._currentDevice;
    }
    const currentDevice = JSON.parse(getFromLocalStorage('currentDevice'));

    if (currentDevice) {
      this._currentDevice = currentDevice;
    }

    return this._currentDevice;
  }

  set currentDevice(value: any) {
    saveToLocalStorage('currentDevice', JSON.stringify(value));
    this._currentDevice = value;
  }

  get identityId() {
    return this.currentDevice ? this.currentDevice.identityId : null;
  }

  constructor(private http: HttpClient) {}

  getDevices(): Observable<any> {
    const PATH =
      environment.project === 'galeb'
        ? `${API_PATH}/${environment.devicesPath}/from-fa`
        : `${API_PATH}/${environment.devicesPath}`;

    return this.http.get<BoResponse>(PATH).pipe(
      map(res => {
        if (res.success) {
          this.devices = res.data.map(d => ({
            identityId: d._id,
            companyId: d.companyId,
            establishmentId: d.establishmentId,
            name: d.name === null ? d._id : d.name,
          }));

          return this.devices;
        }
        console.error(res.message);

        return throwError(res.message);
      }),
      catchError(error => {
        return of([]);
      })
    );
  }

  /**
   * Clear the currently selected device and all devices available for selection
   */
  clear() {
    this.clearCurrentDevice();
    this.devices = [];
  }

  /**
   * Clear the currently selected device only
   */
  clearCurrentDevice() {
    removeFromLocalStorage('currentDevice');
    this.currentDevice = null;
  }

  hasDevices(): boolean {
    return this.devices && this.devices.length > 0;
  }

  setCurrentDeviceByIdentity(identityId: string) {
    const target = this.devices.find(dev => dev.identityId === identityId);
    if (target) {
      this.currentDevice = target;
    }
  }
}
