import { Component, Input } from '@angular/core';
import { TitleService } from '@app/shared/services/title/title.service';



@Component({
  selector: 'app-title',
  template: '',
  styleUrls: []
})
export class TitleComponent {

  @Input()
  set title(value: string) {
    this.titleService.setTitle(value);
  }

  constructor(
    private titleService: TitleService
  ) { }


}
