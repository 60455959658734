import * as tslib_1 from "tslib";
import { AfterViewInit, ChangeDetectorRef, Compiler, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, TemplateRef, } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { AutoUnsubscribe } from '@app/core/decorators/autounsubscribe.decorator';
import { ExportServiceLoader } from '@app/exports/exports-service.loader';
import { getFormattedFileName } from '@app/shared/helpers/helpers';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { merge, Subject } from 'rxjs';
import { debounceTime, repeatWhen, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
let TableComponent = class TableComponent {
    constructor(cdr, translate, searchService, spinner, alerts, compiler, injector) {
        this.cdr = cdr;
        this.translate = translate;
        this.searchService = searchService;
        this.spinner = spinner;
        this.alerts = alerts;
        this.compiler = compiler;
        this.injector = injector;
        this.onDestroy$ = new Subject();
        this.showFooter = false;
        this.showMenus = false;
        this.isResponsive = true;
        this.responsiveNow = false;
        this.noDataClass = 'mat-card mat-elevation-z0 no-data-card';
        this.noDataText = _('table.no_data');
        /** optionally set flag to clear Search service state or not upon this component's ngOnDestroy lifecycle */
        this.clearSearchOnDestroy = true;
        /** Emit current page */
        this.pageEvent = new EventEmitter();
        this.columnDefs = [];
        this.inputRowMenus = [];
        this.tableRowClasses = [];
        this.displayedColumns = [];
        this.pageSizeOptions = [10, 25, 50];
        this.dataCount = 0;
        this.showTable = false;
        this.usePaging = true;
        this.footer = {};
        this.stopDataSourceSubscription$ = new Subject();
        this.resumeDataSourceSubscription$ = new Subject();
        this.contextMenuPosition = { x: '0px', y: '0px' };
    }
    ngOnInit() {
        this.initSearch();
        this.usePaging = this.dataSource.isPaginated;
        this.setWrapperClasses();
        this.columns.pipe(takeUntil(this.onDestroy$)).subscribe(columns => {
            this.columnDefs = columns;
            this.displayedColumns = columns.map(c => c.key);
            if (this.showMenus) {
                this.displayedColumns.push('menus');
            }
            this.cdr.markForCheck();
        });
        if (this.rowMenus) {
            this.rowMenus.pipe(takeUntil(this.onDestroy$)).subscribe(rowMenus => {
                this.inputRowMenus = rowMenus;
                this.cdr.markForCheck();
            });
        }
        this.dataSource
            .connect()
            .pipe(takeUntil(merge(this.onDestroy$, this.stopDataSourceSubscription$)), repeatWhen(() => this.resumeDataSourceSubscription$))
            .subscribe(data => {
            this.rows = data;
            this.fillTableRowClasses(data);
        });
        this.dataSource
            .countObservable()
            .pipe(takeUntil(merge(this.onDestroy$, this.stopDataSourceSubscription$)), repeatWhen(() => this.resumeDataSourceSubscription$))
            .subscribe(count => {
            this.dataCount = count;
            this.showTable = count >= 1;
            this.cdr.markForCheck();
        });
        this.dataSource
            .footerObservable()
            .pipe(takeUntil(merge(this.onDestroy$, this.stopDataSourceSubscription$)), repeatWhen(() => this.resumeDataSourceSubscription$))
            .subscribe(data => {
            if (!this.isEmpty(data)) {
                // show table if footer has data
                this.showTable = true;
                this.footer = data;
                this.cdr.markForCheck();
            }
        });
        /**
         * if search service is not existed,
         * then load data
         */
        if (!this.searchService.search$) {
            this.loadData();
        }
    }
    isEmpty(obj) {
        if (obj.constructor === Object) {
            return Object.keys(obj).length === 0;
        }
        else if (Array.isArray(obj)) {
            return !obj.length;
        }
        return false;
    }
    ngAfterViewInit() {
        // react to paginator's page observable and reload data when user clicks
        // the navigation buttons
        if (this.dataSource.isPaginated) {
            this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
        }
    }
    loadData() {
        if (this.dataSource.isPaginated) {
            /**
             * if setPage is existed, change pagination index and delete setPage
             */
            if (typeof this.setPage !== 'undefined') {
                this.paginator.pageIndex = this.setPage;
                delete this.setPage;
            }
            this.dataSource.fetch({
                pageIndex: this.paginator.pageIndex,
                pageSize: this.paginator.pageSize || this.pageSizeOptions[0],
                filter: this.filter,
            });
            this.emitCurrentPage();
        }
        else {
            this.dataSource.loadData({
                filter: this.filter,
            });
        }
    }
    emitCurrentPage() {
        this.pageEvent.emit(this.paginator.pageIndex);
    }
    initSearch() {
        if (typeof this.useSearch === 'undefined' || this.useSearch === true) {
            this.searchService.search$.pipe(takeUntil(this.onDestroy$), debounceTime(300)).subscribe(res => {
                this.filter = res;
                this.paginator.pageIndex = 0; // always reset to first page upon new search attempt
                this.loadData();
            });
        }
    }
    loadExportService(callback) {
        if (this.exportService == null) {
            ExportServiceLoader.getInstance(this.compiler, this.injector)
                .getExportService()
                .then(exportService => {
                this.exportService = exportService;
                if (typeof callback === 'function') {
                    callback();
                }
            });
        }
        else {
            if (typeof callback === 'function') {
                callback();
            }
        }
    }
    /**
     * Exports table's data. If datasource is paginated, then it will fetch all the needed data.
     * @param exportType string exported file type
     */
    exportTo(exportType) {
        this.loadExportService(() => {
            try {
                let options = {};
                // set default PDF export configuration
                if (exportType === 'pdf' && !this.exportOptions) {
                    options = Object.assign({ title: this.filename, orientation: environment.exports.pdf.defaultOrientation }, this.exportOptions);
                }
                const filename = getFormattedFileName(this.translate.instant(this.filename));
                if (this.dataSource.isPaginated) {
                    this.stopDataSourceSubscription$.next();
                    this.spinner.show();
                    try {
                        this.exportService.exportFromDataSource(exportType, filename, this.columnDefs, this.filter, this.dataSource, options, () => {
                            this.resumeDataSourceSubscription$.next();
                            this.spinner.hide();
                        });
                    }
                    catch (err) {
                        console.log(err);
                        this.resumeDataSourceSubscription$.next();
                        this.spinner.hide();
                    }
                }
                else {
                    this.exportService.exportFromTableRows(exportType, filename, this.columnDefs, this.filter, this.rows, this.footer, options);
                }
            }
            catch (err) {
                console.log(err);
                this.alerts.error(err);
            }
        });
    }
    setWrapperClasses() {
        if (!this.tableWrapperClasses) {
            this.tableWrapperClasses = 'mat-table-wrapper mat-elevation-z8';
            if (this.isResponsive) {
                this.tableWrapperClasses += ' responsive-table';
            }
            if (this.responsiveNow) {
                this.tableWrapperClasses += ' responsive-now';
            }
        }
    }
    isArray(obj) {
        return Array.isArray(obj);
    }
    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        // make sure to reset the search so other components will have a clean slate search params
        if (this.clearSearchOnDestroy) {
            this.searchService.onClear();
        }
        this.stopDataSourceSubscription$.complete();
        this.resumeDataSourceSubscription$.complete();
    }
    /**
     * Clear and set CSS classes string for each row's and store it in \
     * tableRowClasses
     */
    fillTableRowClasses(rows) {
        if (rows && Array.isArray(rows)) {
            this.tableRowClasses = [];
            rows.forEach((row, index) => {
                this.tableRowClasses.push({
                    index,
                    classes: typeof this.rowClasses === 'function' ? this.rowClasses(index, row) : '',
                });
            });
        }
    }
    /**
     * Returns CSS classes for table cells
     * @param column TableColumn of the displayed row
     * @param row displayed row's data
     */
    getCellClasses(column, row, rowIndex, colIndex) {
        let textClasses = `${column.align ? column.align : ''}`;
        if (column.getCellClasses != null) {
            return `${column.getCellClasses(row, rowIndex, colIndex)} ${textClasses}`;
        }
        return textClasses;
    }
    /**
     * Returns CSS classes for table's header (th)
     * @param column TableColumn of the displayed row
     */
    getHeaderClasses(column) {
        let textClasses = `${column.align ? column.align : ''}`;
        return column.headerClasses ? `${column.headerClasses} ${textClasses}` : textClasses;
    }
    /**
     * Returns CSS classes for table's footer row
     * @param column TableColumn of the displayed row
     */
    getFooterClasses(column) {
        let textClasses = `${column.align ? column.align : ''}`;
        return column.headerClasses ? `${column.headerClasses} ${textClasses}` : textClasses;
    }
    /**
     * Context menu handler for table rows
     */
    onRowContextMenu(event, menu, row) {
        const contextMenuEnabled = typeof menu.hasContextMenu === 'function' ? menu.hasContextMenu(row) : menu.hasContextMenu;
        if (contextMenuEnabled) {
            const menuData = { type: 'ROW', row, menu };
            this.openContextMenu(event, menuData);
        }
    }
    /**
     * Context menu handler for button
     */
    onButtonContextMenu(event, link) {
        const menuData = { type: 'BUTTON', link };
        this.openContextMenu(event, menuData);
    }
    /**
     * Helper function to open context menu and set data for it
     */
    openContextMenu(event, menuData) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = menuData;
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }
    /**
     * Click handler for "Open in new tab/window" context menu item on table row menus
     */
    openInNewTabOrWindowRowMenu(event, menu, row, type) {
        if (typeof menu.action === 'function') {
            menu.action(event, row, true, type === 'WINDOW' ? 'width=1280,height=700' : null);
        }
    }
    /**
     * Click handler for "Open in new tab/window" context menu item on button
     */
    openInNewTabOrWindowButton(event, link, type) {
        window.open(link, '_blank', type === 'WINDOW' ? 'width=1280,height=700' : null);
    }
};
TableComponent = tslib_1.__decorate([
    AutoUnsubscribe()
], TableComponent);
export { TableComponent };
