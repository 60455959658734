import { Compiler, Injector, NgModuleFactory } from '@angular/core';
import { ExcelService } from './services/excel/excel.service';
import { ExportService } from './services/export/export.service';
import { PdfService } from './services/pdf/pdf.service';

/**
 * We do this in order to prevent "Runtime compiler is not loaded" error on production mode with AOT compilation is
 * enabled, since ExportsModule (along with its services) should be lazily loaded because jsPDF and xlsx exports library
 * being used on the registered services are quite big in size.
 */
export const lazyLoadExportsModule = { loadChildren: () => import('./exports.module').then(m => m.ExportsModule) };

/**
 * Singleton class to hold instance of "big-sized" services, for example export Excel service.
 * It will load the provider module (`ExportsModule`) that provides these services when necessary.
 */
export class ExportServiceLoader {
  private static instance: ExportServiceLoader;
  private exportService: ExportService;
  private compiler: Compiler;
  private injector: Injector;

  private constructor() {}

  public static getInstance(compiler: Compiler, injector: Injector): ExportServiceLoader {
    if (!ExportServiceLoader.instance) {
      ExportServiceLoader.instance = new ExportServiceLoader();
      ExportServiceLoader.instance.compiler = compiler;
      ExportServiceLoader.instance.injector = injector;
    }
    return ExportServiceLoader.instance;
  }

  /**
   * Lazily imports or loads `ExportsModule`, as provider for export services, in order to get instance of these
   * services.
   *
   * @returns true if export service has been loaded or false otherwise
   */
  private initServices(): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      lazyLoadExportsModule
        .loadChildren()
        .then(elementModuleOrFactory => {
          if (elementModuleOrFactory instanceof NgModuleFactory) {
            // ViewEngine compiler
            const module = elementModuleOrFactory.create(this.injector);
            this.exportService = module.injector.get(ExportService);
            resolve(true);
          } else {
            // Ivy compiler (newer)
            this.compiler.compileModuleAsync(elementModuleOrFactory).then(factory => {
              const module = factory.create(this.injector);
              this.exportService = module.injector.get(ExportService);
              resolve(true);
            });
          }
        })
        .catch(reason => {
          console.log(reason);
          reject(false);
        });
    });
  }

  public getExportService(): Promise<ExportService> {
    return new Promise<ExportService>((resolve, reject) => {
      if (this.exportService == null) {
        this.initServices()
          .then(result => {
            if (result) {
              resolve(this.exportService);
            }
          })
          .catch(reason => {
            console.log(reason);
            reject(null);
          });
      } else {
        resolve(this.exportService);
      }
    });
  }

  public getExcelService(): Promise<ExcelService> {
    return new Promise<ExcelService>((resolve, reject) => {
      if (this.exportService == null) {
        this.getExportService()
          .then(service => {
            resolve(service.getExcelService());
          })
          .catch(err => reject(null));
      } else {
        resolve(this.exportService.getExcelService());
      }
    });
  }

  public getPdfService(): Promise<PdfService> {
    return new Promise<PdfService>((resolve, reject) => {
      if (this.exportService == null) {
        this.getExportService()
          .then(service => {
            resolve(service.getPdfService());
          })
          .catch(err => reject(null));
      } else {
        resolve(this.exportService.getPdfService());
      }
    });
  }
}
