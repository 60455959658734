import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { FormDropdown } from '@app/shared/interfaces/common.interface';
import { ConfigService } from '@app/shared/services/config/config.service';
import { DevicesService } from '@app/shared/services/devices/devices.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Device } from '../../interfaces/device.interface';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, OnDestroy {
  selectedDeviceId: string = null;
  deviceAppVersion = '-';
  devices: Device[] = [];
  deviceOptions: FormDropdown[] = [];
  formControl = new FormControl('', Validators.required);
  errorTranslations = [{ required: 'errors.field.required' }];
  loaded = false;
  protected onDestroy = new Subject<void>();

  constructor(
    public devicesService: DevicesService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    if (this.devicesService.hasDevices()) {
      this.populateDevices(this.devicesService.devices);
      this.setSelectedDevice(this.devicesService.currentDevice.identityId);
      this.loaded = true;
    } else {
      this.devicesService
        .getDevices()
        .pipe(takeUntil(this.onDestroy))
        .subscribe(devs => {
          this.populateDevices(devs);
          this.setSelectedDevice(this.devicesService.currentDevice.identityId);
          this.loaded = true;
        });
    }
  }

  private populateDevices(devs: Device[]) {
    this.devices = devs || [];
    this.deviceOptions = this.devices.map(device => {
      return { value: device.identityId, description: device.name };
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  /**
   * Reset company selection form
   */
  resetSelection() {
    this.devices = [];
  }

  /**
   * Clear selected device
   */
  resetSelectedDevice() {
    this.setSelectedDevice(null);
  }

  /**
   * Helper to set selected device
   * @param id device's id
   */
  private setSelectedDevice(id: string) {
    this.selectedDeviceId = id;
    this.formControl.setValue(id);
    this.configService
      .getAppVersion()
      .pipe(takeUntil(this.onDestroy))
      .subscribe(version => (this.deviceAppVersion = version ? version : '-'));
  }

  /**
   * Select devices and stores it into localStorage
   */
  selectDevice($event: MatSelectChange) {
    const selectedDevice = this.devicesService.devices.find(
      d => d.identityId === $event.value
    );

    if (selectedDevice) {
      this.setSelectedDevice(selectedDevice.identityId);
      this.devicesService.currentDevice = selectedDevice;
      location.reload();
    }
  }
}
