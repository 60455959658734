export const environment = {
  // project - angular.json project
  project: 'backoffice',

  // production or not
  production: false,

  // subpath of frontend
  baseHref: '',

  // base url api with base path
  apiUrl: '',

  // # APPEARANCE SETTINGS #
  appearances: {
    // login page style
    login: 'modern', // "classic", "modern"

    // to show/hide app's title on login page
    showTitleOnLogin: true,

    // app's header style
    header: 'modern-dark', // "classic", "modern", "modern-dark"

    // to show/hide app's title on main header (logged in)
    showAppTitleOnHeader: true,

    // where to show the active page's title
    pageTitle: 'body', // "header", "body"
  },

  // is takeaway enabled
  takeawayEnabled: true,

  // is BKP enabled
  bkpEnabled: true,

  // is FIK enabled
  fikEnabled: true,

  // is returnable packaging enabled
  returnablePackagingEnabled: true,

  // is currency settings enabled
  currencySettingEnabled: true,

  // POS user configuration
  posUser: {
    // is user TIN field enabled
    tinEnabled: false,
    // max length for user TIN
    tinMaxLength: 20,
  },

  partner: {
    // is buyer cost center enabled
    costCenterIdEnabled: false,
    // max length for buyer cost center
    costCenterMaxLength: 20,
  },

  // ONLY IF `resetPasswordExternally: true`:
  // reset password on external URL (used on login page)
  resetPasswordUrl: 'https://moje.o2.cz/web/o2/registration/-/registration/deeplink/restorePassword',

  // If true, `resetPasswordUrl` will be shown instead of reset password thru ECR Freshap
  resetPasswordExternally: false,

  // NgcCookieConsent domain, mandatory to fill
  cookieDomain: '',

  // # LANGUAGE SETTINGS #
  // default language if not already selected
  defaultLanguage: 'cz',
  defaultLocale: 'cs-CZ',
  localStoragePrefix: 'bo',

  // list of available languages
  languageCodes: ['en', 'cz', 'ua', 'sr-Latn', 'sr-Cyrl'],

  // code to locale definition
  languageCodeToLocale: {
    en: 'en-US',
    cz: 'cs-CZ',
    ua: 'ru-UA',
    'sr-Latn': 'sr-Latn',
    'sr-Cyrl': 'sr-Cyrl',
  },

  // # DECIMAL FORMAT #
  defaultDecimalFormat: '.',
  czDecimalFormat: ',',

  // # DEFAULT CURRENCY #
  defaultCurrency: 'CZK',
  defaultCurrencySymbol: 'Kč',

  // # NG-HTTP-LOADER SETTINGS #
  // after how many milliseconds the spinner will be visible, if needed
  ngHttpLoaderDebounceDelay: 100,
  // background color of overlay
  ngHttpLoaderBackgroundColor: '#04256C',
  // url patterns on which we do not want spinner to show
  ngHttpLoaderFilteredUrlPatterns: ['api/graphs', 'api/journals/stat-info-dashboard', 'api/journals/overall'],
  // how many milliseconds should the spinner be visible at least
  ngHttpLoaderMinDuration: 100,
  // spinner type
  ngHttpLoaderSpinner: 'skThreeBounce',

  // snackbar duration in milliseconds
  snackbarDuration: 5000,

  // # WIRECARD SETTINGS #
  // whether this project uses Wirecard or not
  wirecardEnabled: false,
  // disable check on "/wirecard" path, so wirecard form is open event if already submitted
  temporarilyDisableWirecardCheck: false,

  // # MISC CONFIG #
  // Data reload config
  reloadRoute: ['/empty'],

  // Name of the default group
  defaultGroupValue: 'Default Group',

  // Value or key for default measuring unit (ex: to be automatically selected during create new product)
  defaultMeasuringUnit: 'MU2',

  // payment types
  paymentMethodTypes: ['Cash', 'Card - ext.', 'Cheque', 'Bank transfer', 'Meal voucher', 'eMeal voucher'],

  // removed payment methods for config service
  removedPaymentMethodsByType: ['Card'],

  // RegExp for zip code (set null to bypass format checking)
  zipCodeRegex: null,

  // max length for zip code characters
  zipCodeMaxLength: 6,

  // RegExp for valid VAT ID format
  vatIdRegex: '^CZ([0-9]{8,10})$',
  // min length for VAT ID
  vatIdMinLength: 10,
  // max length for VAT ID
  vatIdMaxLength: 12,

  // # DATA EXPORTS #
  exports: {
    pdf: {
      defaultOrientation: 'landscape',
      tableHeaderFillColor: '#004d89',
      tableHeaderTextColor: '#ffffff',
      tableFooterFillColor: '#000000',
      tableFooterTextColor: '#ffffff',
      headerLogo: {
        src: 'assets/images/logo-o2.png',
        format: 'PNG',
        width: 30,
        height: 12,
      },
      sizeOptions: [
        { label: 'A4', unit: 'mm', width: 210, height: 297 },
        { label: 'A5', unit: 'mm', width: 148, height: 210 },
        { label: 'A3', unit: 'mm', width: 297, height: 420 },
        { label: 'Letter', unit: 'mm', width: 216, height: 280 },
      ],
    },
  },

  // # PRODUCTS LOADER/IMPORT #
  loader: {
    // URL for downloadable template sample
    productTemplatePath: 'https://ecr-dev.tvc-info.hr/files/Loader.xlsx',
  },

  // # PRODUCTS VARIATIONS #
  variations: {
    // maximum allowed product's variable keys (or names) such as `size`, `color`, etc
    maxVars: 2,
    // maximum allowed variable values per variable key such as `size=M`, `size=L`, `size=XL`, etc
    maxValues: 6,
  },

  // # IMAGE CROPPER #
  imageCropper: {
    // aspect ratio number to be used to force image cropper ratio
    aspectRatio: 1 / 1,

    // Output format (png, jpeg, webp, or bmp)
    resultFormat: 'png',

    // allowed MIME type for image cropper (only raster image is allowed)
    mimeType: 'image/jpeg,image/png,image/bmp',

    // minimum width for image cropper
    minWidth: 384,

    // minimum height for image cropper
    minHeight: 384,
  },

  // # RECEIPT LOGO CONFIG #
  receiptLogo: {
    // max allowed image logo size in bytes
    maxFileSize: 2097152,
    // allowed MIME type for image logo file selection
    mimeType: 'image/jpeg,image/png,image/bmp',
    // default receipt logo size in milimeters when printed (e.g. on receipt pdf)
    printWidth: 24,
    printHeight: 18,
  },

  // Password setup configuration
  // valid regexp for password format (or set null for no format)
  userPasswordRegex: null,
  userPasswordMinLength: 13,
  userPasswordMaxLength: 50,

  // # URL PATHS #
  authenticationPath: 'api/auth/login',
  authSendSms: 'api/auth/send-sms',
  authVerifyCode: 'api/auth/verify-code',
  resetPasswordPath: 'api/auth/reset-password',
  companiesForUserPath: 'api/companies/for-user',
  companiesForUserByPhoneNumber: 'api/companies/phone-number',
  companiesForUserByUserId: 'api/companies/for-user-id',
  configItemsPath: 'api/config-items',
  configItemsBySectionsPaths: 'api/config-items/sections',
  configItemsCompanyPath: 'api/config-items/company',
  configItemsCurrencyPath: 'api/config-items/currency',
  configItemsAvailableCurrencyPath: 'api/config-items/currency/available',
  configItemsDevicePath: 'api/config-items/device',
  configItemsEstablishmentPath: 'api/config-items/establishment',
  configItemsGeneralPath: 'api/config-items/general',
  configItemsMeasuringUnitsPath: 'api/config-items/measuringUnits',
  configItemsPaymentmethodsPath: 'api/config-items/paymentMethods',
  configItemsUsersPath: 'api/config-items/users',
  configItemsVatsPath: 'api/config-items/vats',
  configItemsBatchPath: 'api/config-items/batch',
  devicesPath: 'api/identities',
  eventLogsPath: 'api/event-logs',
  journalsPath: 'api/journals',
  receiptsClosedPath: 'api/receipts/status/closed',
  receiptsPath: 'api/receipts',
  proformaInvoicesPath: 'api/proforma-invoices',
  priceChangesPath: 'api/price-changes',
  wirecardPath: 'api/signups',
  measuringUnitPath: 'api/config-items/measuringUnits',
  paymentMethodPath: 'api/config-items/paymentMethods',
  catalogItemsByIdentityTypePath: 'api/catalog-items/identity-type',
  catalogItemsByIdentityPath: 'api/catalog-items',
  catalogItemsByTypePath: 'api/catalog-items/type',
  productItemsPath: 'api/products',
  productItemsStockTrackedPath: 'api/products/tracked',
  productGroupsPath: 'api/product-groups',
  productReturnableGroupPath: 'api/product-groups/returnable',
  statisticInfoForDashboardPath: 'api/journals/stat-info-dashboard',
  usersPath: 'api/config-items/users',
  usersCanDeletePath: 'api/config-items/users/can-delete',
  appPermissions: 'api/config-items/app-permissions',
  incomingReceiptsPath: 'api/warehouse/incoming-receipts',
  incomingReceiptTypesPath: 'api/warehouse/incoming-receipts/types',
  importProductsUploadPath: 'api/loader',
  suppliersPath: 'api/partners/suppliers',
  clientsPath: 'api/partners/clients',
  partnersPath: 'api/partners',
  /** base path for route warehouse-document. commons for write-off, incoming-receipt etc */
  warehousePath: 'api/warehouse',
  warehouseBasePath: 'api/warehouse/warehouse',
  writeOffBasePath: 'api/warehouse/write-off',
  warehouseDocumentsBasePath: 'api/warehouse/warehouse-documents',
  actionsBasePath: 'api/warehouse/actions',
  graphBasePath: 'api/graphs',
  /**
   * base path for return to supplier
   */
  returnToSupplierBasePath: 'api/warehouse/return-to-supplier',
  returnablePackagingBasePath: 'api/returnable-packaging',
  inventoryBasePath: 'api/warehouse/inventory',
  /**
   * base path for galleries
   */
  imageGalleryPath: 'api/icons',
  imageGalleryCategoryPath: 'api/icons/categories',
  merchantsPath: 'api/merchants',
};
