<mat-card class="dashboard-widget cash-register-status-widget full-height">
  <mat-card-content>
    <div class="status-row d-flex" *ngIf="log !== null; else nostatus">
      <div class="status-row-icon">
        <span [ngClass]="state === 'open' ? 'status-indicator active' : 'status-indicator inactive'"></span>
      </div>
      <div class="status-row-text" [innerHTML]="text | translate: { user: user, timestamp: formatTimestamp() }"></div>
    </div>
    <ng-template #nostatus>
      <div class="status-row d-flex">
        <div class="status-row-icon">
          <span class="status-indicator inactive"></span>
        </div>
        <div class="status-row-text">
          {{ 'dashboard.cash_register.no_activity' | translate }}
        </div>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
