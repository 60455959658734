import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchSubject = new BehaviorSubject<any>('');

  search$: Observable<any> = this.searchSubject.asObservable();

  constructor() {}

  onSearch(value: any) {
    this.searchSubject.next(value);
  }

  onClear() {
    if (this.searchSubject.value) {
      this.searchSubject.next('');
    }
  }
}
