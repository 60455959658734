import { MostSellingProductsGraphView } from './graphs/most-selling-products.graph-view';
import { OverallGraphView } from './graphs/overall.graph-view';
import { RevenuePaymentMethodsGraphView } from './graphs/revenue-payment-methods.graph-view';
import { RevenueProductGroupsGraphView } from './graphs/revenue-product-groups.graph-view';
import { RevenueUsersGraphView } from './graphs/revenue-users.graph-view';
import { RevenueGraphView } from './graphs/revenue.graph-view';
import { OrganisedGraphData } from './interfaces/graph.interface';

/**
 * Registered graph widgets that will be organised by GraphOrganiserComponent.
 */
export const DEFAULT_ORGANISED_GRAPHS: OrganisedGraphData[] = [
  {
    id: 'Revenue',
    graphView: new RevenueGraphView(),
    visibility: true,
    column: '1/2',
  },
  {
    id: 'RevenueProductGroups',
    graphView: new RevenueProductGroupsGraphView(),
    visibility: true,
    column: '1/2',
  },
  {
    id: 'RevenuePaymentMethods',
    graphView: new RevenuePaymentMethodsGraphView(),
    visibility: true,
    column: '1/2',
  },
  {
    id: 'MostSellingProducts',
    graphView: new MostSellingProductsGraphView(),
    visibility: true,
    column: '1/2',
  },
  {
    id: 'OverallStatistics',
    graphView: new OverallGraphView(),
    visibility: true,
    column: '1/2',
  },
  {
    id: 'RevenueUsers',
    graphView: new RevenueUsersGraphView(),
    visibility: true,
    column: '1/2',
  },
];
