import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatDividerModule,
  MatIconModule,
  MatMenuModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { SearchTableComponent } from '@app/shared/components/search-table/search-table.component';
import { MatPaginatorI18nService } from '@app/shared/services/mat-paginator-i18n/mat-paginator-i18n.service';
import { FilterPipe } from '@app/shared/services/pipes/filter.pipe';
import { LocalizedDatePipe } from '@app/shared/services/pipes/localized-date.pipe';
import { LocalizedDateTimePipe } from '@app/shared/services/pipes/localized-datetime.pipe';
import { NoNullPipe } from '@app/shared/services/pipes/nonull.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FormDateComponent } from './components/generic-forms/form-date.component';
import { FormImageUploadComponent } from './components/generic-forms/form-image-upload.component';
import { FormInputComponent } from './components/generic-forms/form-input.component';
import { FormPasswordComponent } from './components/generic-forms/form-password.component';
import { FormSelectDialogComponent } from './components/generic-forms/form-select-dialog.component';
import { FormSelectSearchComponent } from './components/generic-forms/form-select-search.component';
import { FormTextAreaComponent } from './components/generic-forms/form-textarea.component';
import { FormTimepickerComponent } from './components/generic-forms/form-timepicker.component';
import { FormToggleComponent } from './components/generic-forms/form-toggle.component';
import { ImageCropperDialogComponent } from './components/image-cropper-dialog/image-cropper-dialog.component';
import { IconCategoryTreeComponent } from './components/image-gallery/icon-category-tree/icon-category-tree.component';
import { ImageEnlargeDialogComponent } from './components/image-gallery/image-enlarge-dialog/image-enlarge-dialog.component';
import { ImageGalleryDetailComponent } from './components/image-gallery/image-gallery-detail/image-gallery-detail.component';
import { ImageGalleryPickerDialogComponent } from './components/image-gallery/image-gallery-picker-dialog/image-gallery-picker-dialog.component';
import { ImageGalleryPickerComponent } from './components/image-gallery/image-gallery-picker/image-gallery-picker.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery/image-gallery.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { PageDetailComponent } from './components/page-detail/page-detail.component';
import { PageComponent } from './components/page/page.component';
import { SearchComponent } from './components/search/search.component';
import { TablePageComponent } from './components/table-page/table-page.component';
import { TableComponent } from './components/table/table.component';
import { TitleComponent } from './components/title/title.component';
import { ValidatedFieldComponent } from './components/validate-field/validated-field/validated-field.component';
import { ResizedDirective } from './directives/resized.directive';
import { AuthGuard } from './guards/auth.guard';
import { ModulesGuard } from './guards/modules.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { CompanyIdInterceptor } from './interceptors/comapnyId.interceptor';
import { EstablishmentIdInterceptor } from './interceptors/establishment-id.interceptor';
import { IdentityIdInterceptor } from './interceptors/identityId.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { TimezoneOffsetInterceptor } from './interceptors/timezone-offset.interceptor';
import { AlertsService } from './services/alerts/alerts.service';
import { AuthService } from './services/auth/auth.service';
import { CompanyService } from './services/company/company.service';
import { ConfigService } from './services/config/config.service';
import { DevicesService } from './services/devices/devices.service';
import { DynamicPipe } from './services/pipes/dynamic.pipe';
import { FormatCurrencyPipe } from './services/pipes/format-currency.pipe';
import { FormatNumberPipe } from './services/pipes/format-number.pipe';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    TitleComponent,
    SearchComponent,
    TableComponent,
    PageComponent,
    TablePageComponent,
    PageDetailComponent,
    FilterPipe,
    NoNullPipe,
    FormatNumberPipe,
    FormatCurrencyPipe,
    LocalizedDatePipe,
    LocalizedDateTimePipe,
    SearchTableComponent,
    ValidatedFieldComponent,
    FormSelectSearchComponent,
    FormInputComponent,
    FormDateComponent,
    FormSelectDialogComponent,
    FormTextAreaComponent,
    FormToggleComponent,
    FormImageUploadComponent,
    ResizedDirective,
    FormTimepickerComponent,
    DynamicPipe,
    ImageGalleryPickerComponent,
    ImageGalleryPickerDialogComponent,
    ImageEnlargeDialogComponent,
    ImageGalleryComponent,
    ImageGalleryDetailComponent,
    IconCategoryTreeComponent,
    ImageCropperDialogComponent,
    ConfirmationDialogComponent,
    LanguageSelectorComponent,
    FormPasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatButtonModule,
    MatChipsModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTableModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTreeModule,
    MatProgressSpinnerModule,
    TranslateModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    ImageCropperModule,
  ],
  exports: [
    ConfirmDialogComponent,
    FilterPipe,
    NoNullPipe,
    DynamicPipe,
    LocalizedDatePipe,
    FormatNumberPipe,
    FormatCurrencyPipe,
    PageComponent,
    SearchComponent,
    TableComponent,
    TablePageComponent,
    TitleComponent,
    SearchTableComponent,
    PageDetailComponent,
    LocalizedDateTimePipe,
    ValidatedFieldComponent,
    FormSelectSearchComponent,
    FormInputComponent,
    FormDateComponent,
    FormToggleComponent,
    FormTextAreaComponent,
    FormImageUploadComponent,
    ResizedDirective,
    FormTimepickerComponent,
    ImageGalleryPickerComponent,
    ImageGalleryPickerDialogComponent,
    ImageEnlargeDialogComponent,
    ImageGalleryComponent,
    ImageGalleryDetailComponent,
    ImageCropperDialogComponent,
    LanguageSelectorComponent,
    FormPasswordComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ConfirmationDialogComponent,
    ImageGalleryPickerDialogComponent,
    ImageEnlargeDialogComponent,
    ImageCropperDialogComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AlertsService,
        AuthGuard,
        ModulesGuard,
        AuthService,
        ConfigService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: IdentityIdInterceptor,
          multi: true,
          deps: [DevicesService],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CompanyIdInterceptor,
          multi: true,
          deps: [CompanyService],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EstablishmentIdInterceptor,
          multi: true,
          deps: [CompanyService],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TimezoneOffsetInterceptor,
          multi: true,
        },
        NoNullPipe,
        FormatNumberPipe,
        {
          provide: MatPaginatorIntl,
          useClass: MatPaginatorI18nService,
        },
        DynamicPipe,
      ],
    };
  }
}
