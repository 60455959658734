import { HttpParams } from '@angular/common/http';
import { BaseApiService } from '@app/shared/bases/base-api.service';
import { formatDate } from '@app/shared/helpers/date';
import { randomizeArray } from '@app/shared/helpers/helpers';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Service to fetch graphs' data from backend.
 */
export class GraphDataService extends BaseApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    /**
     * Provide graph's default color scheme
     * @param colorScheme optional - default array of hexadecimal color strings
     * @param randomized whether the returned color scheme should be randomized or not
     * @returns object with color scheme domain
     */
    getDefaultColorScheme(colorScheme, randomized = false) {
        const domain = colorScheme != null
            ? [...colorScheme]
            : [
                '#003763',
                '#bd413e',
                '#0ec7ac',
                '#1648ca',
                '#7dcb85',
                '#e0e11b',
                '#436a40',
                '#29cc7a',
                '#02d9cd',
                '#2b64ab',
                '#3a2dc5',
                '#b5d131',
                '#622dda',
                '#f72d58',
                '#0c876f',
                '#b36314',
                '#f21162',
                '#850a3f',
                '#14a027',
                '#854741',
                '#cb9cc8',
                '#f4a750',
                '#e91b9c',
                '#1ee47e',
                '#e5c7a7',
                '#6955cf',
                '#a43f69',
                '#41c3be',
                '#c0a13a',
                '#333333',
            ];
        if (randomized) {
            return { domain: randomizeArray(domain) };
        }
        return { domain };
    }
    /**
     * Helper to get data from the given start date to end date
     * @param url
     * @param from
     * @param to
     * @returns Observable<T>
     */
    getData(url, from, to) {
        const params = new HttpParams()
            .set('startDate', formatDate({ date: from, format: 'yyyy-MM-dd' }))
            .set('endDate', formatDate({ date: to, format: 'yyyy-MM-dd' }));
        return this.get(url, params);
    }
    /**
     * Get revenue from the given range of dates.
     * @param from start date to query revenue
     * @param to end date to query revenue
     */
    getRevenue(from, to) {
        return this.getData(`${environment.graphBasePath}/daily`, from, to);
    }
    /**
     * Get revenue grouped by product groups, on the given date range.
     * @param from start date to query revenue
     * @param to end date to query revenue
     */
    getRevenueByProductGroups(from, to) {
        return this.getData(`${environment.graphBasePath}/by-product-groups`, from, to);
    }
    /**
     * Get revenue grouped by payment methods, on the given date range.
     * @param from start date to query revenue
     * @param to end date to query revenue
     */
    getRevenueByPaymentMethods(from, to) {
        return this.getData(`${environment.graphBasePath}/by-payment-methods`, from, to);
    }
    /**
     * Get revenue grouped by username, on the given date range .
     * @param from start date to query revenue
     * @param to end date to query revenue
     */
    getRevenueByUsers(from, to) {
        return this.getData(`${environment.graphBasePath}/by-users`, from, to);
    }
    /**
     * Get overall statistics
     */
    getOverallStatistics(from, to) {
        return this.getData(`${environment.journalsPath}/overall`, from, to);
    }
    /**
     * Get most selling products on the given date range and limit.
     * @param from start date to query revenue
     * @param to end date to query revenue
     * @param limit maximum number of products data to be returned
     */
    getMostSellingProducts(from, to, limit) {
        const params = new HttpParams()
            .set('startDate', formatDate({ date: from, format: 'yyyy-MM-dd' }))
            .set('endDate', formatDate({ date: to, format: 'yyyy-MM-dd' }))
            .set('limit', limit.toString());
        return this.get(`${environment.graphBasePath}/most-selling`, params);
    }
}
GraphDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GraphDataService_Factory() { return new GraphDataService(i0.ɵɵinject(i1.HttpClient)); }, token: GraphDataService, providedIn: "root" });
