/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fiscalization-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./fiscalization-status.component";
import * as i8 from "../../../shared/services/config/config.service";
var styles_FiscalizationStatusComponent = [i0.styles];
var RenderType_FiscalizationStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FiscalizationStatusComponent, data: {} });
export { RenderType_FiscalizationStatusComponent as RenderType_FiscalizationStatusComponent };
export function View_FiscalizationStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "mat-card", [["class", "dashboard-widget fiscalization-status-widget full-height mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "status-row d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "status-row-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "status-row-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.fiscalizationOn === true) ? "status-indicator active" : "status-indicator inactive"); _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(((_co.fiscalizationOn === true) ? "common.fiscalization.active" : "common.fiscalization.inactive"))); _ck(_v, 10, 0, currVal_2); }); }
export function View_FiscalizationStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fiscalization-status", [], null, null, null, View_FiscalizationStatusComponent_0, RenderType_FiscalizationStatusComponent)), i1.ɵdid(1, 245760, null, 0, i7.FiscalizationStatusComponent, [i8.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FiscalizationStatusComponentNgFactory = i1.ɵccf("app-fiscalization-status", i7.FiscalizationStatusComponent, View_FiscalizationStatusComponent_Host_0, {}, {}, []);
export { FiscalizationStatusComponentNgFactory as FiscalizationStatusComponentNgFactory };
