import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Icon, IconCategoryNode } from '@app/shared/models/catalog-item.model';
import { GalleryImagesFilter, GalleryService } from '@app/shared/services/gallery/gallery.service';
import { TranslateService } from '@ngx-translate/core';

export interface IconMetaForUI {
  active: boolean;
}

export type ImageGallerySelectionType = 'single' | 'multiple';

export type ImageGalleryModeType = 'normal' | 'selector';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  /**
   * Mode of usage:
   * 'normal' for standard page (e.g. on Image Gallery page)
   * 'selector' for using this component on image gallery picker
   */
  @Input() mode: ImageGalleryModeType = 'normal';

  /**
   * Selection mode when a gallery image is clicked. On 'single', the gallery
   * detail panel will be visible upon click. While 'multiple' selection will
   * not showing the detail panel.
   */
  @Input() selection: ImageGallerySelectionType = 'single';

  /**
   * Initially selected images
   */
  @Input() initialSelected: Icon[] = [];

  /**
   * Emit array of Icon containing all currently selected images (selectedImages)
   */
  @Output() selected = new EventEmitter<Icon[]>();

  /**
   * FormGroup for the search form
   */
  searchForm: FormGroup;

  filter?: GalleryImagesFilter;

  /**
   * Holds the valid submitted search
   */
  currentSearchFilter: GalleryImagesFilter;

  currentCategory: IconCategoryNode;

  /**
   * Currently displayed images / folder
   */
  images: Icon[] = [];

  /**
   * Currently selected image(s) on images panel
   */
  selectedImages: Icon[] = [];

  /**
   * Images panel uses this classes to adjust it's width
   */
  galleryPanelClasses = 'images-panel col-lg-10 col-md-12';

  /**
   * Flag for showing and hiding detail panel
   */
  detailPanelShowing = false;

  isSearched = false;

  /** Pagination stuff -> zTODO: should be replaced to MatPaginator later */
  sizes = [36, 72];
  selectedSize = this.sizes[0];
  page = 0;
  totalPage = 0;
  totalElements = 0;
  numberOfElements = 0;

  constructor(
    private fb: FormBuilder,
    private galleryService: GalleryService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      search: ['', [Validators.maxLength(128)]],
    });

    this.reloadImage(() => {
      if (typeof this.initialSelected !== 'undefined') {
        if (this.initialSelected.length) {
          this.initialSelected.forEach(image => {
            if (typeof image.meta === 'object') {
              image.meta = {
                active: true,
              } as IconMetaForUI;
            }
          });
          this.setSelectedImages(this.initialSelected);
        }
      }
      this.isSearched = false;
    });
  }

  getImage(index: number) {
    const unsafeResource = `data:${this.images[index].contentType};base64,`.concat(this.images[index].file.data);
    return this.sanitizer.bypassSecurityTrustResourceUrl(unsafeResource);
  }

  getDefaultImage(event, index: number) {
    const unsafeResource = `data:${this.images[index].contentType};base64,`.concat(this.images[index].file.data);
    event.target.src = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeResource);
  }

  reloadImage(callback?: () => void) {
    this.galleryService.getIcons(this.page, this.selectedSize, this.filter).subscribe(res => {
      if (res) {
        this.images = res.content;
        this.totalPage = res.totalPages;
        this.totalElements = res.totalElements;
        this.numberOfElements = res.numberOfElements;

        if (this.currentCategory) {
          this.currentCategory.children.forEach(val => {
            this.images.unshift({ folder: val });
          });
        }

        if (this.mode === 'normal') {
          this.clearSelectedImages();
        } else {
          this.setSelectedImages(this.selectedImages);
        }

        if (typeof callback === 'function') {
          callback();
        }
      } else {
        this.page = 0;
        this.totalPage = 0;
        this.totalElements = 0;
        this.numberOfElements = 0;
      }
    });
  }

  changePagingSize() {
    this.page = 0;
    this.reloadImage();
  }

  showNext() {
    this.page++;
    this.reloadImage();
  }

  showPrev() {
    this.page--;
    this.reloadImage();
  }

  clearSelectedImages() {
    this.setSelectedImages([]);
    this.hideDetailPanel();
  }

  showFirst() {
    this.page = 0;
    this.reloadImage();
  }

  showLast() {
    this.page = this.totalPage ? this.totalPage - 1 : 0;
    this.reloadImage();
  }

  /**
   * Activate/deactivate (toggle) this image from selectedImages
   */
  onImageClick(image: Icon) {
    if (image.meta) {
      if (image.meta.active) {
        // deactivate
        this.setSelectedImages(this.selectedImages.filter(img => img.id !== image.id));
        return;
      }
    }
    // activate
    this.appendToSelectedImages(image);
  }

  /**
   * Append the given image into selectedImages
   */
  appendToSelectedImages(image: Icon) {
    if (this.selection === 'single') {
      this.setSelectedImages([image]);
    } else {
      let checkFound = null;
      if (this.selectedImages.length) {
        checkFound = this.selectedImages.find(img => img.id === image.id);
      }

      if (checkFound === null || typeof checkFound === 'undefined') {
        this.selectedImages.push(image);
        this.setSelectedImages(this.selectedImages);
      }
    }
  }

  filterByCategory(category: IconCategoryNode) {
    this.filter = {
      categoryId: category.id,
    };
    this.page = 0
    this.currentCategory = category;
    this.page = 0;
    this.reloadImage();
    this.searchForm.reset();
    this.isSearched = false;
  }

  private setSelectedImages(imgs: Icon[]) {
    this.selectedImages = imgs;

    // clears out meta
    this.images.forEach(image => {
      if (typeof image.meta === 'object') {
        image.meta.active = false;
      }
    });

    if (this.selectedImages.length) {
      this.selectedImages.forEach(image => {
        this.images.forEach(timg => {
          if (timg.id === image.id) {
            timg.meta = {
              active: true,
            } as IconMetaForUI;
          }
        });
      });

      // on single selection
      if (this.selection === 'single' && this.selectedImages.length === 1) {
        if (this.selectedImages[0]) {
          this.showDetailPanel();
          this.scrollTo('selected-image-panel');
        } else {
          this.hideDetailPanel();
        }
      }
    } else {
      this.hideDetailPanel();
    }

    this.selected.emit(this.selectedImages);
  }

  /**
   * Usable for mobile screen: scroll to detail panel upon clicking any image
   */
  private scrollTo(className: string) {
    setTimeout(() => {
      const elementList = document.querySelectorAll('.' + className);
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  showDetailPanel() {
    if (this.selectedImages != null) {
      this.detailPanelShowing = true;
      this.galleryPanelClasses = 'col-lg-6 col-md-12 images-panel';
    }
  }

  hideDetailPanel() {
    this.detailPanelShowing = false;
    this.galleryPanelClasses = 'col-lg-10 col-md-12 images-panel';
  }

  onCloseDetailPanel() {
    if (this.mode === 'normal' || this.selection === 'single') {
      this.clearSelectedImages();
    } else {
      this.hideDetailPanel();
    }
  }

  onSearch() {
    this.filter = {
      name: this.searchForm.value.search,
      locale: this.translateService.currentLang
    };
    this.reloadImage();
    this.isSearched = true;
    this.currentCategory = null;
  }

  onResetSearch() {
    this.filter = null;
    this.searchForm.reset();
    this.reloadImage();
    this.isSearched = true;
    this.currentCategory = null;
  }

  onClickGalleryImagesPanel($event) {
    if ($event.target.tagName !== 'IMG') {
      if (this.mode === 'normal') {
        this.clearSelectedImages();
      } else {
        this.hideDetailPanel();
      }
    }
  }
}
