import * as i0 from "@angular/core";
import * as i1 from "../services/devices/devices.service";
export class IdentityIdInterceptor {
    constructor(devicesService) {
        this.devicesService = devicesService;
    }
    intercept(request, next) {
        if (this.devicesService.identityId) {
            request = request.clone({
                setHeaders: {
                    'x-identity-id': this.devicesService.identityId,
                },
            });
        }
        return next.handle(request);
    }
}
IdentityIdInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdentityIdInterceptor_Factory() { return new IdentityIdInterceptor(i0.ɵɵinject(i1.DevicesService)); }, token: IdentityIdInterceptor, providedIn: "root" });
