import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Compiler,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger, MatSlideToggleChange } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { Params } from '@angular/router';
import { AutoUnsubscribe } from '@app/core/decorators/autounsubscribe.decorator';
import { ExportServiceLoader } from '@app/exports/exports-service.loader';
import { PdfOptions } from '@app/exports/interfaces/pdf.interface';
import { ExportService } from '@app/exports/services/export/export.service';
import { ExportType } from '@app/exports/services/exporter.service';
import { TableDataSource } from '@app/shared/bases/table.datasource';
import { getFormattedFileName } from '@app/shared/helpers/helpers';
import { AlertsService } from '@app/shared/services/alerts/alerts.service';
import { SearchService } from '@app/shared/services/search/search.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, repeatWhen, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * Table's row menu items
 */
export interface TableRowMenuItem<DATA = any> {
  /**
   * the menu's label
   */
  label: string;

  /**
   * menu's icon, taken from Material icon
   */
  icon: string;

  /**
   * menu's on click action
   */
  action: ($event: MouseEvent, row: DATA, target?: boolean, windowFeatures?: string) => void;

  /**
   * Show menu's context menu or not (ex: to open in new tab)
   */
  hasContextMenu?: boolean | ((row: DATA) => boolean);

  /**
   * hide action if function evaluets to true
   */
  hide?: (row: DATA) => boolean;
}

/**
 * Table's column items
 */
export interface TableColumn<DATA = any> {
  /**
   * header column label
   */
  name: string;

  /**
   * key of column value
   */
  key: string;

  /**
   * 'text' display type will be used if not set
   * 'html' display type will render any html code
   * 'template' display will render ref that passed to component
   */
  type?: 'text' | 'chips' | 'html' | 'toggle' | 'template';

  /** cell content alignment (default = '') */
  align?: 'left' | 'center' | 'right' | 'start' | 'end';

  // An event will be dispatched each time the slide-toggle changes its value.
  toggleChange?: (event: MatSlideToggleChange, row: DATA) => void;

  // required if type='chips'
  chipKey?: string;

  /**
   * key for footer column's value (optional)
   */
  footerKey?: string;

  /**
   * When set, this will be used instead the actual footer value (optional)
   */
  footerDefaultValue?: string;

  /**
   * Get the formatted value
   * @param data The value to be formatted
   */
  getValue?: (data: DATA) => string;

  /**
   * Get formatted footer value
   * @param data The value to be formatted
   */
  getFooterValue?: (data: DATA) => string;

  /**
   * Get CSS classes for the current displayed cell
   * @param data current row
   * @param rowIndex cell's row index (zero based)
   * @param colIndex cell's column index (zero based)
   */
  getCellClasses?: (data: DATA, rowIndex: number, colIndex: number) => string;

  /** Optional CSS classes for the column's header */
  headerClasses?: string;

  /** Optional CSS classes for the column's footer */
  footerClasses?: string;

  /**
   * getter template for column.<br/>
   * Parameter for template are : <ol>
   *   <li>rowData = data for current row</li>
   *   <li>key key of column. same as field key</li>
   *   <li>value value from current row , for current column</li>
   *   </ol>
   * @param data  current data row
   */
  getTemplate?: (data: DATA) => ElementRef;

  /**
   * Hide table's cell if needed
   */
  hide?: (row: DATA) => boolean;
}

export interface TableFooterColumn {
  /**
   * key of column value
   */
  key: string;
}

export interface TableColumns extends Array<TableColumn> {}

export interface TableRowMenuItems<DATA = any> extends Array<TableRowMenuItem<DATA>> {}

export interface TableRowClass {
  index: number;
  classes: string;
}

export interface TableRowClasses extends Array<TableRowClass> {}

@AutoUnsubscribe()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject<void>();

  @Input() columns: Observable<TableColumns>;
  @Input() dataSource: TableDataSource<any>;
  @Input() rowMenus: Observable<TableRowMenuItems>;
  @Input() showFooter = false;
  @Input() showMenus = false;
  @Input() filename: string;
  @Input() createLink: string;
  @Input() queryParams?: Params;
  @Input() isResponsive = true;
  @Input() responsiveNow = false;
  @Input() noDataClass = 'mat-card mat-elevation-z0 no-data-card';
  @Input() tableWrapperClasses: string;
  @Input() noDataText = _('table.no_data');
  @Input() rowClasses: (index: number, row: any) => string;
  @Input() useSearch?: boolean;

  /** optionally set flag to clear Search service state or not upon this component's ngOnDestroy lifecycle */
  @Input() clearSearchOnDestroy = true;

  /** Optional - options for exporting into document */
  @Input() exportOptions: any;

  /** Optional - on click function for import button */
  @Input() productImport?: any;

  /** Set default page index */
  @Input() setPage?: number;

  /** template area for buttons on the right side of table's top toolbar */
  @Input() rightButtonsTemplate: TemplateRef<any>;

  /** template area for buttons on the left side of table's top toolbar */
  @Input() leftButtonsTemplate: TemplateRef<any>;

  /** Emit current page */
  @Output() pageEvent = new EventEmitter<number>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  filter: any;

  columnDefs: TableColumns = [];
  inputRowMenus: TableRowMenuItems = [];
  tableRowClasses: TableRowClasses = [];

  displayedColumns: string[] = [];
  pageSizeOptions = [10, 25, 50];
  dataCount = 0;
  showTable = false;
  usePaging = true;

  rows: any;
  footer: any = {};

  stopDataSourceSubscription$ = new Subject<void>();
  resumeDataSourceSubscription$ = new Subject<void>();

  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  /**
   * For performance reason: ExportService should be available only if lazy ExportsModule is loaded, triggered manually
   * by action (ex: export button click) - so we can't use usual Angular DI for this service thru constructor.
   */
  exportService: ExportService;

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private searchService: SearchService,
    private spinner: SpinnerVisibilityService,
    private alerts: AlertsService,
    private compiler: Compiler,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.initSearch();

    this.usePaging = this.dataSource.isPaginated;
    this.setWrapperClasses();

    this.columns.pipe(takeUntil(this.onDestroy$)).subscribe(columns => {
      this.columnDefs = columns;
      this.displayedColumns = columns.map(c => c.key);

      if (this.showMenus) {
        this.displayedColumns.push('menus');
      }
      this.cdr.markForCheck();
    });

    if (this.rowMenus) {
      this.rowMenus.pipe(takeUntil(this.onDestroy$)).subscribe(rowMenus => {
        this.inputRowMenus = rowMenus;
        this.cdr.markForCheck();
      });
    }

    this.dataSource
      .connect()
      .pipe(
        takeUntil(merge(this.onDestroy$, this.stopDataSourceSubscription$)),
        repeatWhen(() => this.resumeDataSourceSubscription$)
      )
      .subscribe(data => {
        this.rows = data;
        this.fillTableRowClasses(data);
      });

    this.dataSource
      .countObservable()
      .pipe(
        takeUntil(merge(this.onDestroy$, this.stopDataSourceSubscription$)),
        repeatWhen(() => this.resumeDataSourceSubscription$)
      )
      .subscribe(count => {
        this.dataCount = count;
        this.showTable = count >= 1;
        this.cdr.markForCheck();
      });

    this.dataSource
      .footerObservable()
      .pipe(
        takeUntil(merge(this.onDestroy$, this.stopDataSourceSubscription$)),
        repeatWhen(() => this.resumeDataSourceSubscription$)
      )
      .subscribe(data => {
        if (!this.isEmpty(data)) {
          // show table if footer has data
          this.showTable = true;
          this.footer = data;
          this.cdr.markForCheck();
        }
      });

    /**
     * if search service is not existed,
     * then load data
     */
    if (!this.searchService.search$) {
      this.loadData();
    }
  }

  private isEmpty(obj): boolean {
    if (obj.constructor === Object) {
      return Object.keys(obj).length === 0;
    } else if (Array.isArray(obj)) {
      return !obj.length;
    }
    return false;
  }

  ngAfterViewInit(): void {
    // react to paginator's page observable and reload data when user clicks
    // the navigation buttons
    if (this.dataSource.isPaginated) {
      this.paginator.page.pipe(tap(() => this.loadData())).subscribe();
    }
  }

  private loadData() {
    if (this.dataSource.isPaginated) {
      /**
       * if setPage is existed, change pagination index and delete setPage
       */
      if (typeof this.setPage !== 'undefined') {
        this.paginator.pageIndex = this.setPage;
        delete this.setPage;
      }
      this.dataSource.fetch({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize || this.pageSizeOptions[0],
        filter: this.filter,
      });
      this.emitCurrentPage();
    } else {
      this.dataSource.loadData({
        filter: this.filter,
      });
    }
  }

  private emitCurrentPage() {
    this.pageEvent.emit(this.paginator.pageIndex);
  }

  private initSearch() {
    if (typeof this.useSearch === 'undefined' || this.useSearch === true) {
      this.searchService.search$.pipe(takeUntil(this.onDestroy$), debounceTime(300)).subscribe(res => {
        this.filter = res;
        this.paginator.pageIndex = 0; // always reset to first page upon new search attempt
        this.loadData();
      });
    }
  }

  private loadExportService(callback: any) {
    if (this.exportService == null) {
      ExportServiceLoader.getInstance(this.compiler, this.injector)
        .getExportService()
        .then(exportService => {
          this.exportService = exportService;
          if (typeof callback === 'function') {
            callback();
          }
        });
    } else {
      if (typeof callback === 'function') {
        callback();
      }
    }
  }

  /**
   * Exports table's data. If datasource is paginated, then it will fetch all the needed data.
   * @param exportType string exported file type
   */
  exportTo(exportType: ExportType) {
    this.loadExportService(() => {
      try {
        let options = {};
        // set default PDF export configuration
        if (exportType === 'pdf' && !this.exportOptions) {
          options = {
            title: this.filename,
            orientation: environment.exports.pdf.defaultOrientation,
            ...this.exportOptions,
          } as PdfOptions;
        }

        const filename = getFormattedFileName(this.translate.instant(this.filename));

        if (this.dataSource.isPaginated) {
          this.stopDataSourceSubscription$.next();
          this.spinner.show();

          try {
            this.exportService.exportFromDataSource(
              exportType,
              filename,
              this.columnDefs,
              this.filter,
              this.dataSource,
              options,
              () => {
                this.resumeDataSourceSubscription$.next();
                this.spinner.hide();
              }
            );
          } catch (err) {
            console.log(err);
            this.resumeDataSourceSubscription$.next();
            this.spinner.hide();
          }
        } else {
          this.exportService.exportFromTableRows(
            exportType,
            filename,
            this.columnDefs,
            this.filter,
            this.rows,
            this.footer,
            options
          );
        }
      } catch (err) {
        console.log(err);
        this.alerts.error(err);
      }
    });
  }

  setWrapperClasses() {
    if (!this.tableWrapperClasses) {
      this.tableWrapperClasses = 'mat-table-wrapper mat-elevation-z8';
      if (this.isResponsive) {
        this.tableWrapperClasses += ' responsive-table';
      }
      if (this.responsiveNow) {
        this.tableWrapperClasses += ' responsive-now';
      }
    }
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    // make sure to reset the search so other components will have a clean slate search params
    if (this.clearSearchOnDestroy) {
      this.searchService.onClear();
    }
    this.stopDataSourceSubscription$.complete();
    this.resumeDataSourceSubscription$.complete();
  }

  /**
   * Clear and set CSS classes string for each row's and store it in \
   * tableRowClasses
   */
  private fillTableRowClasses(rows: any) {
    if (rows && Array.isArray(rows)) {
      this.tableRowClasses = [];
      rows.forEach((row, index) => {
        this.tableRowClasses.push({
          index,
          classes: typeof this.rowClasses === 'function' ? this.rowClasses(index, row) : '',
        });
      });
    }
  }

  /**
   * Returns CSS classes for table cells
   * @param column TableColumn of the displayed row
   * @param row displayed row's data
   */
  getCellClasses(column: TableColumn, row: any, rowIndex: number, colIndex: number): string {
    let textClasses = `${column.align ? column.align : ''}`;

    if (column.getCellClasses != null) {
      return `${column.getCellClasses(row, rowIndex, colIndex)} ${textClasses}`;
    }
    return textClasses;
  }

  /**
   * Returns CSS classes for table's header (th)
   * @param column TableColumn of the displayed row
   */
  getHeaderClasses(column: TableColumn): string {
    let textClasses = `${column.align ? column.align : ''}`;
    return column.headerClasses ? `${column.headerClasses} ${textClasses}` : textClasses;
  }

  /**
   * Returns CSS classes for table's footer row
   * @param column TableColumn of the displayed row
   */
  getFooterClasses(column: TableColumn): string {
    let textClasses = `${column.align ? column.align : ''}`;
    return column.headerClasses ? `${column.headerClasses} ${textClasses}` : textClasses;
  }

  /**
   * Context menu handler for table rows
   */
  onRowContextMenu(event: MouseEvent, menu: TableRowMenuItem, row: any) {
    const contextMenuEnabled =
      typeof menu.hasContextMenu === 'function' ? menu.hasContextMenu(row) : menu.hasContextMenu;
    if (contextMenuEnabled) {
      const menuData = { type: 'ROW', row, menu };
      this.openContextMenu(event, menuData);
    }
  }

  /**
   * Context menu handler for button
   */
  onButtonContextMenu(event: MouseEvent, link: string) {
    const menuData = { type: 'BUTTON', link };
    this.openContextMenu(event, menuData);
  }

  /**
   * Helper function to open context menu and set data for it
   */
  private openContextMenu(event: MouseEvent, menuData: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = menuData;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  /**
   * Click handler for "Open in new tab/window" context menu item on table row menus
   */
  openInNewTabOrWindowRowMenu(event: MouseEvent, menu: TableRowMenuItem, row: any, type: 'TAB' | 'WINDOW') {
    if (typeof menu.action === 'function') {
      menu.action(event, row, true, type === 'WINDOW' ? 'width=1280,height=700' : null);
    }
  }

  /**
   * Click handler for "Open in new tab/window" context menu item on button
   */
  openInNewTabOrWindowButton(event: MouseEvent, link: string, type: 'TAB' | 'WINDOW') {
    window.open(link, '_blank', type === 'WINDOW' ? 'width=1280,height=700' : null);
  }
}
