import * as i0 from "@angular/core";
import * as i1 from "../services/company/company.service";
export class EstablishmentIdInterceptor {
    constructor(companyService) {
        this.companyService = companyService;
    }
    intercept(request, next) {
        if (this.companyService.currentEstablishment) {
            request = request.clone({
                setHeaders: {
                    'x-establishment-id': this.companyService.currentEstablishment,
                },
            });
        }
        return next.handle(request);
    }
}
EstablishmentIdInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EstablishmentIdInterceptor_Factory() { return new EstablishmentIdInterceptor(i0.ɵɵinject(i1.CompanyService)); }, token: EstablishmentIdInterceptor, providedIn: "root" });
