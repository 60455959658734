<app-page [title]="'title.dashboard' | translate">
  <div class="graph-rows">
    <!-- mandatory widgets (always visible) -->
    <div class="row">
      <div [class]="nowStringCss">{{ nowString }}</div>
      <div class="col-dashboard-widget col-12 col-xl-3 col-lg-6 mb-4">
        <app-info-widget
          [title]="infoWidget.cashBalance.title"
          [displayStyle]="infoWidget.cashBalance.displayStyle"
          [state]="infoWidget.cashBalance.state"
          [showPercentage]="false"
          [text]="infoWidget.cashBalance.text"
        ></app-info-widget>
      </div>
      <div class="col-dashboard-widget col-12 col-xl-3 col-lg-6 mb-4">
        <app-info-widget
          [title]="infoWidget.totalRevenue.title"
          [displayStyle]="infoWidget.totalRevenue.displayStyle"
          [state]="infoWidget.totalRevenue.state"
          [showPercentage]="true"
          [percentage]="infoWidget.totalRevenue.percentage"
          [text]="infoWidget.totalRevenue.text"
        ></app-info-widget>
      </div>
      <div class="col-dashboard-widget col-12 col-xl-3 col-lg-6 mb-4">
        <app-info-widget
          [title]="infoWidget.numberOfReceipts.title"
          [displayStyle]="infoWidget.numberOfReceipts.displayStyle"
          [state]="infoWidget.numberOfReceipts.state"
          [showPercentage]="true"
          [percentage]="infoWidget.numberOfReceipts.percentage"
          [text]="infoWidget.numberOfReceipts.text"
        ></app-info-widget>
      </div>
      <div class="col-dashboard-widget col-12 col-xl-3 col-lg-6 mb-4">
        <app-info-widget
          [title]="infoWidget.avgPerReceipt.title"
          [displayStyle]="infoWidget.avgPerReceipt.displayStyle"
          [state]="infoWidget.avgPerReceipt.state"
          [showPercentage]="true"
          [percentage]="infoWidget.avgPerReceipt.percentage"
          [text]="infoWidget.avgPerReceipt.text"
        ></app-info-widget>
      </div>
    </div>
    <!-- payment with foreign currency -->
    <div class="row" *ngIf="infoWidget.cashBalanceForeign.state === 'LOADED'">
      <div class="col-dashboard-widget col-12 mb-4">
        <app-info-widget
          [title]="infoWidget.cashBalanceForeign.title"
          [displayStyle]="'INLINE'"
          [displayStyle]="infoWidget.cashBalanceForeign.displayStyle"
          [state]="infoWidget.cashBalanceForeign.state"
          [showPercentage]="false"
          [text]="infoWidget.cashBalanceForeign.text"
        ></app-info-widget>
      </div>
    </div>
    <div class="row">
      <div class="col-dashboard-widget col-12 col-lg-6 mb-4">
        <app-cash-register-status [eventLog]="eventLog$"></app-cash-register-status>
      </div>
      <div class="col-dashboard-widget col-12 col-lg-6 mb-4">
        <app-fiscalization-status></app-fiscalization-status>
      </div>
    </div>

    <!-- customizable widgets -->
    <app-graph-outlet [refresh]="refreshGraphs"></app-graph-outlet>
    <app-graph-organiser (updated)="onGraphsUpdated()"></app-graph-organiser>
  </div>
</app-page>
