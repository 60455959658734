/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cash-register-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i5 from "@angular/material/card";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./cash-register-status.component";
import * as i8 from "../../../shared/services/language/language.service";
var styles_CashRegisterStatusComponent = [i0.styles];
var RenderType_CashRegisterStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CashRegisterStatusComponent, data: {} });
export { RenderType_CashRegisterStatusComponent as RenderType_CashRegisterStatusComponent };
function View_CashRegisterStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "status-row d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "status-row-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "status-row-text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpod(6, { user: 0, timestamp: 1 }), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state === "open") ? "status-indicator active" : "status-indicator inactive"); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 7).transform(_co.text, _ck(_v, 6, 0, _co.user, _co.formatTimestamp()))); _ck(_v, 5, 0, currVal_1); }); }
function View_CashRegisterStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "status-row d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "status-row-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "status-indicator inactive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "status-row-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("dashboard.cash_register.no_activity")); _ck(_v, 4, 0, currVal_0); }); }
export function View_CashRegisterStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "mat-card", [["class", "dashboard-widget cash-register-status-widget full-height mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i4.View_MatCard_0, i4.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i5.MatCard, [[2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i5.MatCardContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CashRegisterStatusComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["nostatus", 2]], null, 0, null, View_CashRegisterStatusComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.log !== null); var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_CashRegisterStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cash-register-status", [], null, null, null, View_CashRegisterStatusComponent_0, RenderType_CashRegisterStatusComponent)), i1.ɵdid(1, 245760, null, 0, i7.CashRegisterStatusComponent, [i8.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CashRegisterStatusComponentNgFactory = i1.ɵccf("app-cash-register-status", i7.CashRegisterStatusComponent, View_CashRegisterStatusComponent_Host_0, { eventLog: "eventLog" }, {}, []);
export { CashRegisterStatusComponentNgFactory as CashRegisterStatusComponentNgFactory };
