<ng-container *ngIf="image">
  <div class="image-box mb-3 mt-3">
    <img loading="lazy" [src]="resourceUrl" [alt]="image.name" class="fill" />
    <div class="text-right">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <!-- <button mat-menu-item (click)="emitReplaceImage()">
      <mat-icon>swap_horiz</mat-icon>Replace
    </button> -->
      <button mat-menu-item (click)="enlarge()">
        <mat-icon>zoom_out_map</mat-icon>{{ 'gallery.enlarge' | translate }}
      </button>
      <button mat-menu-item (click)="emitDeleteImage()" *ngIf="!readonly">
        <mat-icon>delete</mat-icon>{{ 'common.delete' | translate }}
      </button>
    </mat-menu>
  </div>
  <mat-form-field class="full-width">
    <mat-label>{{ 'gallery.image_title' | translate }}</mat-label>
    <input matInput [(ngModel)]="imageName" [readonly]="readonly" />
  </mat-form-field>
  <div class="d-flex flex-row-reverse">
    <button
      type="button"
      color="primary"
      mat-flat-button
      aria-label="save the change"
      *ngIf="!readonly"
      (click)="save()"
    >
      <mat-icon>check</mat-icon>
      {{ 'common.save' | translate }}
    </button>
    <button type="button" mat-button class="mx-2" aria-label="close this panel" (click)="close()">
      <mat-icon>cancel</mat-icon>
      {{ 'common.close' | translate }}
    </button>
  </div>
</ng-container>
