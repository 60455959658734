import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AlertsService {
  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {}

  error(messageToken: string) {
    this.alert(messageToken, 'error-alert');
  }

  success(messageToken: string) {
    this.alert(messageToken, 'success-alert');
  }

  private async alert(messageToken: string, colorClass: string) {
    const close = await this.translateService.get(_('common.close')).toPromise();
    const message = await this.translateService.get(messageToken).toPromise();

    this.snackBar.open(message, close, {
      panelClass: [colorClass],
      duration: environment.snackbarDuration,
    });
  }
}
