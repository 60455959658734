import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TableLoadOption } from '@app/shared/bases/table.datasource';
import { hexFromBase64 } from '@app/shared/helpers/helpers';
import { FormDropdown } from '@app/shared/interfaces/common.interface';
import { ProductGroupColor, ProductGroupColorDropdown } from '@app/shared/interfaces/product-group-color.interface';
import { BoResponse } from '@app/shared/interfaces/response.interface';
import { CatalogItem } from '@app/shared/models/catalog-item.model';
import { AlertsService } from '@app/shared/services/alerts/alerts.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;

export enum CATALOG_TYPE {
  GROUP = '1',
  PRODUCT = '2',
}

@Injectable({
  providedIn: 'root',
})
export class CatalogItemsService {
  defaultGroup = environment.defaultGroupValue;

  constructor(private http: HttpClient, private alertService: AlertsService) {}

  /**
   * Get product groups from catalogItems collection.
   * @param option TableLoadOption option to fetch the data
   * @param includeRoot should `isRoot` criteria on catalogItems is needed.
   *     includeRoot equals null means that "Default Group" should not be included
   *     on returned data (ex: on Warehouse - Product Groups page).
   *     includeRoot equals true (or false) means `isRoot` query criteria on
   *     catalogItems will be used.
   */
  getGroups(option: TableLoadOption, includeRoot?: boolean): Observable<any> {
    return this.getFromCatalogItems(`${API_URL}/${environment.productGroupsPath}`, option, includeRoot);
  }

  getGroupById(id: string) {
    return this.http.get<BoResponse>(`${API_URL}/${environment.productGroupsPath}/${hexFromBase64(id)}`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  getReturnablePackagingGroup() {
    return this.http.get<BoResponse>(`${API_URL}/${environment.productGroupsPath}/returnable`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  deleteGroup(group): Observable<any> {
    return this.http
      .post<BoResponse>(`${API_URL}/${environment.productGroupsPath}/${hexFromBase64(group._id)}`, {
        group_id: hexFromBase64(group._id),
      })
      .pipe(
        map(res => {
          if (res.success) {
            return res.data;
          }
          return throwError(res.message);
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      );
  }

  getProducts(option: TableLoadOption, ingredientOnly?: boolean): Observable<any> {
    return this.getFromCatalogItems(`${API_URL}/${environment.productItemsPath}`, option, null, ingredientOnly);
  }

  getStockTrackedProducts(option: TableLoadOption, ingredientOnly?: boolean): Observable<any> {
    return this.getFromCatalogItems(
      `${API_URL}/${environment.productItemsStockTrackedPath}`,
      option,
      null,
      ingredientOnly
    );
  }

  /**
   * Get all products (unpaginated), using the given filter.
   * @param filter filtering pattern to search for products and product groups.
   * - for products: "product_name:<value>;group_id:(?<value>.*);measuring_id:(?<value>.*)"
   * - for product groups: "name:(?<value>.*)"
   */
  getAllProducts(filter?: string): Observable<CatalogItem[]> {
    if (filter && filter.length >= 3) {
      const params = new HttpParams().set('filter', filter);
      return this.http
        .get<BoResponse>(`${API_URL}/${environment.productItemsPath}/all`, {
          params,
        })
        .pipe(
          map(res => {
            if (res.success) {
              return res.data;
            }
            return throwError(res.message);
          }),
          catchError(() => {
            return of([]);
          })
        );
    }
    return of([]);
  }

  getProductById(id: string): Observable<any> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.productItemsPath}/${hexFromBase64(id)}`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  getProductVariations(parentId: string): Observable<CatalogItem[]> {
    return this.http
      .get<BoResponse>(`${API_URL}/${environment.productItemsPath}/variations/${hexFromBase64(parentId)}`)
      .pipe(
        map(res => {
          if (res.success) {
            return res.data;
          }
          return throwError(res.message);
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  isAnyProductWithMeasuringUnit(measuringUnitId: string): Observable<boolean> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.productItemsPath}/unit/${measuringUnitId}`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(() => {
        return of(0);
      })
    );
  }

  deleteProduct(product): Observable<any> {
    return this.http
      .post<BoResponse>(`${API_URL}/${environment.productItemsPath}/${hexFromBase64(product._id)}`, {
        group_id: hexFromBase64(product.parent._id),
      })
      .pipe(
        map(res => {
          if (res.success) {
            return res.data;
          }
          return throwError(res.message);
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      );
  }

  private getFromCatalogItems(path: string, option: TableLoadOption, includeRoot?: boolean, ingredientOnly?: boolean) {
    let params = new HttpParams().set('page', option.pageIndex.toString()).set('size', option.pageSize.toString());

    if (includeRoot != null) {
      params = params.set('includeRoot', includeRoot.toString());
    }

    if (ingredientOnly != null) {
      params = params.set('ingredientOnly', `${ingredientOnly}`);
    }

    if (option.filter) {
      if (option.filter.hasOwnProperty('pattern')) {
        params = params.set('filter', option.filter.pattern);
      }
    }

    return this.http.get<BoResponse>(path, { params }).pipe(
      map(res => {
        if (res.success) {
          return res;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        return of([]);
      })
    );
  }

  saveOrUpdateProduct(data: { [key: string]: any }, edit: boolean): Observable<any> {
    let api: Observable<BoResponse>;

    if (edit) {
      api = this.http.put<BoResponse>(`${API_URL}/${environment.productItemsPath}`, data);
    } else {
      api = this.http.post<BoResponse>(`${API_URL}/${environment.productItemsPath}`, data);
    }
    return api.pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        this.alertService.error(error);
        return throwError(error);
      })
    );
  }

  saveOrUpdateProductGroup(data: { [key: string]: any }, edit: boolean): Observable<any> {
    let api: Observable<BoResponse>;
    const url = `${API_URL}/${environment.productGroupsPath}`;

    if (edit) {
      api = this.http.put<BoResponse>(url, data);
    } else {
      api = this.http.post<BoResponse>(url, data);
    }
    return api.pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(error => {
        this.alertService.error(error);
        return throwError(error);
      })
    );
  }

  saveDefaultReturnablePackagingGroup(data: { enable: boolean; hexColorCode: string }) {
    const params = new HttpParams()
      .set('hexColorCode', data.hexColorCode)
      .set('enable', data.enable ? 'true' : 'false');

    return this.http
      .post<BoResponse>(`${API_URL}/${environment.returnablePackagingBasePath}/default-group`, null, { params })
      .pipe(
        map(res => {
          if (res.success) {
            return res.data;
          }
          return throwError(res.message);
        }),
        catchError(error => {
          this.alertService.error(error);
          return throwError(error);
        })
      );
  }

  saveOrUpdateReturnablePackaging(data: { [key: string]: any }, edit: boolean = false): Observable<any> {
    let api: Observable<BoResponse>;
    const url = `${API_URL}/${environment.returnablePackagingBasePath}`;

    if (edit) {
      api = this.http.put<BoResponse>(`${url}/${hexFromBase64(data._id)}`, data);
    } else {
      api = this.http.post<BoResponse>(url, data);
    }

    return api.pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        console.error(res.message);
        return throwError(res.message);
      }),
      catchError(error => {
        this.alertService.error(error);
        return throwError(error);
      })
    );
  }

  deleteReturnablePackaging(data) {
    return this.http
      .delete<BoResponse>(`${API_URL}/${environment.returnablePackagingBasePath}/${hexFromBase64(data._id)}`)
      .pipe(
        map(res => {
          if (res.success) {
            return res.data;
          }
          return throwError(res.message);
        }),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      );
  }

  getReturnablePackaging(option: TableLoadOption): Observable<any> {
    let params = new HttpParams().set('page', option.pageIndex.toString()).set('size', option.pageSize.toString());

    if (option.filter && option.filter.hasOwnProperty('pattern')) {
      params = params.set('filter', option.filter.pattern);
    }

    return this.http
      .get<BoResponse>(`${API_URL}/${environment.returnablePackagingBasePath}`, {
        params,
      })
      .pipe(
        map(res => {
          if (res.success) {
            return res;
          }
          return throwError(res.message);
        }),
        catchError(() => {
          return of([]);
        })
      );
  }

  getWeightedProductTypes(): Observable<any> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.productItemsPath}/weighted-types`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  getDefaultProductGroupColors(): Observable<ProductGroupColor> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.productGroupsPath}/default-group-colors`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(() => {
        return of({});
      })
    );
  }

  getDefaultProductGroupColorsDropdown(): Observable<ProductGroupColorDropdown> {
    return new Observable<ProductGroupColorDropdown>(subscriber => {
      const dropdown: FormDropdown[] = [];
      let defaultColor = '';

      this.getDefaultProductGroupColors()
        .toPromise()
        .then(res => {
          for (const [key, value] of Object.entries(res)) {
            dropdown.push({
              value: value.colorHexCode,
              description: `product_group.color.${value.colorName}`,
              icon: 'folder',
              iconStyle: { color: value.colorHexCode },
            });

            if (value.default) {
              defaultColor = value.colorHexCode;
            }
          }

          subscriber.next({
            dropdown,
            default: defaultColor,
          });
          subscriber.complete();
        });
    });
  }
}
