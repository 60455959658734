<mat-card>
  <mat-card-subtitle>{{ 'gallery.filter_by_category' | translate }}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="icon-category-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          <button
            [color]="!isSearched && currentNode && currentNode.key === node.key ? 'primary' : ''"
            mat-button
            (click)="onClick(node)"
          >
            {{ node.name }}
          </button>
        </li>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
            <button
              [color]="!isSearched && currentNode && currentNode.key === node.key ? 'primary' : ''"
              mat-button
              (click)="onClick(node)"
            >
              {{ node.name }}
            </button>
          </div>
          <ul [class.icon-category-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </mat-card-content>
</mat-card>
