import { environment as sharedEnvironment } from './shared.environment';

export const environment = {
  ...sharedEnvironment,
  project: 'backoffice',
  production: false,
  cookieDomain: 'ecr-dev.tvc-info.hr',
  wirecardEnabled: true,
  apiUrl: '/backoffice',
  localStoragePrefix: 'bo_test',
};
