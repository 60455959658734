import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NavigationEnd } from '@angular/router';
import { BaseComponent } from '@app/shared/bases/base.component';
import { NavigationService } from '@app/shared/services/navigation/navigation.service';
import { takeUntil } from 'rxjs/operators';
import { menu, Menu, MenuItem } from '../../data/menu';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent extends BaseComponent implements OnInit {
  @Input() menu: Menu;
  @Output() menuClicked = new EventEmitter<string>();

  treeControl = new NestedTreeControl<MenuItem>(node => node.items);
  dataSource = new MatTreeNestedDataSource<MenuItem>();

  hasChild = (_: number, node: MenuItem) => !!node.items && node.items.length > 0;

  currentNavigation: NavigationEnd;

  constructor(private navigationService: NavigationService) {
    super();
  }

  ngOnInit() {
    this.dataSource.data = this.menu;

    this.navigationService.navigation$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(navigation => this.onNavigationEnd(navigation));
  }

  onMenuClicked(link: string) {
    this.menuClicked.emit(link);
  }

  private onNavigationEnd(navigation: NavigationEnd) {
    this.currentNavigation = navigation;
    menu.forEach(m => this.traverseMenuForHighlight(m, this.currentNavigation.url));
  }

  private traverseMenuForHighlight(menu: MenuItem, url: string) {
    if (typeof menu.link === 'undefined' && menu.prefix) {
      // for nested tree node
      if (menu.prefix && this.currentNavigation.url.startsWith(menu.prefix)) {
        menu.cssClasses = 'active';
        this.treeControl.expand(menu);
      } else {
        menu.cssClasses = '';
      }

      if (menu.items && menu.items.length) {
        menu.items.forEach(item => this.traverseMenuForHighlight(item, url));
      }
    } else if (menu.link) {
      menu.cssClasses = this.currentNavigation.url.startsWith(menu.link) ? 'active' : '';
    }
  }
}
