import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Icon } from '@app/shared/models/catalog-item.model';

@Component({
  selector: 'app-image-enlarge-dialog',
  templateUrl: './image-enlarge-dialog.component.html',
})
export class ImageEnlargeDialogComponent {
  image: Icon;
  imageResource: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { image: Icon },
    private sanitizer: DomSanitizer
  ) {
    this.image = data.image;
    const unsafeResource = `data:${data.image.contentType};base64,`.concat(data.image.file.data);
    this.imageResource = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeResource);
  }
}
