import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { LanguageService } from '@app/shared/services/language/language.service';
import { TitleService } from '@app/shared/services/title/title.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public routerEventSubscription: Subscription;

  @Output() onMenuIconClick = new EventEmitter();
  @Input() menuOpened = true;
  @HostBinding('class') headerStyleClass = environment.appearances.header;

  currentUrl = '.';

  title$: BehaviorSubject<string>;
  username$: BehaviorSubject<string>;
  headerStyle = environment.appearances.header;
  showAppTitle = environment.appearances.showAppTitleOnHeader;
  pageTitleMode = environment.appearances.pageTitle;

  get classNames(): string {
    return `navbar ${this.headerStyle}`;
  }

  constructor(
    public authService: AuthService,
    public languageService: LanguageService,
    private router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.title$ = this.titleService.title$;
    this.routerEventSubscription = this.subscribeToRouterUrlChange();
  }

  subscribeToRouterUrlChange(): Subscription {
    return this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map(e => e.urlAfterRedirects)
      )
      .subscribe(urlPath => {
        this.currentUrl = urlPath;
      });
  }

  toggleMenu() {
    this.onMenuIconClick.emit();
  }

  logout() {
    this.authService.logout();
  }
}
