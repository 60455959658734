import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { menuSettings } from 'src/app/core/data/menu';
import { environment } from 'src/environments/environment';

/**
 * Enable or disable modules/features activation according to the config (environment)
 */
@Injectable()
export class ModulesGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // enable/disable currency list, create, and edit pages
    const currencyMenu = menuSettings.items.find(menuItem => menuItem.id === 'settings_currencies');
    if (currencyMenu && !environment.currencySettingEnabled && state.url.startsWith(currencyMenu.link)) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
