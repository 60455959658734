import { environment } from 'src/environments/environment';
import { getCurrentLanguageCode } from './language';

export function getLocaleForLanguageCode(code: string) {
  if (code === 'en') {
    return 'en-US';
  }
  else if (environment.languageCodeToLocale[code]) {
    return environment.languageCodeToLocale[code];
  }
  return environment.defaultLocale;
}


export function getCurrentLocale(): string {
  const code = getCurrentLanguageCode();

  return getLocaleForLanguageCode(code);
}