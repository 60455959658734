<div class="row">
  <ng-container *ngFor="let graph of graphs">
    <div [ngClass]="columnClasses(graph.column)" *ngIf="graph.visibility && graph.graphView != null">
      <mat-card class="graph-card full-height">
        <mat-card-content>
          <app-graph [organisedGraphData]="graph"></app-graph>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
</div>
