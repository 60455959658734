<h2 class="my-0" style="top: -12px; position: relative">
  <span *ngIf="selection === 'single'">{{ 'gallery.select_image' | translate }}</span>
  <span *ngIf="selection === 'multiple'">
    {{ 'images.picker.select-images' | translate }}
  </span>
</h2>
<div mat-dialog-content>
  <app-image-gallery
    [mode]="mode"
    [selection]="selection"
    [initialSelected]="selectedImages"
    (selected)="onSelected($event)"
  ></app-image-gallery>
</div>
<div mat-dialog-actions class="d-flex flex-row-reverse">
  <button mat-raised-button color="primary" (click)="onConfirm()" class="mx-2">
    {{ 'common.done' | translate }}
  </button>
  <button mat-button (click)="onDismiss()">
    {{ 'common.cancel' | translate }}
  </button>
</div>
