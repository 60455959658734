import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '@app/shared/helpers/local-storage';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_PATH = environment.apiUrl;
export class DevicesService {
    constructor(http) {
        this.http = http;
        // tslint:disable-next-line:variable-name
        this._currentDevice = null;
    }
    get currentDevice() {
        if (this._currentDevice !== null) {
            return this._currentDevice;
        }
        const currentDevice = JSON.parse(getFromLocalStorage('currentDevice'));
        if (currentDevice) {
            this._currentDevice = currentDevice;
        }
        return this._currentDevice;
    }
    set currentDevice(value) {
        saveToLocalStorage('currentDevice', JSON.stringify(value));
        this._currentDevice = value;
    }
    get identityId() {
        return this.currentDevice ? this.currentDevice.identityId : null;
    }
    getDevices() {
        const PATH = environment.project === 'galeb'
            ? `${API_PATH}/${environment.devicesPath}/from-fa`
            : `${API_PATH}/${environment.devicesPath}`;
        return this.http.get(PATH).pipe(map(res => {
            if (res.success) {
                this.devices = res.data.map(d => ({
                    identityId: d._id,
                    companyId: d.companyId,
                    establishmentId: d.establishmentId,
                    name: d.name === null ? d._id : d.name,
                }));
                return this.devices;
            }
            console.error(res.message);
            return throwError(res.message);
        }), catchError(error => {
            return of([]);
        }));
    }
    /**
     * Clear the currently selected device and all devices available for selection
     */
    clear() {
        this.clearCurrentDevice();
        this.devices = [];
    }
    /**
     * Clear the currently selected device only
     */
    clearCurrentDevice() {
        removeFromLocalStorage('currentDevice');
        this.currentDevice = null;
    }
    hasDevices() {
        return this.devices && this.devices.length > 0;
    }
    setCurrentDeviceByIdentity(identityId) {
        const target = this.devices.find(dev => dev.identityId === identityId);
        if (target) {
            this.currentDevice = target;
        }
    }
}
DevicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DevicesService_Factory() { return new DevicesService(i0.ɵɵinject(i1.HttpClient)); }, token: DevicesService, providedIn: "root" });
