import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { IconCategory, IconCategoryNode } from '@app/shared/models/catalog-item.model';
import { GalleryService } from '@app/shared/services/gallery/gallery.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-icon-category-tree',
  templateUrl: './icon-category-tree.component.html',
  styleUrls: ['./icon-category-tree.component.scss'],
})
export class IconCategoryTreeComponent implements OnInit {
  @Input() isSearched: boolean;

  @Output() categoryFiltered = new EventEmitter<IconCategoryNode>();

  treeControl = new NestedTreeControl<IconCategoryNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<IconCategoryNode>();

  lang: string;
  @Input() currentNode: IconCategoryNode;

  constructor(private galleryService: GalleryService, private translateService: TranslateService) {}

  hasChild = (_: number, node: IconCategoryNode) => !!node.children && node.children.length > 0;

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    this.initData();
  }

  private initData() {
    this.galleryService
      .getAllIconCategories()
      .toPromise()
      .then(res => {
        this.dataSource.data = this.convertToNode(res);
      });
  }

  private convertToNode(data: IconCategory[]): IconCategoryNode[] {
    let response: IconCategoryNode[] = [];

    data.map(val => {
      response.push({
        id: val.id,
        key: val.key,
        name: val.name[this.lang],
        children: this.convertToNode(val.children),
      });
    });

    return response;
  }

  onClick(node: IconCategoryNode) {
    this.currentNode = node;
    this.categoryFiltered.emit(node);
  }
}
