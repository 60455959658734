import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { getFromLocalStorage } from './local-storage';

export async function languageLoader(translate: TranslateService) {
  const code = getCurrentLanguageCode();

  translate.setDefaultLang(code);
  await translate.use(code);
}

export function getCurrentLanguageCode(): string {
  const code = getFromLocalStorage('lang') || environment.defaultLanguage;

  return code;
}
