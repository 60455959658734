import { Injectable } from '@angular/core';
import { TableDataSource } from '@app/shared/bases/table.datasource';
import { SearchAttemptFields } from '@app/shared/components/search-table/search-table.component';
import { TableColumns } from '@app/shared/components/table/table.component';
import { ExcelService } from '../excel/excel.service';
import { Exporter, ExportType } from '../exporter.service';
import { PdfService } from '../pdf/pdf.service';

/** General exporter wrapper service. `providedIn` is not used here since this service should only be available
 * thru `ExportsModule` only
 */
@Injectable()
export class ExportService {
  constructor(private excelService: ExcelService, private pdfService: PdfService) {}

  public getExcelService(): ExcelService {
    return this.excelService;
  }

  public getPdfService(): PdfService {
    return this.pdfService;
  }

  /**
   * Returns Exporter class responsible for the given ExportType, or undefined if not supported
   * @param exportType string of supported export file types
   */
  getExporter(exportType: ExportType): Exporter {
    if (exportType === 'xlsx' || exportType === 'csv') {
      return this.excelService;
    } else if (exportType === 'pdf') {
      return this.pdfService;
    }

    throw new Error(`The given export type of '${exportType}' is not supported`);
  }

  /**
   * Export from table rows, for example if table's datasource is not paginated (table is showing all the data).
   * @param exportType exported file type
   * @param fileName exported file name
   * @param columnDefs TableColumns for the exported data
   * @param filter object containing search filters and their configuration
   * @param rows exported data (or displayed rows on table)
   * @param footer optional - exported footer data (footer row on table)
   * @param options optional parameter (ex: PdfOptions for pdf exporter service)
   * @param callback optional callback when export process is done
   */
  exportFromTableRows(
    exportType: ExportType,
    fileName: string,
    columnDefs: TableColumns,
    filter: SearchAttemptFields,
    rows: any[],
    footer?: { [key: string]: string },
    options?: any,
    callback?: any
  ) {
    this.getExporter(exportType).exportFromTableRows(
      exportType.toString(),
      fileName,
      columnDefs,
      filter,
      rows,
      footer,
      options,
      callback
    );
  }

  /**
   * Export from TableDataSource, especially when datasource is paginated.
   * @param exportType exported file type
   * @param fileName exported file name
   * @param columnDefs TableColumns of rows
   * @param filter object containing search filters and their configuration
   * @param dataSource table's TableDataSource
   * @param options optional parameter (ex: PdfOptions for pdf exporter service)
   * @param callback optional callback when export process is done
   */
  exportFromDataSource(
    exportType: ExportType,
    fileName: string,
    columnDefs: TableColumns,
    filter: SearchAttemptFields,
    dataSource: TableDataSource<any>,
    options?: any,
    callback?: any
  ) {
    this.getExporter(exportType).exportFromDataSource(
      exportType.toString(),
      fileName,
      columnDefs,
      filter,
      dataSource,
      options,
      callback
    );
  }
}
