import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { TitleService } from '@app/shared/services/title/title.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menu } from './data/menu';
import { MenuService } from './services/menu/menu.service';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;

  title = _('title.dashboard');
  title$ = this.titleService.title$;
  menuItems: Menu;
  menuOpened$ = new Subject<boolean>();
  isHandset = false;
  pageTitleMode = environment.appearances.pageTitle;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private menuService: MenuService,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((state: BreakpointState) => (this.isHandset = state.matches));

    this.menuService.menus$.subscribe(menu => (this.menuItems = menu));
    this.menuService.refresh();
  }

  toggleMenu() {
    this.drawer.toggle();
  }

  menuOpenedChanged(opened: boolean) {
    this.menuOpened$.next(opened);
  }

  onSidebarMenuClicked(link: string) {
    // close menu on mobile
    if (this.isHandset) {
      this.drawer.close();
    }
  }
}
