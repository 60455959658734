import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GraphAdditionalFilter,
  GraphDisplayOption,
  GraphTimePeriod,
  GraphTimePeriodRange,
  GraphType,
  MultiGraphData,
  OrganisedGraphData,
  SingleGraphData,
} from '../interfaces/graph.interface';
import { GraphDataService } from '../services/graph-data/graph-data.service';
import { GraphOrganiserService } from '../services/graph-organiser/graph-organiser.service';
import { BaseGraphView } from './base.graph-view';

export class MostSellingProductsGraphView extends BaseGraphView {
  private defaultLimit = 5;

  constructor() {
    super();
  }

  get title(): string {
    return _('graphs.most_selling_products');
  }

  get allowVisibilityChanged(): boolean {
    return true;
  }

  get graphType(): GraphType {
    return 'VERTICAL_BAR';
  }

  get displayOption(): GraphDisplayOption {
    return {
      yAxisLabel: _('common.quantity_sold'),
      xAxisLabel: _('common.product'),
      // one color for all bars
      colorScheme: ['#004d89'],
      // quantity is integer, so no decimal point
      yAxisTickFormatting: (value: number) => (value % 1 === 0 ? value.toLocaleString() : ''),
    };
  }

  getTimePeriod(translateService: TranslateService): GraphTimePeriod {
    const startDay = moment().startOf('day');
    const endDay = moment().endOf('day');
    return {
      type: 'SELECT',
      ranges: [
        {
          name: translateService.instant(_('graphs.period.today')),
          from: startDay.toDate(),
          to: endDay.toDate(),
          singleDay: true,
        },
        {
          name: translateService.instant(_('graphs.period.week')),
          from: moment(startDay).subtract(1, 'weeks').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '2' }),
          from: moment(startDay).subtract(2, 'weeks').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '4' }),
          from: moment(startDay).subtract(4, 'weeks').toDate(),
          to: endDay.toDate(),
        },
      ],
    };
  }

  getAdditionalFilters(
    organisedGraphData: OrganisedGraphData,
    graphDataService: GraphDataService,
    graphOrganiserService: GraphOrganiserService,
    translateService: TranslateService
  ): GraphAdditionalFilter[] {
    const filterData = graphOrganiserService.getGraphsFilterById(organisedGraphData.id);
    const filters = filterData ? filterData.filters : {};

    return [
      {
        id: 'limit',
        type: 'SELECT',
        options: of([
          {
            value: this.defaultLimit,
            description: translateService.instant(_('graphs.product_number'), { value: this.defaultLimit }),
          },
          { value: 10, description: translateService.instant(_('graphs.product_number'), { value: 10 }) },
          { value: 15, description: translateService.instant(_('graphs.product_number'), { value: 15 }) },
          { value: 20, description: translateService.instant(_('graphs.product_number'), { value: 20 }) },
        ]),
        defaultValue: filters.limit ? filters.limit : this.defaultLimit,
      },
    ];
  }

  fetchData(
    graphDataService: GraphDataService,
    translateService: TranslateService,
    range: GraphTimePeriodRange,
    additionalFilters?: { [key: string]: any }
  ): Promise<SingleGraphData[] | MultiGraphData[]> {
    const limit = additionalFilters
      ? additionalFilters.limit
        ? additionalFilters.limit
        : this.defaultLimit
      : this.defaultLimit;

    return new Promise<SingleGraphData[] | MultiGraphData[]>((resolve, reject) => {
      graphDataService
        .getMostSellingProducts(range.from, range.to, limit)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            const series: SingleGraphData[] = result.map(res => {
              return {
                name: res.name,
                value: res.quantity,
              };
            });
            resolve(series);
          } else {
            reject([]);
          }
        });
    });
  }
}
