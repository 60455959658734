/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./graph-outlet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../graph/graph.component.ngfactory";
import * as i7 from "../graph/graph.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../services/graph-data/graph-data.service";
import * as i10 from "../../services/graph-organiser/graph-organiser.service";
import * as i11 from "./graph-outlet.component";
var styles_GraphOutletComponent = [i0.styles];
var RenderType_GraphOutletComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GraphOutletComponent, data: {} });
export { RenderType_GraphOutletComponent as RenderType_GraphOutletComponent };
function View_GraphOutletComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "mat-card", [["class", "graph-card full-height mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(4, 49152, null, 0, i4.MatCard, [[2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i4.MatCardContent, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-graph", [], null, null, null, i6.View_GraphComponent_0, i6.RenderType_GraphComponent)), i1.ɵdid(8, 245760, null, 0, i7.GraphComponent, [i8.TranslateService, i9.GraphDataService, i10.GraphOrganiserService, i1.ChangeDetectorRef], { organisedGraphData: [0, "organisedGraphData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnClasses(_v.parent.context.$implicit.column); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_1); }); }
function View_GraphOutletComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphOutletComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.visibility && (_v.context.$implicit.graphView != null)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GraphOutletComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphOutletComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graphs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GraphOutletComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-graph-outlet", [], null, null, null, View_GraphOutletComponent_0, RenderType_GraphOutletComponent)), i1.ɵdid(1, 245760, null, 0, i11.GraphOutletComponent, [i10.GraphOrganiserService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GraphOutletComponentNgFactory = i1.ɵccf("app-graph-outlet", i11.GraphOutletComponent, View_GraphOutletComponent_Host_0, { refresh: "refresh" }, {}, []);
export { GraphOutletComponentNgFactory as GraphOutletComponentNgFactory };
