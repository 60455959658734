import { formatDate } from '@app/shared/helpers/date';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  GraphDisplayOption,
  GraphTimePeriod,
  GraphTimePeriodRange,
  GraphType,
  MultiGraphData,
  SingleGraphData,
} from '../interfaces/graph.interface';
import { GraphDataService } from '../services/graph-data/graph-data.service';
import { BaseGraphView } from './base.graph-view';

export class RevenueGraphView extends BaseGraphView {
  constructor() {
    super();
  }

  get title(): string {
    return _('graphs.revenue');
  }

  get allowVisibilityChanged(): boolean {
    return true;
  }

  get graphType(): GraphType {
    return 'LINE_CHART';
  }

  get displayOption(): GraphDisplayOption {
    return {
      yAxisLabel: _('graphs.revenue'),
      xAxisLabel: _('common.date'),
      colorScheme: ['#004d89'], // one color for all bars
    };
  }

  getTimePeriod(translateService: TranslateService): GraphTimePeriod {
    const startDay = moment().startOf('day');
    const endDay = moment().endOf('day');
    return {
      type: 'SELECT',
      ranges: [
        {
          name: translateService.instant(_('graphs.period.days'), { value: '8' }),
          from: moment(startDay).subtract(7, 'days').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '2' }),
          from: moment(startDay).subtract(2, 'weeks').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '4' }),
          from: moment(startDay).subtract(4, 'weeks').toDate(),
          to: endDay.toDate(),
        },
      ],
    };
  }

  fetchData(
    graphDataService: GraphDataService,
    translateService: TranslateService,
    range: GraphTimePeriodRange
  ): Promise<SingleGraphData[] | MultiGraphData[]> {
    return new Promise<SingleGraphData[] | MultiGraphData[]>((resolve, reject) => {
      graphDataService
        .getRevenue(range.from, range.to)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            const series: SingleGraphData[] = result.map(res => {
              return {
                name: formatDate({ date: res.date, format: 'mediumDate' }),
                value: res.revenue,
                extra: {
                  suffix: environment.defaultCurrencySymbol,
                },
              };
            });

            const graphData: MultiGraphData[] = [
              {
                name: translateService.instant(_('graphs.revenue')),
                series,
              },
            ];
            resolve(graphData);
          } else {
            reject([]);
          }
        });
    });
  }
}
