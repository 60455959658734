import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import localeCsExtra from '@angular/common/locales/extra/cs';
import localeUaExtra from '@angular/common/locales/extra/ru-UA';
import localeSrCyrlExtra from '@angular/common/locales/extra/sr-Cyrl';
import localeSrLatnExtra from '@angular/common/locales/extra/sr-Latn';
import localeUa from '@angular/common/locales/ru-UA';
import localeSrCyrl from '@angular/common/locales/sr-Cyrl';
import localeSrLtn from '@angular/common/locales/sr-Latn';
import { Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from '@app/shared/helpers/date-adapter';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { getCurrentLanguageCode } from './shared/helpers/language';
import { getCurrentLocale } from './shared/helpers/locale';
registerLocaleData(localeEn, 'en');
registerLocaleData(localeCs, 'cs-CZ', localeCsExtra);
registerLocaleData(localeCs, 'cz', localeCsExtra);
registerLocaleData(localeUa, 'ru-UA', localeUaExtra);
registerLocaleData(localeUa, 'ua', localeUaExtra);
registerLocaleData(localeSrLtn, 'sr-Latn', localeSrLatnExtra);
registerLocaleData(localeSrCyrl, 'sr-Cyrl', localeSrCyrlExtra);
const cookieConfig = {
    cookie: {
        domain: environment.cookieDomain,
    },
    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: '#004d89',
            text: '#ffffff',
            link: '#ffffff',
        },
        button: {
            background: '#fafafa',
            text: '#000000',
            border: 'transparent',
        },
    },
    type: 'info',
};
const ɵ0 = CUSTOM_DATE_FORMATS, ɵ1 = () => getCurrentLocale();
export class AppModule {
}
export function appInitializerFactory(translate, injector, dateAdapter) {
    return () => new Promise((resolve) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            const defaultLang = getCurrentLanguageCode();
            translate.setDefaultLang(defaultLang);
            translate.use(defaultLang).subscribe(() => {
                // tslint:disable-next-line:no-console
                console.info(`Successfully initialized '${defaultLang}' language.'`);
            }, err => {
                console.error(`Problem with '${defaultLang}' language initialization.'`);
            }, () => {
                resolve(null);
            });
            dateAdapter.setLocale(defaultLang);
        });
    });
}
export function translationsHttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, environment.baseHref + '/assets/i18n/');
}
export { ɵ0, ɵ1 };
