import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit } from 'ng-http-loader';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { merge } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { languageLoader } from './shared/helpers/language';
import { LanguageService } from './shared/services/language/language.service';
import { NavigationService } from './shared/services/navigation/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  backgroundColor = environment.ngHttpLoaderBackgroundColor;
  debounceDelay = environment.ngHttpLoaderDebounceDelay;
  minDuration = environment.ngHttpLoaderMinDuration;
  filteredUrlPatterns = environment.ngHttpLoaderFilteredUrlPatterns;
  spinkit = Spinkit;
  spinkitType: string;
  asyncLoadCount = 0;
  navigationCount = 0;
  showLazyRouteLoader = false;

  constructor(
    private ccService: NgcCookieConsentService,
    private languageService: LanguageService,
    private title: Title,
    private translateService: TranslateService,
    private router: Router,
    private navigation: NavigationService
  ) {}

  ngOnInit() {
    languageLoader(this.translateService)
      .then(() => {
        this.languageService.switchLanguage(this.languageService.getCurrentLanguageCode(), false);
        return this.translateService.get('app_title').toPromise();
      })
      .then(appTitle => {
        this.title.setTitle(appTitle);

        // get cookie translation for current language
        this.translateCookieConsent();
      });

    if (this.spinkitType !== undefined && this.spinkit !== null) {
      this.spinkitType = this.spinkitType[environment.ngHttpLoaderSpinner];
    }
    this.spinkitType = this.spinkitType === undefined ? this.spinkitType : this.spinkit.skCubeGrid;

    // if user doesn't have consent, subscribe to language change
    // or pop up close and destroy if user has consent
    if (!this.ccService.hasConsented()) {
      merge(this.translateService.onLangChange, this.ccService.popupClose$)
        .pipe(takeWhile(() => !this.ccService.hasConsented()))
        .subscribe(() => {
          this.translateCookieConsent();
        });
    }

    // subscription starts here since we need complete navigation/route events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.navigationCount++;
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.navigationCount--;
      }

      if (event instanceof NavigationEnd) {
        this.navigation.onNavigationEndChanged(event);
      } else if (event instanceof RouteConfigLoadStart) {
        this.asyncLoadCount++;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.asyncLoadCount--;
      }

      this.showLazyRouteLoader = !!(this.navigationCount && this.asyncLoadCount);
    });
  }

  private translateCookieConsent() {
    this.ccService.getConfig().content = this.ccService.getConfig().content || {};
    this.ccService.getConfig().content.header = this.translateService.instant('cookie.header');
    this.ccService.getConfig().content.message = this.translateService.instant('cookie.message');
    this.ccService.getConfig().content.dismiss = this.translateService.instant('cookie.dismiss');
    this.ccService.getConfig().content.allow = this.translateService.instant('cookie.allow');
    this.ccService.getConfig().content.deny = this.translateService.instant('cookie.deny');
    this.ccService.getConfig().content.link = this.translateService.instant('cookie.link');
    this.ccService.getConfig().content.href = this.translateService.instant('cookie.href');
    this.ccService.getConfig().content.policy = this.translateService.instant('cookie.policy');

    this.ccService.destroy(); // remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
  }
}
