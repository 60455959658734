import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeEn from '@angular/common/locales/en';
import localeCsExtra from '@angular/common/locales/extra/cs';
import localeUaExtra from '@angular/common/locales/extra/ru-UA';
import localeSrCyrlExtra from '@angular/common/locales/extra/sr-Cyrl';
import localeSrLatnExtra from '@angular/common/locales/extra/sr-Latn';
import localeUa from '@angular/common/locales/ru-UA';
import localeSrCyrl from '@angular/common/locales/sr-Cyrl';
import localeSrLtn from '@angular/common/locales/sr-Latn';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomDatePickerAdapter, CUSTOM_DATE_FORMATS } from '@app/shared/helpers/date-adapter';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { getCurrentLanguageCode } from './shared/helpers/language';
import { getCurrentLocale } from './shared/helpers/locale';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeCs, 'cs-CZ', localeCsExtra);
registerLocaleData(localeCs, 'cz', localeCsExtra);
registerLocaleData(localeUa, 'ru-UA', localeUaExtra);
registerLocaleData(localeUa, 'ua', localeUaExtra);
registerLocaleData(localeSrLtn, 'sr-Latn', localeSrLatnExtra);
registerLocaleData(localeSrCyrl, 'sr-Cyrl', localeSrCyrlExtra);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#004d89',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#fafafa',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
};

@NgModule({
  declarations: [AppComponent],
  exports: [TranslateModule],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translationsHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
  ],
  providers: [
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector, DateAdapter],
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: LOCALE_ID, useFactory: () => getCurrentLocale() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function appInitializerFactory(translate: TranslateService, injector: Injector, dateAdapter: DateAdapter<Date>) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const defaultLang = getCurrentLanguageCode();
        translate.setDefaultLang(defaultLang);
        translate.use(defaultLang).subscribe(
          () => {
            // tslint:disable-next-line:no-console
            console.info(`Successfully initialized '${defaultLang}' language.'`);
          },
          err => {
            console.error(`Problem with '${defaultLang}' language initialization.'`);
          },
          () => {
            resolve(null);
          }
        );

        dateAdapter.setLocale(defaultLang);
      });
    });
}

export function translationsHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.baseHref + '/assets/i18n/');
}
