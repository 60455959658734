import {Injectable} from '@angular/core';
import {BoResponse} from '@app/shared/interfaces/response.interface';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {EventLog} from '@app/shared/models/event-log.model';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class EventLogsService {

  private _eventLogs = [];

  constructor(private http: HttpClient) { }

  getEventLogs(identityId: string): Observable<any> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.eventLogsPath}/${identityId}`)
      .pipe(
        map(res => {
          if (res.success) {
            this._eventLogs = res.data;
            return res.data;
          }
          console.error(res.message);
          return throwError(res.message);
        }),
        catchError(error => {
          return of([]);
        }));
  }

  getLastEvent(identityId: string): Promise<any> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.eventLogsPath}/${identityId}/last`)
      .pipe(
        map(res => {
          if (res.success) {
            return new EventLog().deserialize(res.data);
          }
          console.error(res.message);
          return throwError(res.message);
        }),
        catchError(error => {
          return of(null);
        })).toPromise();
  }
}
