import * as i0 from "@angular/core";
import * as i1 from "../services/company/company.service";
export class CompanyIdInterceptor {
    constructor(companyService) {
        this.companyService = companyService;
    }
    intercept(request, next) {
        if (this.companyService.currentCompany) {
            request = request.clone({
                setHeaders: {
                    'x-company-id': this.companyService.currentCompany,
                },
            });
        }
        return next.handle(request);
    }
}
CompanyIdInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CompanyIdInterceptor_Factory() { return new CompanyIdInterceptor(i0.ɵɵinject(i1.CompanyService)); }, token: CompanyIdInterceptor, providedIn: "root" });
