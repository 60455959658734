import { HttpParams } from '@angular/common/http';
import { BaseApiService } from '@app/shared/bases/base-api.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GalleryService extends BaseApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getIcons(page, size, filter) {
        let params = new HttpParams();
        if (page !== null && size !== null) {
            params = params.append('page', String(page));
            params = params.append('size', String(size));
        }
        if (filter != null) {
            if (filter.name)
                params = params.append('name', filter.name);
            if (filter.locale)
                params = params.append('locale', filter.locale);
            if (filter.categoryId)
                params = params.append('categoryId', filter.categoryId);
        }
        params = params.append('completed', String(true));
        return this.get(`${environment.imageGalleryPath}`, params);
    }
    getAllIconCategories() {
        return this.get(`${environment.imageGalleryCategoryPath}`);
    }
}
GalleryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GalleryService_Factory() { return new GalleryService(i0.ɵɵinject(i1.HttpClient)); }, token: GalleryService, providedIn: "root" });
