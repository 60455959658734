/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./graph-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/generic-forms/form-select-search.component.ngfactory";
import * as i3 from "../../../shared/components/generic-forms/form-select-search.component";
import * as i4 from "@angular/common";
import * as i5 from "./graph-filter.component";
var styles_GraphFilterComponent = [i0.styles];
var RenderType_GraphFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GraphFilterComponent, data: {} });
export { RenderType_GraphFilterComponent as RenderType_GraphFilterComponent };
function View_GraphFilterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-form-select-search", [], null, [[null, "selectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChange" === en)) {
        var pd_0 = (_co.onSelectChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormSelectSearchComponent_0, i2.RenderType_FormSelectSearchComponent)), i1.ɵdid(2, 770048, null, 0, i3.FormSelectSearchComponent, [i1.ChangeDetectorRef], { control: [0, "control"], fieldClasses: [1, "fieldClasses"], useSearch: [2, "useSearch"], options: [3, "options"] }, { selectionChange: "selectionChange" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formControl; var currVal_1 = "no-label"; var currVal_2 = false; var currVal_3 = i1.ɵunv(_v, 2, 3, i1.ɵnov(_v, 3).transform(_co.graphFilter.options)); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_GraphFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphFilterComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graphFilter.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "SELECT"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GraphFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-graph-filter", [], null, null, null, View_GraphFilterComponent_0, RenderType_GraphFilterComponent)), i1.ɵdid(1, 114688, null, 0, i5.GraphFilterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GraphFilterComponentNgFactory = i1.ɵccf("app-graph-filter", i5.GraphFilterComponent, View_GraphFilterComponent_Host_0, { graphFilter: "graphFilter", graphId: "graphId" }, { valueChanged: "valueChanged" }, []);
export { GraphFilterComponentNgFactory as GraphFilterComponentNgFactory };
