import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LocalizedDatePipe } from '@app/shared/services/pipes/localized-date.pipe';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
  pipes = {
    localizedDate: new LocalizedDatePipe(),
    translate: new TranslatePipe(this.translateService, this.cdr),
  };

  constructor(private translateService: TranslateService, private cdr: ChangeDetectorRef) {}

  transform(value: any, pipeToken: any): any {
    if (typeof pipeToken !== 'undefined' && pipeToken !== null && this.pipes[pipeToken]) {
      return this.pipes[pipeToken].transform(value);
    }
    return value;
  }
}
