import { Injectable } from '@angular/core';
import { menu, Menu } from '@app/core/data/menu';
import { CatalogItemsService } from '@app/warehouse/services/catalog-items/catalog-items.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  initialMenus: Menu = menu;

  /** Observable for menu items, it will send the initial menus first and loads dynamic menu items later on refresh() */
  menus$ = new BehaviorSubject(this.initialMenus);

  constructor(private catalogItemService: CatalogItemsService) {}

  refresh() {
    this.catalogItemService
      .getReturnablePackagingGroup()
      .toPromise()
      .then(data => {
        const menus = menu;
        // hide returnable packaging (Warehouse -> Returnable packaging)
        const warehouseMenu = menus.find(item => item.id === 'warehouse');
        if (warehouseMenu) {
          const rpMenu = warehouseMenu.items.find(item => item.id === 'warehouse_returnable_packaging');
          if (rpMenu) {
            rpMenu.hidden = data == null || (data && data.deleted === true);
          }
        }

        this.menus$.next(menus);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
