import { formatDate as angularFormatDate } from '@angular/common';
import { getCurrentLocale } from './locale';

interface FormatTableDateOptions {
  date: string | Date | number;
  format?: string;
  locale?: string;
  timezone?: string;
}

export function formatDate(config: FormatTableDateOptions): string {
  const format = config.format ? config.format : 'medium';
  const locale = config.locale ? config.locale : getCurrentLocale();
  const timezone = config.timezone ? config.timezone : getBrowserGMTOffset();

  return angularFormatDate(config.date, format, locale, timezone);
}

export function getBrowserGMTOffset(prefix: string = 'GMT') {
  const tzOffsetInMinutes = new Date().getTimezoneOffset(); // locale zone - GMT
  const sign = -1 * tzOffsetInMinutes < 0 ? '-' : '+';
  const tzOffset = Math.floor(Math.abs(tzOffsetInMinutes) / 60);
  const tzOffsetString = tzOffset < 10 ? `0${tzOffset}00` : `${tzOffset}00`;

  return `${prefix}${sign}${tzOffsetString}`;
}
