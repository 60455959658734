import { getBrowserGMTOffset } from '@app/shared/helpers/date';
import { getCurrentLanguageCode } from '@app/shared/helpers/language';
import { saveToLocalStorage } from '@app/shared/helpers/local-storage';
import { getCurrentLocale } from '@app/shared/helpers/locale';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/material/core";
import * as i3 from "../../../core/services/session/session.service";
export class LanguageService {
    constructor(translateService, adapter, session) {
        this.translateService = translateService;
        this.adapter = adapter;
        this.session = session;
        this.langagueObservable = new BehaviorSubject(this.getCurrentLocale());
    }
    /**
     * Changes from one language to another
     * @param language locale id, language to use
     * @param disableReload boolean if disable session reload page
     */
    switchLanguage(code, disableReload = false) {
        saveToLocalStorage('lang', code);
        this.translateService.use(code);
        this.adapter.setLocale(code);
        this.langagueObservable.next(this.getCurrentLocale());
        if (disableReload) {
            this.session.reloadPage();
        }
    }
    getCurrentLanguageCode() {
        return getCurrentLanguageCode();
    }
    loadSelectedLanguageToken() {
        return `language.${getCurrentLanguageCode()}`;
    }
    getLanguageListCodes() {
        return environment.languageCodes;
    }
    getCurrentLocale() {
        return getCurrentLocale();
    }
    getBrowserGMTOffset() {
        return getBrowserGMTOffset();
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.DateAdapter), i0.ɵɵinject(i3.SessionService)); }, token: LanguageService, providedIn: "root" });
