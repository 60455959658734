<div class="mat-tree-wrapper">
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu">
    <!-- This is the tree node template for leaf nodes -->
    <li>
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <ng-container *ngIf="node.asHeading; else asMenu">
          <div class="mat-tree-node as-heading">
            <div class="menu-heading">
              <span class="menu-heading-label">{{ node.label | translate }}</span>
            </div>
          </div>
        </ng-container>

        <ng-template #asMenu>
          <div class="mat-tree-node" [ngClass]="node.cssClasses" *ngIf="!node.hidden">
            <a class="label-title remove-decoration" [routerLink]="node.link" (click)="onMenuClicked(node.link)">
              <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.icon">{{ node.icon }}</mat-icon>
              <span class="label-title-text">
                {{ node.label | translate }}
              </span>
            </a>
          </div>
        </ng-template>
      </mat-tree-node>
    </li>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node" [ngClass]="node.cssClasses" *ngIf="!node.hidden">
        <span class="label-title pointer-cursor" matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">{{ node.icon }}</mat-icon>
          <span class="label-title-text">{{ node.label | translate }}</span>
        </span>
        <!-- <span class="expand-icon-wrapper"> -->
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">{{
            treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
          }}</mat-icon>
        </button>
        <!-- </span> -->
      </div>
      <ul [class.menu-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </mat-nested-tree-node>
  </mat-tree>
</div>
