import { HttpParams } from '@angular/common/http';
import { BaseApiService } from '@app/shared/bases/base-api.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WidgetService extends BaseApiService {
    constructor(http) {
        super(http);
        this.http = http;
    }
    getStatInfo(eventLog) {
        const params = new HttpParams()
            .set('date', new Date().toISOString())
            .set('lastEventLogDate', new Date(eventLog.eventTime).toISOString())
            .set('state', eventLog.eventBundle.state);
        return this.get(`${environment.statisticInfoForDashboardPath}`, params);
    }
}
WidgetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WidgetService_Factory() { return new WidgetService(i0.ɵɵinject(i1.HttpClient)); }, token: WidgetService, providedIn: "root" });
