<router-outlet></router-outlet>
<ng-http-loader
  [backgroundColor]="backgroundColor"
  [spinner]="spinkit.skCubeGrid"
  [debounceDelay]="debounceDelay"
  [minDuration]="minDuration"
  [filteredUrlPatterns]="filteredUrlPatterns"
>
</ng-http-loader>
<div class="lazy-route-loader" *ngIf="showLazyRouteLoader">
  <span>{{ 'app.loading' | translate }}</span>
</div>
