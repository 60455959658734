<div [class]="tableWrapperClasses">
  <mat-toolbar
    *ngIf="(filename && filename.length >= 0) || createLink || leftButtonsTemplate || rightButtonsTemplate"
    style="background-color: white; margin-bottom: 2px"
  >
    <div class="table-toolbar-buttons">
      <div class="left-buttons mr-auto">
        <!-- template outle for left-most buttons -->
        <ng-container *ngTemplateOutlet="leftButtonsTemplate"></ng-container>
        <!-- export buttons -->
        <button mat-raised-button [matMenuTriggerFor]="export" *ngIf="showTable && filename && filename.length >= 0">
          <mat-icon>cloud_download</mat-icon>
          {{ 'common.export' | translate }}
        </button>
        <mat-menu #export="matMenu">
          <button mat-menu-item (click)="exportTo('xlsx')">{{ 'common.export.excel' | translate }}</button>
          <button mat-menu-item (click)="exportTo('csv')">{{ 'common.export.csv' | translate }}</button>
          <button mat-menu-item (click)="exportTo('pdf')">{{ 'common.export.pdf' | translate }}</button>
        </mat-menu>
        <button
          mat-raised-button
          class="mx-1"
          *ngIf="createLink"
          [routerLink]="createLink"
          [queryParams]="queryParams"
          (contextmenu)="onButtonContextMenu($event, createLink)"
        >
          <mat-icon>add_circle_outline</mat-icon>
          {{ 'common.create' | translate }}
        </button>
      </div>
      <!-- template outle for right-most buttons -->
      <div *ngIf="rightButtonsTemplate" class="pl-auto right-buttons">
        <ng-container *ngTemplateOutlet="rightButtonsTemplate"></ng-container>
      </div>
      <button color="primary" mat-raised-button appContextMenuOpen *ngIf="productImport" (click)="productImport()">
        <mat-icon>cloud_upload</mat-icon>
        {{ 'common.import' | translate }}
      </button>
    </div>
  </mat-toolbar>
  <table mat-table [dataSource]="dataSource" [hidden]="!showTable" [class.responsive-now]="responsiveNow">
    <!-- rows (except for menu items) -->
    <ng-container *ngFor="let column of columnDefs; let colIndex = index" [matColumnDef]="column.key">
      <th mat-header-cell *matHeaderCellDef [ngClass]="getHeaderClasses(column)">{{ column.name | translate }}</th>
      <td
        mat-cell
        *matCellDef="let element; let rowIndex = index"
        [ngClass]="getCellClasses(column, element, rowIndex, colIndex)"
      >
        <span class="mobile-label">{{ column.name | translate }}</span>
        <ng-container [ngSwitch]="column.type" *ngIf="column.hide ? column.hide(element) !== true : true">
          <span *ngSwitchDefault>{{
            (column.getValue ? column.getValue(element) : element[column.key]) | nonull
          }}</span>
          <span *ngSwitchCase="'text'">{{
            (column.getValue ? column.getValue(element) : element[column.key]) | nonull
          }}</span>
          <span *ngSwitchCase="'html'" [innerHTML]="column.getValue ? column.getValue(element) : element[column.key]">
          </span>
          <span *ngSwitchCase="'toggle'">
            <mat-slide-toggle
              [checked]="column.getValue ? column.getValue(element) : element[column.key]"
              (change)="column.toggleChange($event, element)"
            ></mat-slide-toggle>
          </span>
          <span *ngSwitchCase="'template'">
            <ng-container
              *ngTemplateOutlet="
                column.getTemplate(element);
                context: {
                  rowData: element,
                  key: column.key,
                  value: column.getValue ? column.getValue(element) : element[column.key]
                }
              "
            ></ng-container>
          </span>

          <mat-chip-list *ngSwitchCase="'chips'">
            <ng-container *ngIf="isArray(element[column.key])">
              <span *ngFor="let chip of element[column.key]">
                <mat-chip *ngIf="chip[column.chipKey]">
                  {{ chip[column.chipKey] | nonull }}
                </mat-chip>
              </span>
            </ng-container>
            <ng-container *ngIf="!isArray(element[column.key]) && element[column.key]">
              <mat-chip *ngIf="element[column.key][column.chipKey]">
                {{ element[column.key][column.chipKey] | nonull }}
              </mat-chip>
            </ng-container>
          </mat-chip-list>
        </ng-container>
      </td>
      <ng-container *ngIf="showFooter">
        <td mat-footer-cell *matFooterCellDef [ngClass]="getFooterClasses(column)">
          <ng-container *ngIf="column.footerDefaultValue; else footerValue">
            {{ column.footerDefaultValue | translate }}
          </ng-container>
          <ng-template #footerValue>
            {{ column.getFooterValue ? column.getFooterValue(footer) : footer[column.footerKey] }}
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <!-- row menus -->
    <ng-container role="menu" matColumnDef="menus" class="sticky-end-column">
      <th mat-header-cell *matHeaderCellDef class="row-menu-header"></th>
      <td mat-cell *matCellDef="let element" class="mat-action-list">
        <span *ngFor="let menu of inputRowMenus">
          <button
            *ngIf="menu.hide ? menu.hide(element) !== true : true"
            mat-icon-button
            class="row-menu-items"
            [attr.aria-label]="menu.label | translate"
            [attr.title]="menu.label | translate"
            [matTooltip]="menu.label | translate"
            (click)="menu.action($event, element)"
            (contextmenu)="onRowContextMenu($event, menu, element)"
          >
            <mat-icon>{{ menu.icon }}</mat-icon>
          </button>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = index; columns: displayedColumns"
      [ngClass]="tableRowClasses[i] ? tableRowClasses[i].classes : ''"
    ></tr>
    <ng-container *ngIf="showFooter">
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </ng-container>
  </table>

  <mat-card class="{{ noDataClass }}" *ngIf="!showTable">
    {{ noDataText | translate }}
  </mat-card>

  <div [hidden]="!usePaging || (usePaging && !showTable)">
    <mat-paginator [length]="dataCount" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
  </div>

  <div
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
  ></div>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-row="row" let-menu="menu" let-type="type" let-link="link">
      <button
        mat-menu-item
        (click)="
          type === 'ROW'
            ? openInNewTabOrWindowRowMenu($event, menu, row, 'TAB')
            : openInNewTabOrWindowButton($event, link, 'TAB')
        "
      >
        {{ 'common.open_new_tab' | translate }}
      </button>
      <button
        mat-menu-item
        (click)="
          type === 'ROW'
            ? openInNewTabOrWindowRowMenu($event, menu, row, 'WINDOW')
            : openInNewTabOrWindowButton($event, link, 'WINDOW')
        "
      >
        {{ 'common.open_new_window' | translate }}
      </button>
    </ng-template>
  </mat-menu>
</div>
