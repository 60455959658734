import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getBrowserGMTOffset } from '../helpers/date';

@Injectable({
  providedIn: 'root',
})
export class TimezoneOffsetInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'x-timezone-offset': getBrowserGMTOffset(''),
      },
    });

    return next.handle(request);
  }
}
