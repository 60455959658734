import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
import * as i3 from "@ngx-translate/core";
export class TitleService {
    constructor(route, title, translateService) {
        this.route = route;
        this.title = title;
        this.translateService = translateService;
        this.title$ = new BehaviorSubject('');
        this.url$ = this.route.url;
    }
    /**
     * Sets value of any tipe to title.
     *
     * @param {*} value
     * @memberof TitleService
     */
    setTitle(value) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const titleToken = value.toString();
            const titleString = yield this.translateService.get(titleToken).toPromise();
            const titleCommon = yield this.translateService.get('app_title').toPromise();
            let titleCombined = titleCommon;
            if (titleString) {
                titleCombined = `${titleString} / ${titleCombined}`;
            }
            ;
            this.title$.next(titleString);
            this.title.setTitle(titleCombined);
        });
    }
}
TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.Title), i0.ɵɵinject(i3.TranslateService)); }, token: TitleService, providedIn: "root" });
