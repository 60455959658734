import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth.guard';
import { ModulesGuard } from '@app/shared/guards/modules.guard';
import { EmptyComponent } from './components/empty/empty.component';
import { CoreComponent } from './core.component';

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard, ModulesGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'statistics',
        loadChildren: () => import('../statistics/statistics.module').then(m => m.StatisticsModule),
      },
      {
        path: 'warehouse',
        loadChildren: () => import('../warehouse/warehouse.module').then(m => m.WarehouseModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'wirecard',
        loadChildren: () => import('../wirecard/wirecard.module').then(m => m.WirecardModule),
      },
      {
        path: 'partners',
        loadChildren: () => import('../partners/partners.module').then(m => m.PartnersModule),
      },
      {
        path: 'empty',
        component: EmptyComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
