import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class NavigationService {
    constructor() {
        this.navigation$ = new BehaviorSubject(null);
    }
    onNavigationEndChanged(navigationEnd) {
        this.navigation$.next(navigationEnd);
    }
}
NavigationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(); }, token: NavigationService, providedIn: "root" });
