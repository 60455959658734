import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookupValue } from '@app/shared/interfaces/common.interface';
import { BoResponse } from '@app/shared/interfaces/response.interface';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  CompanyInterface,
  ConfigItemResponse,
  CurrencyInterface,
  DeviceInterface,
  EstablishmentInterface,
  FiscalizationInterface,
  GeneralInterface,
  MeasuringUnitInterface,
  PaymentMethodsInterface,
  TakeawayInterface,
  UsersInterface,
  VatsInterface,
} from './response';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const API_URL = environment.apiUrl;
/**
 * to skip tslint. use constant
 */
const keyFilter = 'filter';

@Injectable()
export class ConfigService {
  // tslint:disable-next-line:variable-name
  private _sectionFilters = {
    general: { section: 'general', filter: this.filterGeneralDict.bind(this) },
    takeaway: { section: 'takeaway', filter: this.filterTakeawayDict.bind(this) },
    currency: {
      section: 'currency',
      filter: this.filterCurrencyDict.bind(this),
    },
    company: { section: 'company', filter: this.filterCompanyDict.bind(this) },
    establishment: {
      section: 'establishment',
      filter: this.filterEstablishmentDict.bind(this),
    },
    device: { section: 'device', filter: this.filterDeviceDict.bind(this) },
    fiscalization: { section: 'fiscalization', filter: this.filterFiscalizationDict.bind(this) },
    measuringUnits: {
      section: 'measuringunits',
      filter: this.filterMeasuringUnitsList.bind(this),
    },
    measuringUnitsAll: {
      section: 'measuringunits',
      filter: this.filterMeasuringUnitsAll.bind(this),
    },
    measuringUnitsById: {
      section: 'measuringunits',
      filter: this.filterMeasuringUnitsDict.bind(this),
    },
    basicMeasuringUnits: {
      section: 'measuringunits',
      filter: this.filterBasicMeasuringUnitsList.bind(this),
    },
    basicMeasuringUnitsById: {
      section: 'measuringunits',
      filter: this.filterBasicMeasuringUnitsDict.bind(this),
    },
    vats: { section: 'vats', filter: this.filterVatsList.bind(this) },
    vatsById: { section: 'vats', filter: this.filterVatsDict.bind(this) },
    vatsAll: { section: 'vats', filter: this.filterVatsAll.bind(this) },
    users: { section: 'users', filter: this.filterUsersList.bind(this) },
    usersAll: { section: 'users', filter: this.filterUsersAll.bind(this) },
    usersById: { section: 'users', filter: this.filterUsersDict.bind(this) },
    paymentMethods: {
      section: 'paymentmethods',
      filter: this.filterPaymentMethodsList.bind(this),
    },
    paymentMethodsAll: {
      section: 'paymentmethods',
      filter: this.filterPaymentMethodsAll.bind(this),
    },
    paymentMehodsById: {
      section: 'paymentmethods',
      filter: this.filterPaymentMethodsDict.bind(this),
    },
  };

  constructor(private http: HttpClient, private translate: TranslateService) {}

  private getSpecificConfigItems(path: string, sections?: string[]): Observable<any> {
    const url = `${API_URL}/${path}`;
    let getMethod = this.http.get<BoResponse>(url);

    if (sections && sections.length) {
      // some of the keys do not exist on backend, this code removes unknown keys and adds apporpriate names for how are
      // sections named on backend
      let appropriateSectionNames = sections
        .filter(s => this._sectionFilters.hasOwnProperty(s))
        .map(s => this._sectionFilters[s].section);

      appropriateSectionNames = [...new Set(appropriateSectionNames)]; // remove duplicated;

      getMethod = this.http.get<BoResponse>(url, {
        params: new HttpParams().set('sections', appropriateSectionNames.join(',')),
      });
    }

    return getMethod.pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(error => {
        console.error(error);
        return of([]);
      })
    );
  }

  getBySections(sections: string[]): Observable<any> {
    if (sections.length === 0) {
      return of({});
    }

    return this.getSpecificConfigItems(environment.configItemsBySectionsPaths, sections).pipe(
      map(data => {
        const res = {};
        sections.forEach(section => {
          res[section] = this._sectionFilters[section][keyFilter](data);
        });
        return res;
      })
    );
  }

  getGeneral(): Observable<GeneralInterface> {
    return this.getSpecificConfigItems(environment.configItemsGeneralPath).pipe(
      map(data => this.filterGeneralDict(data))
    );
  }

  getCurrencies(): Observable<CurrencyInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsCurrencyPath).pipe(
      map(data => this.filterCurrenciesList(data))
    );
  }

  getAvailableCurrencies(): Observable<string[]> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.configItemsAvailableCurrencyPath}`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getCompany(): Observable<CompanyInterface> {
    return this.getSpecificConfigItems(environment.configItemsCompanyPath).pipe(
      map(data => this.filterCompanyDict(data))
    );
  }

  getEstablishment(): Observable<EstablishmentInterface> {
    return this.getSpecificConfigItems(environment.configItemsEstablishmentPath).pipe(
      map(data => this.filterEstablishmentDict(data))
    );
  }

  getDevice(): Observable<DeviceInterface> {
    return this.getSpecificConfigItems(environment.configItemsDevicePath).pipe(
      map(data => this.filterDeviceDict(data))
    );
  }

  getMeasuringUnits(): Observable<MeasuringUnitInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsMeasuringUnitsPath).pipe(
      map(data => this.filterMeasuringUnitsList(data))
    );
  }

  getMeasuringUnitsAll(): Observable<MeasuringUnitInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsMeasuringUnitsPath).pipe(
      map(data => this.filterMeasuringUnitsAll(data))
    );
  }

  getMeasuringUnitsById(): Observable<{
    [key: string]: MeasuringUnitInterface;
  }> {
    return this.getSpecificConfigItems(environment.configItemsMeasuringUnitsPath).pipe(
      map(data => this.filterMeasuringUnitsDict(data))
    );
  }

  getBasicMeasuringUnits(): Observable<MeasuringUnitInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsMeasuringUnitsPath).pipe(
      map(data => this.filterBasicMeasuringUnitsList(data))
    );
  }

  getBasicMeasuringUnitsById(): Observable<{
    [key: string]: MeasuringUnitInterface;
  }> {
    return this.getSpecificConfigItems(environment.configItemsMeasuringUnitsPath).pipe(
      map(data => this.filterBasicMeasuringUnitsDict(data))
    );
  }

  getVats(): Observable<VatsInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsVatsPath).pipe(map(data => this.filterVatsList(data)));
  }

  getVatsAll(): Observable<VatsInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsVatsPath).pipe(map(data => this.filterVatsAll(data)));
  }

  getVatsById(identityId): Observable<{ [key: string]: VatsInterface }> {
    return this.getSpecificConfigItems(environment.configItemsVatsPath).pipe(map(data => this.filterVatsDict(data)));
  }

  getUsers(): Observable<UsersInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsUsersPath).pipe(map(data => this.filterUsersList(data)));
  }

  getUsersAll(): Observable<UsersInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsUsersPath).pipe(map(data => this.filterUsersAll(data)));
  }

  getUsersById(): Observable<{ [key: string]: UsersInterface }> {
    return this.getSpecificConfigItems(environment.configItemsUsersPath).pipe(map(data => this.filterUsersDict(data)));
  }

  getPaymentMethods(): Observable<PaymentMethodsInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsPaymentmethodsPath).pipe(
      map(data => this.filterPaymentMethodsList(data))
    );
  }

  getPaymentMethodsAll(): Observable<PaymentMethodsInterface[]> {
    return this.getSpecificConfigItems(environment.configItemsPaymentmethodsPath).pipe(
      map(data => this.filterPaymentMethodsAll(data))
    );
  }

  getPaymentMehodsById(): Observable<{
    [key: string]: PaymentMethodsInterface;
  }> {
    return this.getSpecificConfigItems(environment.configItemsPaymentmethodsPath).pipe(
      map(data => this.filterPaymentMethodsDict(data))
    );
  }

  getAppVersion(): Observable<string> {
    return this.getDevice().pipe(map(data => this.filterAppVersion(data)));
  }

  private filterGeneralDict(data: ConfigItemResponse): GeneralInterface {
    return data.general;
  }

  private filterTakeawayDict(data: ConfigItemResponse): TakeawayInterface {
    return data.takeaway;
  }

  private filterCurrencyDict(data: ConfigItemResponse): { [key: string]: CurrencyInterface } {
    return data.currency;
  }

  private filterCompanyDict(data: ConfigItemResponse): CompanyInterface {
    return data.company;
  }

  private filterEstablishmentDict(data: ConfigItemResponse): EstablishmentInterface {
    return data.establishment;
  }

  private filterDeviceDict(data: ConfigItemResponse): DeviceInterface {
    return data.device;
  }

  private filterFiscalizationDict(data: ConfigItemResponse): FiscalizationInterface {
    return data.fiscalization;
  }

  private filterMeasuringUnitsDict(data: ConfigItemResponse): { [key: string]: MeasuringUnitInterface } {
    Object.keys(data.measuringunits).map(key => {
      const unit = data.measuringunits[key];
      if (unit.basedon) {
        unit.basic_measuring_unit = data.measuringunits[unit.basedon].name;
      }
    });
    return data.measuringunits;
  }

  private filterCurrenciesList(data: ConfigItemResponse): CurrencyInterface[] {
    return Object.values(data.currency)
      .sort((a, b) => a.code ? 
        a.code.localeCompare(b.code ? b.code : this.translate.instant(_('empty.value'))) :
        this.translate.instant(_('empty.value')).localeCompare(b.code ? b.code : this.translate.instant(_('empty.value')))
      )
      .filter(u => u.active);
  }

  private filterMeasuringUnitsAll(data: ConfigItemResponse): MeasuringUnitInterface[] {
    return Object.values(this.filterMeasuringUnitsDict(data))
      .sort((a, b) => a.name ? 
      a.name.localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) :
      this.translate.instant(_('empty.value')).localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) 
      )
      .filter(mu => !mu.deleted);
  }

  private filterMeasuringUnitsList(data: ConfigItemResponse): MeasuringUnitInterface[] {
    return this.filterMeasuringUnitsAll(data).filter(mu => mu.active);
  }

  private filterBasicMeasuringUnitsDict(data: ConfigItemResponse): { [key: string]: MeasuringUnitInterface } {
    return Object.values(this.filterMeasuringUnitsDict(data)).reduce((acc, mu) => {
      if (!mu.basedon) {
        acc[mu._id] = mu;
      }
      return acc;
    }, {});
  }

  private filterBasicMeasuringUnitsList(data: ConfigItemResponse): MeasuringUnitInterface[] {
    return this.filterMeasuringUnitsList(data)
      .filter(mu => !mu.basedon)
      .sort((a, b) => a.name ? 
      a.name.localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) :
      this.translate.instant(_('empty.value')).localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) 
      );
  }

  private filterVatsAll(data: ConfigItemResponse): VatsInterface[] {
    return Object.values(data.vats).sort((a, b) => 
      a.label ? 
      a.label.localeCompare(b.label ? b.label : this.translate.instant(_('empty.value'))) : 
      this.translate.instant(_('empty.value')).localeCompare(b.label ? b.label : this.translate.instant(_('empty.value')))
    );
  }

  private filterVatsDict(data: ConfigItemResponse): { [key: string]: VatsInterface } {
    return data.vats;
  }

  private filterVatsList(data: ConfigItemResponse): VatsInterface[] {
    return this.filterVatsAll(data).filter(v => v.active);
  }

  private filterUsersDict(data: ConfigItemResponse): { [key: string]: UsersInterface } {
    return data.users;
  }

  private filterUsersAll(data: ConfigItemResponse): UsersInterface[] {
    return Object.values(data.users)
      .sort((a, b) => a.name ?
        a.name.localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) :
        this.translate.instant(_('empty.value')).localeCompare(b.name ? b.name : this.translate.instant(_('empty.value')))
      )
      .filter(u => u.deleted !== true);
  }

  private filterUsersList(data: ConfigItemResponse): UsersInterface[] {
    return this.filterUsersAll(data).filter(u => u.active || u.active === undefined || u.active === null);
  }

  private filterPaymentMethodsAll(data: ConfigItemResponse): PaymentMethodsInterface[] {
    return Object.values(data.paymentmethods)
      .sort((a, b) => a.name ? 
      a.name.localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) :
      this.translate.instant(_('empty.value')).localeCompare(b.name ? b.name : this.translate.instant(_('empty.value'))) 
      )
      .filter(pm => pm.deleted !== true);
  }

  private filterPaymentMethodsDict(data: ConfigItemResponse): { [key: string]: PaymentMethodsInterface } {
    return data.paymentmethods;
  }

  private filterPaymentMethodsList(data: ConfigItemResponse): PaymentMethodsInterface[] {
    return this.filterPaymentMethodsAll(data)
      .filter(pm => pm.active)
      .filter(pm => environment.removedPaymentMethodsByType.indexOf(pm.type) === -1);
  }

  private filterAppVersion(device: DeviceInterface): string | null {
    return device ? device.appVersion : null;
  }

  update(path: string, data: any): Observable<any> {
    return this.http.put<BoResponse>(`${API_URL}/${environment.configItemsPath}/${path}`, data).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  /**
   * get current device user names
   */
  getCurrentDeviceUserNames(): Observable<Array<LookupValue>> {
    return this.http.get<BoResponse>(`${API_URL}/${environment.configItemsPath}/lookup-users`).pipe(
      map(res => {
        if (res.success) {
          return res.data;
        }
        return throwError(res.message);
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }

  getPaymentMethodTypes(): string[] {
    return environment.paymentMethodTypes;
  }
}
