/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "./image-enlarge-dialog.component";
import * as i3 from "@angular/platform-browser";
var styles_ImageEnlargeDialogComponent = [];
var RenderType_ImageEnlargeDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImageEnlargeDialogComponent, data: {} });
export { RenderType_ImageEnlargeDialogComponent as RenderType_ImageEnlargeDialogComponent };
export function View_ImageEnlargeDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["class", "fill-enlarge"], ["loading", "lazy"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageResource; var currVal_1 = ((_co.image == null) ? null : _co.image.name); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_ImageEnlargeDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-enlarge-dialog", [], null, null, null, View_ImageEnlargeDialogComponent_0, RenderType_ImageEnlargeDialogComponent)), i0.ɵdid(1, 49152, null, 0, i2.ImageEnlargeDialogComponent, [i1.MAT_DIALOG_DATA, i3.DomSanitizer], null, null)], null, null); }
var ImageEnlargeDialogComponentNgFactory = i0.ɵccf("app-image-enlarge-dialog", i2.ImageEnlargeDialogComponent, View_ImageEnlargeDialogComponent_Host_0, {}, {}, []);
export { ImageEnlargeDialogComponentNgFactory as ImageEnlargeDialogComponentNgFactory };
