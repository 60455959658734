import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class SessionService {
    constructor(router) {
        this.router = router;
        this.currentRoute = '/';
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((navEnd) => {
            if (navEnd.urlAfterRedirects !== '/'
                && navEnd.urlAfterRedirects.startsWith('/dashboard')
                && navEnd.urlAfterRedirects.startsWith('/empty')) {
                this.currentRoute = navEnd.urlAfterRedirects;
            }
        });
    }
    reloadPage() {
        if (this.currentRoute !== '/'
            && this.currentRoute.startsWith('/dashboard')
            && this.currentRoute.startsWith('/empty')) {
            this.router.navigate(environment.reloadRoute, { skipLocationChange: true });
            this.router.navigate([this.currentRoute], { skipLocationChange: true });
        }
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.Router)); }, token: SessionService, providedIn: "root" });
