import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SearchService {
    constructor() {
        this.searchSubject = new BehaviorSubject('');
        this.search$ = this.searchSubject.asObservable();
    }
    onSearch(value) {
        this.searchSubject.next(value);
    }
    onClear() {
        if (this.searchSubject.value) {
            this.searchSubject.next('');
        }
    }
}
SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(); }, token: SearchService, providedIn: "root" });
