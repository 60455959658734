import * as tslib_1 from "tslib";
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from 'src/environments/environment';
export class AlertsService {
    constructor(snackBar, translateService) {
        this.snackBar = snackBar;
        this.translateService = translateService;
    }
    error(messageToken) {
        this.alert(messageToken, 'error-alert');
    }
    success(messageToken) {
        this.alert(messageToken, 'success-alert');
    }
    alert(messageToken, colorClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const close = yield this.translateService.get(_('common.close')).toPromise();
            const message = yield this.translateService.get(messageToken).toPromise();
            this.snackBar.open(message, close, {
                panelClass: [colorClass],
                duration: environment.snackbarDuration,
            });
        });
    }
}
