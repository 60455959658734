import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { GraphAdditionalFilter, GraphFilterData } from '@app/dashboard/interfaces/graph.interface';

@Component({
  selector: 'app-graph-filter',
  templateUrl: './graph-filter.component.html',
  styleUrls: ['./graph-filter.component.scss'],
})
export class GraphFilterComponent implements OnInit {
  @Input() graphFilter: GraphAdditionalFilter;
  @Input() graphId: string;
  @Output() valueChanged = new EventEmitter<any>();

  filterData: GraphFilterData;
  formControl: FormControl;

  ngOnInit() {
    this.initFormControl();
  }

  private initFormControl() {
    this.formControl = new FormControl(this.graphFilter.id);
    this.formControl.setValue(this.graphFilter.defaultValue);
  }

  onSelectChanged(event: MatSelectChange) {
    this.valueChanged.emit(this.formControl.value);
  }
}
