import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventLogsService } from '@app/core/services/event-logs/event-logs.service';
import { WidgetDisplayStyle, WidgetState } from '@app/dashboard/interfaces/widget.interface';
import { WidgetService } from '@app/dashboard/services/widget/widget.service';
import { BasePageComponent } from '@app/shared/bases/base-page.component';
import { formatDate } from '@app/shared/helpers/date';
import { formatNumberDefault } from '@app/shared/helpers/helpers';
import { EventLog } from '@app/shared/models/event-log.model';
import { DevicesService } from '@app/shared/services/devices/devices.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface InfoWidgetData {
  title: string;
  text: string | string[];
  displayStyle: WidgetDisplayStyle;
  state: WidgetState;
  showPercentage: boolean;
  percentage?: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BasePageComponent implements OnInit, OnDestroy {
  eventLog$ = new BehaviorSubject<EventLog>(null);
  refreshGraphs = new Subject<boolean>();
  now: Date;
  infoWidget: any = {};

  get nowString(): string {
    return this.translate.instant(_('dashboard.stats_now'), { now: formatDate({ date: this.now }) });
  }

  get nowStringCss(): string {
    return environment.appearances.pageTitle === 'header' ? 'col-12 mb-4 text-center' : 'col-12 mb-4';
  }

  constructor(
    private translate: TranslateService,
    private devicesService: DevicesService,
    private eventLogsService: EventLogsService,
    private widgetService: WidgetService
  ) {
    super();
  }

  ngOnInit() {
    this.now = new Date();
    this.onLangChange();
    this.refresh();
  }

  private refresh() {
    this.initWidgetData();
    this.getLastEventLog();
  }

  private onLangChange() {
    this.translate.onLangChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.refresh();
    });
  }

  private getLastEventLog() {
    const identityId = this.devicesService.identityId;
    this.eventLogsService.getLastEvent(identityId).then(res => {
      if (res) {
        this.eventLog$.next(res);
        this.getDataForInfoWidgets(res);
      }
    });
  }

  private initWidgetData() {
    this.infoWidget = {
      cashBalance: {
        title: _('dashboard.balance_in_hand'),
        displayStyle: 'DEFAULT',
        state: 'LOADING',
      } as InfoWidgetData,
      cashBalanceForeign: {
        title: _('dashboard.balance_in_hand_foreign'),
        displayStyle: 'INLINE',
        state: 'LOADING',
      } as InfoWidgetData,
      totalRevenue: {
        title: _('dashboard.total_revenue'),
        displayStyle: 'DEFAULT',
        state: 'LOADING',
      } as InfoWidgetData,
      numberOfReceipts: {
        title: _('dashboard.number_receipts'),
        displayStyle: 'DEFAULT',
        state: 'LOADING',
      } as InfoWidgetData,
      avgPerReceipt: {
        title: _('dashboard.average_receipt'),
        displayStyle: 'DEFAULT',
        state: 'LOADING',
      } as InfoWidgetData,
    };
  }

  private getDataForInfoWidgets(eventLog: EventLog) {
    this.widgetService
      .getStatInfo(eventLog)
      .toPromise()
      .then(res => {
        if (res) {
          // set data for total cash balance widget
          if (res.totalCashBalance) {
            const balanceInDefaultCurrency = res.totalCashBalance.find(
              item => item.currency === environment.defaultCurrency
            );
            if (balanceInDefaultCurrency != null) {
              this.infoWidget.cashBalance.text = this.formatCurrencySpecial(
                balanceInDefaultCurrency.amount,
                balanceInDefaultCurrency.currency
              );
            } else {
              this.infoWidget.cashBalance.text = this.formatCurrencySpecial(0, environment.defaultCurrency);
            }
            this.infoWidget.cashBalance.state = 'LOADED';

            // set data for total cash balance in foreign currencies widget
            const balanceInForeign = res.totalCashBalance.filter(item => item.currency !== environment.defaultCurrency);
            if (balanceInForeign && balanceInForeign.length) {
              let balanceInForeignRes: string[] = [];
              balanceInForeign.forEach(item => {
                balanceInForeignRes.push(this.formatCurrencySpecial(item.amount, item.currency));
              });
              this.infoWidget.cashBalanceForeign.text = balanceInForeignRes;
              this.infoWidget.cashBalanceForeign.state = 'LOADED';
            } else {
              this.infoWidget.cashBalanceForeign.state = 'ERROR';
            }
          }

          // set data for total revenue widget
          if (res.totalRevenue != null) {
            this.infoWidget.totalRevenue.text = this.formatCurrencySpecial(
              res.totalRevenue.amount,
              environment.defaultCurrency
            );
            this.infoWidget.totalRevenue.percentage = res.totalRevenue.percentage;
            this.infoWidget.totalRevenue.state = 'LOADED';
          } else {
            this.infoWidget.totalRevenue.state = 'ERROR';
          }

          // set data for number of receipts widget
          if (res.numberOfReceipts != null) {
            this.infoWidget.numberOfReceipts.text = formatNumberDefault(
              res.numberOfReceipts.amount,
              this.translate.currentLang,
              '1.0-0'
            );
            this.infoWidget.numberOfReceipts.percentage = res.numberOfReceipts.percentage;
            this.infoWidget.numberOfReceipts.state = 'LOADED';
          } else {
            this.infoWidget.numberOfReceipts.state = 'ERROR';
          }

          // set data for average per receipt widget
          if (res.averagePerReceipt != null) {
            this.infoWidget.avgPerReceipt.text = this.formatCurrencySpecial(
              res.averagePerReceipt.amount,
              environment.defaultCurrency
            );
            this.infoWidget.avgPerReceipt.percentage = res.averagePerReceipt.percentage;
            this.infoWidget.avgPerReceipt.state = 'LOADED';
          } else {
            this.infoWidget.avgPerReceipt.state = 'ERROR';
          }
        } // end of if (res)
      });
  }

  /**
   * Provide unique formatting with separate wrapper for decimal point
   * @param value
   */
  private formatCurrencySpecial(value: number, currencyCode: string): string {
    const formatted = formatNumberDefault(value, this.translate.currentLang);
    const decimalSeparator = getLocaleNumberSymbol(this.translate.currentLang, NumberSymbol.CurrencyDecimal);
    const splitted = formatted.split(decimalSeparator);
    const decimalPart =
      splitted.length > 1 ? decimalSeparator + '<span class="decimal">' + splitted[1] + '</span>' : '';
    const symbol =
      currencyCode == null || typeof currencyCode === 'undefined'
        ? ''
        : this.translate.instant(`currency.${currencyCode}.symbol`);
    return `${splitted[0]}${decimalPart} ${symbol}`;
  }

  onGraphsUpdated() {
    this.refreshGraphs.next(true);
  }
}
