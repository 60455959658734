import { formatNumber } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { SearchAttemptFields } from '../components/search-table/search-table.component';
import { formatDate } from './date';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

/**
 * Returns true if the given single or array of FormGroups are valid, or false otherwise.
 * @param forms array of FormGroup to be checked.
 */
export function isFormValid(...forms: FormGroup[]): boolean {
  for (const form of forms) {
    if (!form.valid) {
      return false;
    }
  }
  return true;
}

/**
 * Returns true if the given obj is empty (as an Object or array)
 * @param obj Object or array.
 */
export function isEmpty(obj: any): boolean {
  if (obj.constructor === Object) {
    return Object.keys(obj).length === 0;
  } else if (Array.isArray(obj)) {
    return !obj.length;
  }
  return false;
}

/**
 * Returns true if the given `object1` has the exact attribute values with `object2`, or false otherwise.
 */
export function isEquals(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

/**
 * Returns true if the given `object1` has the exact attribute values with `object2` and their values are not null or
 * undefined. Returns false otherwise.
 */
export function isEqualsAndSet(object1: any, object2: any) {
  return Object.keys(object1).every(
    key =>
      object1[key] === object2[key] &&
      object1[key] != null &&
      object1[key] != '' &&
      typeof object1[key] !== 'undefined' &&
      object2[key] != null &&
      object2[key] != '' &&
      typeof object2[key] !== 'undefined'
  );
}

/**
 * Filter data (or do "search") on frontend side.
 * TODO: to filter 'DATE' type of field.
 *
 * @param data array of objects that being received from backend
 * @param filterFields SearchAttemptFields that has been set through SearchService, for example on SearchTableComponent.
 */
export function filterSearchResult(data: any, filterFields?: SearchAttemptFields): any {
  if (filterFields) {
    return data.filter(item => {
      for (const filterField of filterFields.fields) {
        if (filterField.value) {
          if (!item.hasOwnProperty(filterField.field.key)) {
            return false;
          }

          if (filterField.field.type === 'TEXT' || filterField.field.type === 'DROPDOWN') {
            if (item[filterField.field.key].toLowerCase().indexOf(filterField.value.toLowerCase()) === -1) {
              return false;
            }
          } else if (filterField.field.type === 'CHECKBOX') {
            if (item[filterField.field.key] !== filterField.value) {
              return false;
            }
          }
        }
      }

      return true;
    });
  }
  return data;
}

/**
 * Return HttpParams from the given SearchAttemptFields
 * @param filterFields SearchAttemptFields containing search filters
 * @param params HttpParams, if null then new instance will be created
 */
export function buildHttpParams(filterFields: SearchAttemptFields, params?: HttpParams) {
  if (params == null) {
    params = new HttpParams();
  }
  if (filterFields && filterFields.fields) {
    filterFields.fields.forEach(field => {
      if (field.value) {
        params = params.set(field.field.key, field.value);
      }
    });
  }
  return params;
}

/**
 * Convert to hex from byte64
 *
 * @param base64Value as byte64 string
 */
export function hexFromBase64(base64Value) {
  return window
    .atob(base64Value)
    .split('')
    .map(function (aChar) {
      return ('0' + aChar.charCodeAt(0).toString(16)).slice(-2);
    })
    .join('');
}

/**
 * Convert to byte64 string from hex
 *
 * @param hexValue as hex string
 */
export function hexToBase64(hexValue) {
  return btoa(
    String.fromCharCode.apply(
      null,
      hexValue
        .replace(/[\r\n]/g, '')
        .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
        .replace(/ +$/, '')
        .split(' ')
    )
  );
}

/**
 * Formatting value base on current language
 *
 * @param val string, value to be formatted
 * @param currentLang string, current languange code eg: "cz", "en"
 */
export function decimalFormat(val: string, currentLang: string): string {
  if (val) {
    if (currentLang === 'cz') {
      if (val.includes(environment.defaultDecimalFormat)) {
        return val.replace(environment.defaultDecimalFormat, environment.czDecimalFormat);
      }
    } else {
      if (val.includes(environment.czDecimalFormat)) {
        return val.replace(environment.czDecimalFormat, environment.defaultDecimalFormat);
      }
    }
  }
  return val;
}

/**
 * Replace comma to point
 *
 * @param val string, value to be replace
 */
export function replaceDecimal(val: string): string {
  if (val) {
    const value = val.toString();
    if (value.includes(environment.czDecimalFormat)) {
      return value.replace(environment.czDecimalFormat, environment.defaultDecimalFormat);
    }
  }
  return val;
}

/**
 * Convert string to payment method translatable text
 * @param val string, value to be convert
 */
export function translatablePaymentMethodType(val: string): string {
  return `payment_method.type.${val.trim().replace(/\s+/g, '_').toLowerCase()}`;
}

/**
 * Format Currency
 * @param val number, value to be formatted
 */
export function formatCurrencyDefault(val: number, lang: string): string {
  return `${formatNumberDefault(val, lang)} ${environment.defaultCurrencySymbol}`;
}

/**
 * Format Number base on language
 * @param val number to be formatted
 * @param lang current locale code
 */
export function formatNumberDefault(val: number, locale: string, digitsInfo: string = '1.2-2'): string {
  return formatNumber(val, locale, digitsInfo);
}

/**
 * Check if the the given value is undefined or null, then return defaultVal.
 * Returns the same given value if it is defined.
 * @param value variable to be checked for undefined or null
 * @param defaultVal value to be returned if the given value is undefined or null
 */
export function defaultValue(value: any, defaultVal: any) {
  if (typeof value === 'undefined' || value === null) {
    return defaultVal;
  }
  return value;
}

/**
 * Returns formatted file name to be used for all exports
 * @param filename
 */
export function getFormattedFileName(filename: string): string {
  const name = filename ? filename.replace(/\//g, '-') : '';
  const date = formatDate({ date: Date(), format: 'y-MM-dd HH-mm-ss' });
  return `${name} ${date}`;
}

export function randomizeArray(input: any[]): any[] {
  // Create a copy of the original array to be randomized
  let shuffle = [...input];

  // Defining function returning random value from i to N
  const getRandomValue = (i: any, N: number) => Math.floor(Math.random() * (N - i) + i);

  // Shuffle a pair of two elements at random position j
  shuffle.forEach((elem, i, arr, j = getRandomValue(i, arr.length)) => ([arr[i], arr[j]] = [arr[j], arr[i]]));

  return shuffle;
}

/**
 * Returns slugified string version from the given args
 */
export function slugify(...args: (string | number)[]): string {
  const value = args.join(' ');

  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '_'); // separator
}

/**
 * Generate all possible mathematical combinations from the given array of arrays
 */
export function generateCombination(...args: string[][]): string[][] {
  const r = [];
  const max = args.length - 1;

  const helper = (arr: string[], i: number) => {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i == max) r.push(a);
      else helper(a, i + 1);
    }
  };

  helper([], 0);
  return r;
}
