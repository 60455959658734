import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as ResizeSensor from 'resize-sensor';

export interface ResizedEvent {
  element: ElementRef;
  newWidth: number;
  newHeight: number;
  oldWidth: number;
  oldHeight: number;
}

@Directive({
  selector: '[appResized]',
})
export class ResizedDirective implements OnInit, OnDestroy {
  @Output() readonly resized = new EventEmitter<ResizedEvent>();

  private oldWidth: number;
  private oldHeight: number;

  private resizeSensor: ResizeSensor;

  constructor(private readonly element: ElementRef) {}

  ngOnInit(): void {
    // only initialize resize watching if sensor is availablei
    if (ResizeSensor) {
      this.resizeSensor = new ResizeSensor(this.element.nativeElement, () =>
        this.onResized()
      );
    }
  }

  ngOnDestroy(): void {
    if (this.resizeSensor) {
      this.resizeSensor.detach();
    }
  }

  private onResized(): void {
    const newWidth = this.element.nativeElement.clientWidth;
    const newHeight = this.element.nativeElement.clientHeight;

    if (newWidth === this.oldWidth && newHeight === this.oldHeight) {
      return;
    }

    const event: ResizedEvent = {
      element: this.element,
      newWidth,
      newHeight,
      oldWidth: this.oldWidth,
      oldHeight: this.oldHeight,
    };

    this.oldWidth = this.element.nativeElement.clientWidth;
    this.oldHeight = this.element.nativeElement.clientHeight;

    this.resized.emit(event);
  }
}
