import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventLog } from '@app/shared/models/event-log.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const API_URL = environment.apiUrl;
export class EventLogsService {
    constructor(http) {
        this.http = http;
        this._eventLogs = [];
    }
    getEventLogs(identityId) {
        return this.http.get(`${API_URL}/${environment.eventLogsPath}/${identityId}`)
            .pipe(map(res => {
            if (res.success) {
                this._eventLogs = res.data;
                return res.data;
            }
            console.error(res.message);
            return throwError(res.message);
        }), catchError(error => {
            return of([]);
        }));
    }
    getLastEvent(identityId) {
        return this.http.get(`${API_URL}/${environment.eventLogsPath}/${identityId}/last`)
            .pipe(map(res => {
            if (res.success) {
                return new EventLog().deserialize(res.data);
            }
            console.error(res.message);
            return throwError(res.message);
        }), catchError(error => {
            return of(null);
        })).toPromise();
    }
}
EventLogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventLogsService_Factory() { return new EventLogsService(i0.ɵɵinject(i1.HttpClient)); }, token: EventLogsService, providedIn: "root" });
