import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Icon } from '@app/shared/models/catalog-item.model';
import { ImageGalleryModeType, ImageGallerySelectionType } from '../image-gallery/image-gallery.component';

export interface ImageGalleryPickerDialogData {
  selectedImages: Icon[];
  selection: ImageGallerySelectionType;
}

@Component({
  selector: 'app-image-gallery-picker-dialog',
  templateUrl: './image-gallery-picker-dialog.component.html',
})
export class ImageGalleryPickerDialogComponent {
  @Input() mode: ImageGalleryModeType = 'normal';

  selectedImages: Icon[] = [];
  selection: ImageGallerySelectionType = 'single';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageGalleryPickerDialogData,
    private dialogRef: MatDialogRef<ImageGalleryPickerDialogComponent>
  ) {
    this.selectedImages = data.selectedImages;
    this.selection = data.selection;
  }

  onSelected(images: Icon[]) {
    this.selectedImages = images;
  }

  onDismiss() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.selectedImages);
  }
}
