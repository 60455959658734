import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  MatCardModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
} from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DevicesComponent } from './components/devices/devices.component';
import { EmptyComponent } from './components/empty/empty.component';
import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CoreRoutingModule } from './core-routing.module';
import { CoreComponent } from './core.component';
import { LoggingService } from './services/logging/logging.service';

@NgModule({
  declarations: [CoreComponent, NavbarComponent, LeftSidebarComponent, DevicesComponent, EmptyComponent],
  exports: [CoreComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    CdkTreeModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    MatExpansionModule,
    MatMenuModule,
    MatSelectModule,
    MatTreeModule,
    SharedModule.forRoot(),
    TranslateModule.forChild(),
    DashboardModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [LoggingService],
    };
  }
}
