import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from 'src/environments/environment';

export interface MenuItem {
  /** unique identifier for this menu item */
  id?: string;

  /** displayed label of this menu */
  label: string;

  /** mandatory only to menu items */
  link?: string;

  /** mat-icon string for this menu */
  icon?: string;

  /** mandatory only for nested menu container */
  items?: MenuItem[];

  /** mandatory only for nested menu container (where `items` are not empty) */
  prefix?: string;

  /** set true if this is just a heading title */
  asHeading?: boolean;

  /** CSS classes for this menu (ex: to be used to set 'active' class) */
  cssClasses?: string;

  /** show/hide this menu */
  hidden?: boolean;
}

export type Menu = MenuItem[];

// Menus
const menuDashboard: MenuItem = {
  id: 'dashboard',
  label: _('title.dashboard'),
  link: '/dashboard',
  icon: 'dashboard',
};

// Statistic
const menuStatistic: MenuItem = {
  id: 'statistics',
  label: _('title.statistics'),
  icon: 'bar_chart',
  prefix: '/statistics',
  items: [
    {
      id: 'statistics_receipts',
      label: _('title.receipts'),
      icon: 'arrow_right',
      link: '/statistics/receipts',
    },
    {
      id: 'statistics_shifts',
      label: _('title.shift_reports'),
      icon: 'arrow_right',
      link: '/statistics/shifts',
    },
    {
      id: 'statistics_proforma',
      label: _('title.proforma_invoices'),
      icon: 'arrow_right',
      link: '/statistics/proforma-invoices',
    },
    {
      id: 'statistics_price_change',
      label: _('title.price_change'),
      icon: 'arrow_right',
      link: '/statistics/price-change',
    },
  ],
};

// Partners
const menuPartners: MenuItem = {
  id: 'partners',
  label: _('title.partners'),
  icon: 'contacts',
  prefix: '/partners',
  items: [
    {
      id: 'partners_suppliers',
      label: _('title.suppliers'),
      icon: 'arrow_right',
      link: '/partners/suppliers',
    },
    {
      id: 'partners_clients',
      label: _('title.clients'),
      icon: 'arrow_right',
      link: '/partners/clients',
    },
  ],
};

// Warehouse
const menuWarehouse: MenuItem = {
  id: 'warehouse',
  label: _('title.warehouse'),
  icon: 'shopping_cart',
  prefix: '/warehouse',
  items: [
    {
      label: _('menu.subheading.products'),
      asHeading: true,
    },
    {
      id: 'warehouse_products',
      label: _('title.products'),
      icon: 'arrow_right',
      link: '/warehouse/products',
    },
    {
      id: 'warehouse_product_groups',
      label: _('title.product_groups'),
      icon: 'arrow_right',
      link: '/warehouse/product-groups',
    },
    {
      id: 'warehouse_warehouses',
      label: _('title.warehouses'),
      icon: 'arrow_right',
      link: '/warehouse/warehouses',
    },
    {
      id: 'warehouse_measuring_units',
      label: _('title.measuring_units'),
      icon: 'arrow_right',
      link: '/warehouse/measuring-units',
    },
    {
      id: 'warehouse_returnable_packaging',
      label: _('title.returnable_packaging'),
      icon: 'arrow_right',
      link: '/warehouse/returnable-packaging',
      hidden: true,
    },
    {
      label: _('menu.subheading.operations'),
      asHeading: true,
    },
    {
      id: 'warehouse_incoming_receipts',
      label: _('title.incoming_receipts'),
      icon: 'arrow_right',
      link: '/warehouse/incoming-receipts',
    },
    {
      id: 'warehouse_inventory',
      label: 'title.inventory',
      icon: 'arrow_right',
      link: '/warehouse/inventory',
    },
    {
      id: 'warehouse_return_to_supplier',
      label: _('title.return_to_supplier'),
      icon: 'arrow_right',
      link: '/warehouse/return-to-supplier',
    },
    {
      id: 'warehouse_write_off',
      label: _('title.write_off'),
      icon: 'arrow_right',
      link: '/warehouse/write-off',
    },
    {
      label: _('menu.subheading.actions'),
      asHeading: true,
    },
    {
      id: 'warehouse_actions',
      label: _('title.actions'),
      icon: 'arrow_right',
      link: '/warehouse/actions',
    },
  ],
};

// Settings
export const menuSettings: MenuItem = {
  id: 'settings',
  label: _('title.settings'),
  icon: 'settings',
  prefix: '/settings',
  items: [
    {
      id: 'settings_company',
      label: _('title.company'),
      icon: 'arrow_right',
      link: '/settings/company',
    },
    {
      id: 'settings_users',
      label: _('title.users'),
      icon: 'arrow_right',
      link: '/settings/users',
    },
    {
      id: 'settings_currencies',
      label: _('title.currencies'),
      icon: 'arrow_right',
      link: '/settings/currencies',
      hidden: !environment.currencySettingEnabled,
    },
    {
      id: 'settings_payment_methods',
      label: _('title.payment_methods'),
      icon: 'arrow_right',
      link: '/settings/payment-methods',
    },
    {
      id: 'settings_vat_groups',
      label: _('title.vat_groups'),
      icon: 'arrow_right',
      link: '/settings/vat-groups',
    },
    {
      id: 'settings_others',
      label: _('title.others'),
      icon: 'arrow_right',
      link: '/settings/others',
    },
  ],
};

// tslint:disable-next-line:variable-name
const _menu = [];

_menu.push(menuDashboard);
_menu.push(menuStatistic);
_menu.push(menuWarehouse);
_menu.push(menuPartners);
_menu.push(menuSettings);

export const menu: Menu = _menu;
