import { Component, Input } from '@angular/core';
import { WidgetDisplayStyle, WidgetState } from '@app/dashboard/interfaces/widget.interface';
import { BaseComponent } from '@app/shared/bases/base.component';
import { formatNumberDefault } from '@app/shared/helpers/helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-widget',
  templateUrl: './info-widget.component.html',
  styleUrls: ['./info-widget.component.scss'],
})
export class InfoWidgetComponent extends BaseComponent {
  @Input() title: string;
  @Input() percentage: number;
  @Input() showPercentage = false;
  @Input() text: string | string[];
  @Input() displayStyle: WidgetDisplayStyle = 'DEFAULT';
  @Input() state: WidgetState = 'LOADING';

  get widgetClasses(): string {
    return `info-widget info-widget-${this.displayStyle.toLowerCase()} ${
      this.showPercentage ? 'with-percentage' : 'without-percentage'
    } dashboard-widget full-height`;
  }

  get percentageIconClasses(): string {
    if (this.showPercentage && this.percentage != null) {
      if (this.percentage > 0) return 'arrow-icon increased';
      else if (this.percentage < 0) return 'arrow-icon decreased';
    }
    return 'arrow-icon zero';
  }

  get percentageValue(): string {
    if (this.percentage != null) {
      let prefix = '';
      if (this.percentage > 0) {
        prefix = '+';
      }
      return `${prefix}${formatNumberDefault(this.percentage, this.translate.currentLang)}%`;
    }
    return '';
  }

  get displayedText(): string {
    if (this.text instanceof Array) {
      let result = '';
      this.text.forEach(item => (result += `<span class="info-text-item">${item}</span>`));
      return result;
    }
    return this.text.toString();
  }

  constructor(private translate: TranslateService) {
    super();
  }
}
