<div class="row">
  <div class="col-lg-2 col-md-12 category-tree">
    <app-icon-category-tree
      (categoryFiltered)="filterByCategory($event)"
      [currentNode]="currentCategory"
      [isSearched]="isSearched"
    ></app-icon-category-tree>
  </div>

  <!-- gallery panel -->
  <div [class]="galleryPanelClasses">
    <mat-card>
      <div class="row">
        <!-- Search -->
        <div class="col-12 gallery-search-wrapper">
          <form [formGroup]="searchForm" (submit)="onSearch()" class="form-inline">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'common.search' | translate }}</mat-label>
              <input
                matInput
                placeholder="{{ 'gallery.search_by_name' | translate }}"
                maxlength="128"
                formControlName="search"
              />
            </mat-form-field>

            <div class="text-right">
              <button class="mr-3" type="reset" mat-button color="warn" aria-label="reset" (click)="onResetSearch()">
                <mat-icon>refresh</mat-icon>
                {{ 'common.clear' | translate }}
              </button>
              <button
                type="submit"
                mat-raised-button
                color="primary"
                aria-label="search"
                [disabled]="searchForm.value.search === ''"
              >
                <mat-icon>search</mat-icon>
                {{ 'common.search' | translate }}
              </button>
            </div>
          </form>
        </div>

        <!-- Images -->
        <div
          class="gallery-images col-12 pt-2"
          [ngClass]="{ selecting: selectedImages.length }"
          (click)="onClickGalleryImagesPanel($event)"
        >
          <div class="d-flex flex-row flex-wrap">
            <ng-container *ngFor="let image of images; let index = index">
              <div *ngIf="image.folder != null; else galleryImage" class="gallery-image mb-3 mr-3">
                <div class="folder" (click)="filterByCategory(image.folder)">
                  <mat-icon>folder</mat-icon>
                  <small>{{ image.folder.name }}</small>
                </div>
              </div>
              <ng-template #galleryImage>
                <div
                  class="gallery-image mb-3 mr-3"
                  (click)="onImageClick(image)"
                  [ngClass]="{ active: image.meta?.active }"
                >
                  <img
                    loading="lazy"
                    class="object-fit-cover"
                    [src]="getImage(index)"
                    [alt]="image?.name"
                    (error)="getDefaultImage($event, index)"
                  />
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>

        <!-- Pagination -->
        <div class="row">
          <div *ngIf="images && images.length" class="col-12 d-flex justify-content-start align-items-center">
            <label class="page-size-label">{{ 'paginator.item_per_pages' | translate }}</label>
            <mat-select [(value)]="selectedSize" (valueChange)="changePagingSize()" class="page-size-select ml-2">
              <mat-option *ngFor="let size of sizes" [value]="size">{{ size }}</mat-option>
            </mat-select>
            <span class="page-size-label mx-4">
              {{
                'paginator.get_range_label'
                  | translate
                    : {
                        start: numberOfElements ? page * selectedSize + 1 : 0,
                        end: numberOfElements ? page * selectedSize + numberOfElements : 0,
                        length: totalElements
                      }
              }}
            </span>
            <button type="button" mat-icon-button (click)="showFirst()" [disabled]="this.page == 0">
              <mat-icon>skip_previous</mat-icon>
            </button>
            <button type="button" mat-icon-button (click)="showPrev()" [disabled]="this.page == 0">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <button type="button" mat-icon-button (click)="showNext()" [disabled]="this.page >= this.totalPage - 1">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button type="button" mat-icon-button (click)="showLast()" [disabled]="this.page >= this.totalPage - 1">
              <mat-icon>skip_next</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <!-- selected image panel -->
  <div class="selected-image-panel col-lg-4 col-md-12" *ngIf="detailPanelShowing">
    <app-image-gallery-detail
      [readonly]="mode === 'normal'"
      [image]="selectedImages[0]"
      (closePanel)="onCloseDetailPanel()"
    >
    </app-image-gallery-detail>
  </div>
</div>
