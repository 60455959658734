import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/bases/base.component';
import { ConfigService } from '@app/shared/services/config/config.service';
import { ConfigItemResponse } from '@app/shared/services/config/response';

@Component({
  selector: 'app-fiscalization-status',
  templateUrl: './fiscalization-status.component.html',
  styleUrls: ['./fiscalization-status.component.scss'],
})
export class FiscalizationStatusComponent extends BaseComponent implements OnInit {
  fiscalizationOn = false;

  constructor(private configService: ConfigService) {
    super();
  }

  ngOnInit() {
    this.loadFiscalizationStatus();
  }

  private loadFiscalizationStatus() {
    this.configService
      .getBySections(['fiscalization'])
      .toPromise()
      .then((configItem: ConfigItemResponse) => {
        this.fiscalizationOn =
          configItem && configItem.fiscalization ? configItem.fiscalization.fiscalizationOn : false;
      });
  }
}
