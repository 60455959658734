import { HttpParams } from '@angular/common/http';
import { hexFromBase64 } from '@app/shared/helpers/helpers';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/alerts/alerts.service";
const API_URL = environment.apiUrl;
export var CATALOG_TYPE;
(function (CATALOG_TYPE) {
    CATALOG_TYPE["GROUP"] = "1";
    CATALOG_TYPE["PRODUCT"] = "2";
})(CATALOG_TYPE || (CATALOG_TYPE = {}));
export class CatalogItemsService {
    constructor(http, alertService) {
        this.http = http;
        this.alertService = alertService;
        this.defaultGroup = environment.defaultGroupValue;
    }
    /**
     * Get product groups from catalogItems collection.
     * @param option TableLoadOption option to fetch the data
     * @param includeRoot should `isRoot` criteria on catalogItems is needed.
     *     includeRoot equals null means that "Default Group" should not be included
     *     on returned data (ex: on Warehouse - Product Groups page).
     *     includeRoot equals true (or false) means `isRoot` query criteria on
     *     catalogItems will be used.
     */
    getGroups(option, includeRoot) {
        return this.getFromCatalogItems(`${API_URL}/${environment.productGroupsPath}`, option, includeRoot);
    }
    getGroupById(id) {
        return this.http.get(`${API_URL}/${environment.productGroupsPath}/${hexFromBase64(id)}`).pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of([]);
        }));
    }
    getReturnablePackagingGroup() {
        return this.http.get(`${API_URL}/${environment.productGroupsPath}/returnable`).pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of([]);
        }));
    }
    deleteGroup(group) {
        return this.http
            .post(`${API_URL}/${environment.productGroupsPath}/${hexFromBase64(group._id)}`, {
            group_id: hexFromBase64(group._id),
        })
            .pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(error => {
            console.error(error);
            return of(null);
        }));
    }
    getProducts(option, ingredientOnly) {
        return this.getFromCatalogItems(`${API_URL}/${environment.productItemsPath}`, option, null, ingredientOnly);
    }
    getStockTrackedProducts(option, ingredientOnly) {
        return this.getFromCatalogItems(`${API_URL}/${environment.productItemsStockTrackedPath}`, option, null, ingredientOnly);
    }
    /**
     * Get all products (unpaginated), using the given filter.
     * @param filter filtering pattern to search for products and product groups.
     * - for products: "product_name:<value>;group_id:(?<value>.*);measuring_id:(?<value>.*)"
     * - for product groups: "name:(?<value>.*)"
     */
    getAllProducts(filter) {
        if (filter && filter.length >= 3) {
            const params = new HttpParams().set('filter', filter);
            return this.http
                .get(`${API_URL}/${environment.productItemsPath}/all`, {
                params,
            })
                .pipe(map(res => {
                if (res.success) {
                    return res.data;
                }
                return throwError(res.message);
            }), catchError(() => {
                return of([]);
            }));
        }
        return of([]);
    }
    getProductById(id) {
        return this.http.get(`${API_URL}/${environment.productItemsPath}/${hexFromBase64(id)}`).pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of([]);
        }));
    }
    getProductVariations(parentId) {
        return this.http
            .get(`${API_URL}/${environment.productItemsPath}/variations/${hexFromBase64(parentId)}`)
            .pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of([]);
        }));
    }
    isAnyProductWithMeasuringUnit(measuringUnitId) {
        return this.http.get(`${API_URL}/${environment.productItemsPath}/unit/${measuringUnitId}`).pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of(0);
        }));
    }
    deleteProduct(product) {
        return this.http
            .post(`${API_URL}/${environment.productItemsPath}/${hexFromBase64(product._id)}`, {
            group_id: hexFromBase64(product.parent._id),
        })
            .pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(error => {
            console.error(error);
            return of(null);
        }));
    }
    getFromCatalogItems(path, option, includeRoot, ingredientOnly) {
        let params = new HttpParams().set('page', option.pageIndex.toString()).set('size', option.pageSize.toString());
        if (includeRoot != null) {
            params = params.set('includeRoot', includeRoot.toString());
        }
        if (ingredientOnly != null) {
            params = params.set('ingredientOnly', `${ingredientOnly}`);
        }
        if (option.filter) {
            if (option.filter.hasOwnProperty('pattern')) {
                params = params.set('filter', option.filter.pattern);
            }
        }
        return this.http.get(path, { params }).pipe(map(res => {
            if (res.success) {
                return res;
            }
            console.error(res.message);
            return throwError(res.message);
        }), catchError(error => {
            return of([]);
        }));
    }
    saveOrUpdateProduct(data, edit) {
        let api;
        if (edit) {
            api = this.http.put(`${API_URL}/${environment.productItemsPath}`, data);
        }
        else {
            api = this.http.post(`${API_URL}/${environment.productItemsPath}`, data);
        }
        return api.pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            console.error(res.message);
            return throwError(res.message);
        }), catchError(error => {
            this.alertService.error(error);
            return throwError(error);
        }));
    }
    saveOrUpdateProductGroup(data, edit) {
        let api;
        const url = `${API_URL}/${environment.productGroupsPath}`;
        if (edit) {
            api = this.http.put(url, data);
        }
        else {
            api = this.http.post(url, data);
        }
        return api.pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(error => {
            this.alertService.error(error);
            return throwError(error);
        }));
    }
    saveDefaultReturnablePackagingGroup(data) {
        const params = new HttpParams()
            .set('hexColorCode', data.hexColorCode)
            .set('enable', data.enable ? 'true' : 'false');
        return this.http
            .post(`${API_URL}/${environment.returnablePackagingBasePath}/default-group`, null, { params })
            .pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(error => {
            this.alertService.error(error);
            return throwError(error);
        }));
    }
    saveOrUpdateReturnablePackaging(data, edit = false) {
        let api;
        const url = `${API_URL}/${environment.returnablePackagingBasePath}`;
        if (edit) {
            api = this.http.put(`${url}/${hexFromBase64(data._id)}`, data);
        }
        else {
            api = this.http.post(url, data);
        }
        return api.pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            console.error(res.message);
            return throwError(res.message);
        }), catchError(error => {
            this.alertService.error(error);
            return throwError(error);
        }));
    }
    deleteReturnablePackaging(data) {
        return this.http
            .delete(`${API_URL}/${environment.returnablePackagingBasePath}/${hexFromBase64(data._id)}`)
            .pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(error => {
            console.error(error);
            return of(null);
        }));
    }
    getReturnablePackaging(option) {
        let params = new HttpParams().set('page', option.pageIndex.toString()).set('size', option.pageSize.toString());
        if (option.filter && option.filter.hasOwnProperty('pattern')) {
            params = params.set('filter', option.filter.pattern);
        }
        return this.http
            .get(`${API_URL}/${environment.returnablePackagingBasePath}`, {
            params,
        })
            .pipe(map(res => {
            if (res.success) {
                return res;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of([]);
        }));
    }
    getWeightedProductTypes() {
        return this.http.get(`${API_URL}/${environment.productItemsPath}/weighted-types`).pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of([]);
        }));
    }
    getDefaultProductGroupColors() {
        return this.http.get(`${API_URL}/${environment.productGroupsPath}/default-group-colors`).pipe(map(res => {
            if (res.success) {
                return res.data;
            }
            return throwError(res.message);
        }), catchError(() => {
            return of({});
        }));
    }
    getDefaultProductGroupColorsDropdown() {
        return new Observable(subscriber => {
            const dropdown = [];
            let defaultColor = '';
            this.getDefaultProductGroupColors()
                .toPromise()
                .then(res => {
                for (const [key, value] of Object.entries(res)) {
                    dropdown.push({
                        value: value.colorHexCode,
                        description: `product_group.color.${value.colorName}`,
                        icon: 'folder',
                        iconStyle: { color: value.colorHexCode },
                    });
                    if (value.default) {
                        defaultColor = value.colorHexCode;
                    }
                }
                subscriber.next({
                    dropdown,
                    default: defaultColor,
                });
                subscriber.complete();
            });
        });
    }
}
CatalogItemsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogItemsService_Factory() { return new CatalogItemsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AlertsService)); }, token: CatalogItemsService, providedIn: "root" });
