import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { GraphOrganiserDialogComponent } from '../graph-organiser-dialog/graph-organiser-dialog.component';

/**
 * This component will show popup to organise all graphs visibility and visual
 * order.
 */
@Component({
  selector: 'app-graph-organiser',
  templateUrl: './graph-organiser.component.html',
  styleUrls: ['./graph-organiser.component.scss'],
})
export class GraphOrganiserComponent {
  /** Emitted when user clicks on saved button on graphs organiser dialog */
  @Output() updated = new EventEmitter();

  constructor(private matDialog: MatDialog) {}

  openOrganiser() {
    this.matDialog
      .open(GraphOrganiserDialogComponent, {
        minWidth: '60vw',
        disableClose: true,
      })
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.updated.emit();
        }
      });
  }
}
