import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductGroupRevenueForGraph } from '../interfaces/graph-api.interface';
import {
  GraphDisplayOption,
  GraphTimePeriod,
  GraphTimePeriodRange,
  GraphType,
  MultiGraphData,
  SingleGraphData,
} from '../interfaces/graph.interface';
import { GraphDataService } from '../services/graph-data/graph-data.service';
import { BaseRevenueGraphView } from './base-revenue.graph-view';

export class RevenueProductGroupsGraphView extends BaseRevenueGraphView {
  constructor() {
    super();
  }

  get title(): string {
    return _('graphs.revenue_product_groups');
  }

  get allowVisibilityChanged(): boolean {
    return true;
  }

  get graphType(): GraphType {
    return 'PIE_CHART';
  }

  get displayOption(): GraphDisplayOption {
    return {
      showLegend: true,
      showLabels: false,
      colorSchemeRandomized: false,
      colorScheme: ['#004c6a', '#00767b', '#1c9950', '#b6ab00', '#fa9207', '#eb6a19', '#d64024'],
    };
  }

  getTimePeriod(translateService: TranslateService): GraphTimePeriod {
    const startDay = moment().startOf('day');
    const endDay = moment().endOf('day');
    return {
      type: 'SELECT',
      ranges: [
        {
          name: translateService.instant(_('graphs.period.today')),
          from: startDay.toDate(),
          to: endDay.toDate(),
          singleDay: true,
        },
        {
          name: translateService.instant(_('graphs.period.week')),
          from: moment(startDay).subtract(1, 'weeks').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '2' }),
          from: moment(startDay).subtract(2, 'weeks').toDate(),
          to: endDay.toDate(),
        },
        {
          name: translateService.instant(_('graphs.period.weeks'), { value: '4' }),
          from: moment(startDay).subtract(12, 'weeks').toDate(),
          to: endDay.toDate(),
        },
      ],
    };
  }

  fetchData(
    graphDataService: GraphDataService,
    translateService: TranslateService,
    range: GraphTimePeriodRange
  ): Promise<SingleGraphData[] | MultiGraphData[]> {
    return new Promise<SingleGraphData[] | MultiGraphData[]>((resolve, reject) => {
      graphDataService
        .getRevenueByProductGroups(range.from, range.to)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(result => {
          if (result) {
            const graphData: SingleGraphData[] = this.limitAndReorderData<ProductGroupRevenueForGraph>(
              translateService,
              result
            ).map(res => {
              return {
                name:
                  res.name == null
                    ? translateService.instant(_('product_group.from_input'))
                    : res.isRoot === true
                    ? translateService.instant('common.default_group')
                    : res.name,
                value: res.revenue,
                extra: {
                  suffix: environment.defaultCurrencySymbol,
                },
              };
            });
            resolve(graphData);
          } else {
            reject([]);
          }
        });
    });
  }
}
