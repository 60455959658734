import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatTooltipModule,
} from '@angular/material';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CashRegisterStatusComponent } from './components/cash-register-status/cash-register-status.component';
import { FiscalizationStatusComponent } from './components/fiscalization-status/fiscalization-status.component';
import { GraphOrganiserDialogComponent } from './components/graph-organiser-dialog/graph-organiser-dialog.component';
import { GraphOrganiserComponent } from './components/graph-organiser/graph-organiser.component';
import { GraphOutletComponent } from './components/graph-outlet/graph-outlet.component';
import { GraphComponent } from './components/graph/graph.component';
import { InfoWidgetComponent } from './components/info-widget/info-widget.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { GraphFilterComponent } from './components/graph-filter/graph-filter.component';

@NgModule({
  declarations: [
    GraphComponent,
    GraphOrganiserComponent,
    GraphOutletComponent,
    GraphOrganiserDialogComponent,
    DashboardComponent,
    CashRegisterStatusComponent,
    FiscalizationStatusComponent,
    InfoWidgetComponent,
    GraphFilterComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule.forRoot(),
    TranslateModule,
    FormsModule,
    DragDropModule,
    NgxChartsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSelectModule,
    MatCardModule,
    MatProgressSpinnerModule,
  ],
  exports: [GraphComponent, GraphOrganiserComponent, GraphOutletComponent],
  entryComponents: [GraphOrganiserDialogComponent],
})
export class DashboardModule {}
