import * as tslib_1 from "tslib";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '@app/shared/helpers/local-storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { empty, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../devices/devices.service";
import * as i3 from "../company/company.service";
import * as i4 from "@angular/router";
const API_URL = environment.apiUrl;
const AUTHENTICATION_URL = `${environment.apiUrl}/${environment.authenticationPath}`;
const JWT_HELPER = new JwtHelperService();
export class AuthService {
    constructor(http, devicesService, companiesService, router) {
        this.http = http;
        this.devicesService = devicesService;
        this.companiesService = companiesService;
        this.router = router;
        // tslint:disable-next-line:variable-name
        this._user = null;
        const user = JSON.parse(getFromLocalStorage('user'));
        if (user) {
            this._user = user;
        }
    }
    get user() {
        if (this._user !== null) {
            return this._user;
        }
        return JSON.parse(getFromLocalStorage('user'));
    }
    get username() {
        const user = this.user;
        return user && user.username ? user.username : '';
    }
    get companyId() {
        return this.user && this.user.icpRefNo ? this.user.icpRefNo : null;
    }
    get userId() {
        return this.user && this.user.icpRefNo ? this.user.icpRefNo : null;
    }
    getDataFromToken(token, type = 'Bearer', byPhoneNumber = false) {
        const data = JWT_HELPER.decodeToken(token);
        return {
            icpRefNo: byPhoneNumber ? data.phoneNumber : data.icpRefNo,
            userDetails: data.userDetails,
            username: byPhoneNumber ? data.phoneNumber : data.sub,
            token,
            iat: data.iat,
            exp: data.exp,
            type,
        };
    }
    getAdminDataFromToken(token, type = 'Bearer') {
        const data = JWT_HELPER.decodeToken(token);
        return {
            icpRefNo: null,
            userDetails: data.userDetails,
            companyId: data.companyId,
            username: 'Admin',
            token: token,
            iat: data.iat,
            exp: data.exp,
            type: type,
        };
    }
    isAuthenticated() {
        if (this.user === null) {
            return false;
        }
        const userData = this.getDataFromToken(this.user.token);
        if (userData.userDetails !== '[USER]') {
            return false;
        }
        return !JWT_HELPER.isTokenExpired(this.user.token);
    }
    login(username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.loginProcedure({
                http: this.http,
                url: AUTHENTICATION_URL,
                username,
                password,
            }).toPromise();
            return Promise.resolve(result);
        });
    }
    loginAdmin(token) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = this.loginWithToken(token);
            return Promise.resolve(data);
        });
    }
    otpSendCodeToNumber(phoneNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.otpSendCodeToNumberProcedure(phoneNumber).toPromise();
            return Promise.resolve(result);
        });
    }
    otpValidateCode(phoneNumber, code) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.otpValidateCodeProcedure(phoneNumber, code).toPromise();
            return Promise.resolve(result);
        });
    }
    clear() {
        removeFromLocalStorage('user');
        this._user = null;
        this.companiesService.clear();
        this.devicesService.clear();
        return Promise.resolve(true);
    }
    logout() {
        this.clear();
        this.router.navigate(['/login']);
    }
    /**
     * Send a reset password request from the given username/email
     */
    resetPassword(email) {
        return this.http.patch(`${API_URL}/${environment.resetPasswordPath}`, { email });
    }
    setUser(data) {
        this._user = data;
        saveToLocalStorage('user', JSON.stringify(data));
    }
    loginProcedure(config) {
        const { url, http, password, username } = config;
        if (url && http && password && username) {
            return http.post(url, { username, password }).pipe(map(res => {
                if (res && res.accessToken) {
                    const user = this.getDataFromToken(res.accessToken, res.tokenType);
                    this.setUser(user);
                    return [true, user.icpRefNo, null];
                }
                return [false, null, null];
            }, catchError(error => {
                return [false, null, error];
            })));
        }
        return empty();
    }
    loginWithToken(token) {
        const user = this.getAdminDataFromToken(token);
        if (user && user.companyId && user.userDetails === '[ADMIN]') {
            this.setUser(user);
            return [true, { companyId: user.companyId }];
        }
        return [false, null];
    }
    otpSendCodeToNumberProcedure(phoneNumber) {
        if (phoneNumber) {
            return this.http.post(`${API_URL}/${environment.authSendSms}`, { phone_number: phoneNumber }).pipe(map(res => {
                if (res['success'] === true) {
                    return { success: true };
                }
                return { success: false };
            }, catchError(error => {
                console.error(error);
                return of({ success: false });
            })));
        }
        return of({
            success: false,
        });
    }
    otpValidateCodeProcedure(phoneNumber, code) {
        if (code) {
            return this.http
                .post(`${API_URL}/${environment.authVerifyCode}`, { phone_number: phoneNumber, otp: code })
                .pipe(map(res => {
                if (res['success'] === true && res['credentials'] && res['credentials']['accessToken']) {
                    const { accessToken, tokenType } = res['credentials'];
                    const user = this.getDataFromToken(accessToken, tokenType, true);
                    this.setUser(user);
                    return { success: true, phoneNumber: user.icpRefNo };
                }
                console.error(res);
                return { success: false };
            }, catchError(error => {
                console.error(error);
                return of({ success: false });
            })));
        }
        return of({
            success: false,
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DevicesService), i0.ɵɵinject(i3.CompanyService), i0.ɵɵinject(i4.Router)); }, token: AuthService, providedIn: "root" });
