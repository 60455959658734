import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { BaseGraphView } from '@app/dashboard/graphs/base.graph-view';
import {
  GraphAdditionalFilter,
  GraphDisplayOption,
  GraphPieChartLegend,
  GraphTimePeriod,
  GraphTimePeriodRange,
  MultiGraphData,
  OrganisedGraphData,
  SingleGraphData,
} from '@app/dashboard/interfaces/graph.interface';
import { GraphDataService } from '@app/dashboard/services/graph-data/graph-data.service';
import { GraphOrganiserService } from '@app/dashboard/services/graph-organiser/graph-organiser.service';
import { BaseComponent } from '@app/shared/bases/base.component';
import { ResizedEvent } from '@app/shared/directives/resized.directive';
import { isEmpty } from '@app/shared/helpers/helpers';
import { FormDropdown } from '@app/shared/interfaces/common.interface';
import { TranslateService } from '@ngx-translate/core';
import { DataItem } from '@swimlane/ngx-charts';

/**
 * Generic component to render graphs
 */
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent extends BaseComponent implements OnInit {
  /** OrganisedGraphData data which should be fetched (organised) from localStorage */
  @Input() organisedGraphData: OrganisedGraphData;

  /** Emit the graph data when selected */
  @Output() selected = new EventEmitter<DataItem>();

  /** Current instance of BaseGraphView, taken from `organisedGraphData` */
  graphView: BaseGraphView;

  /** Graph's display option */
  option: GraphDisplayOption;

  /** additional filters configuration */
  graphAdditionalFilters: GraphAdditionalFilter[];

  /**
   * Single or multi series graph data according to the graph type.
   * Both VERTICAL_BAR and HORIZONTAL_BAR GraphType are using single series
   * data. The rest are using multi series data.
   */
  data: SingleGraphData[] | MultiGraphData[] = [];

  /** current active filters being set on this graph */
  filters: { [key: string]: string } = {};

  /** graph's width (optional). If not set, it will use responsive width. */
  graphWidth: number;

  /** default graph's height in pixels */
  graphHeight = 400;

  /** width breakpoint in pixels that will be used for triggering 'small' CSS class on graph's wrapper */
  smallBreakpoint = 500;

  /** is graph on 'small' CSS */
  smallWidth = false;

  /** width breakpoint in pixels that will be used for triggering 'x-small' CSS class on graph's wrapper */
  extraSmallWidth = false;

  /** is graph on 'x-small' CSS */
  extraSmallBreakpoint = 320;

  /** graph time period's type and ranges available on this graph */
  timePeriod: GraphTimePeriod;

  /** currently selected time period range for fetching data */
  currentTimePeriodRange: GraphTimePeriodRange;

  /** FormControl handler for time period dropdown */
  selectPeriodControl: FormControl;

  /** Dropdown data for `selectPeriodControl` */
  selectPeriodData: FormDropdown[] = [];

  /** custom legend data for PieChart */
  legendData: GraphPieChartLegend[] = [];

  /** default color scheme to build the graph elements (ex: bars or pie chart) */
  colorScheme: any;

  /** returns true if there is data to be rendered */
  get hasData(): boolean {
    return this.graphView.graphType === 'TABLE' ? true : this.data && this.data.length > 0;
  }

  constructor(
    private translateService: TranslateService,
    private graphDataService: GraphDataService,
    private graphOrganiserService: GraphOrganiserService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    // get BaseGraphView instance from the given OrganisedGraphData
    this.graphView = this.organisedGraphData.graphView;

    // merges given graph's option with default
    this.option = this.graphView.normalizedDisplayOption;

    // initiates stuff before calling onInit() on BaseGraphView instance
    this.initColorScheme();
    this.initTimePeriod();
    this.initFilters();

    // calls `onInit()` on BaseGraphView instance
    this.graphView.onInit(this.graphDataService, this.translateService, this.currentTimePeriodRange);

    // finally, fetch some data
    this.loadData();
  }

  private initFilters() {
    // get additional filters (apart from time period filter) if any
    this.graphAdditionalFilters = this.graphView.getAdditionalFilters(
      this.organisedGraphData,
      this.graphDataService,
      this.graphOrganiserService,
      this.translateService
    );

    // get widget filters data from localStorage
    const filtersData = this.graphOrganiserService.getGraphsFilterById(this.organisedGraphData.id);
    this.filters = filtersData ? filtersData.filters : {};

    // set the current time period to use the first available time range
    this.currentTimePeriodRange = this.timePeriod.ranges[0];

    if (this.timePeriod.type === 'SELECT') {
      this.selectPeriodData = [];
      this.timePeriod.ranges.forEach((item, index) => {
        this.selectPeriodData.push({
          value: index,
          description: item.name,
        });
      });

      // init control for time period dropdown
      this.selectPeriodControl = new FormControl();
      if (isEmpty(this.filters)) {
        this.selectPeriodControl.setValue(0);
      } else {
        const timeIndex = +this.filters.time;
        if (timeIndex != null && timeIndex <= this.timePeriod.ranges.length) {
          this.currentTimePeriodRange = this.timePeriod.ranges[timeIndex];
          this.selectPeriodControl.setValue(timeIndex);
        }
      }
    }
  }

  /**
   * Init color scheme for this graph with option to randomize them
   */
  private initColorScheme(): any {
    this.colorScheme = this.graphDataService.getDefaultColorScheme(
      this.option.colorScheme,
      this.option.colorSchemeRandomized
    );
  }

  /**
   * Set supported time period from BaseGraphView instance
   */
  private initTimePeriod() {
    this.timePeriod = this.graphView.getTimePeriod(this.translateService);
  }

  /**
   * Event handler when additional graph's filter is changed
   */
  onAdditionalFilterChanged(graphFilter: GraphAdditionalFilter, value: any) {
    this.filters[graphFilter.id] = value;
    this.graphOrganiserService.saveGraphsFilter(this.organisedGraphData.id, graphFilter.id, value);
    this.loadData();
  }

  /**
   * Load data by calling fetchData() on the BaseGraphView instance of this graph
   */
  loadData() {
    if (this.graphView != null) {
      const timePeriod = this.currentTimePeriodRange != null ? this.currentTimePeriodRange : this.timePeriod.ranges[0];
      this.graphView.finished = false;
      this.graphView
        .fetchData(this.graphDataService, this.translateService, timePeriod, this.filters)
        .then(data => {
          if (this.graphView.graphType !== 'TABLE' && data) {
            this.data = data;
            this.buildLegendData(data);
          }
          this.graphView.finished = true;
        })
        .catch(err => {
          console.log(err);
          this.data = [];
          this.graphView.finished = true;
        });
    }
  }

  /**
   * Build custom legend, for example on PieChart
   * @param data
   */
  private buildLegendData(data: SingleGraphData[] | MultiGraphData[]) {
    this.legendData = [];

    let total = 0;
    data.forEach((item: SingleGraphData | MultiGraphData) => {
      if (item.hasOwnProperty('value') != null) {
        item = item as SingleGraphData;
        total += +item.value;
      }
    });

    data.forEach((item: SingleGraphData | MultiGraphData, index: number) => {
      if (item.hasOwnProperty('value') != null) {
        item = item as SingleGraphData;
        this.legendData.push({
          color: this.colorScheme.domain[index],
          name: item.name,
          value: item.value,
          percentage: (+item.value / total) * 100,
          extra: item.extra,
        });
      }
    });
  }

  /**
   * Event handler when time period is changed
   * @param event
   */
  onSelectPeriodChanged(event: MatSelectChange) {
    this.currentTimePeriodRange = this.timePeriod.ranges[event.value];
    this.filters.time = event.value;
    this.graphOrganiserService.saveGraphsFilter(this.organisedGraphData.id, 'time', event.value);
    this.loadData();
  }

  onSelect(data: DataItem) {
    this.selected.emit(data);
  }

  onContainerResized(event: ResizedEvent) {
    if (this.graphView.graphType === 'PIE_CHART' && this.option.showLegend) {
      if (event.newWidth > this.smallBreakpoint) {
        this.graphWidth = event.newWidth / 2.5;
        this.graphHeight = this.graphWidth;
      } else {
        this.graphWidth = event.newWidth;
        this.graphHeight = 200;
      }
    } else {
      this.graphWidth = event.newWidth;
    }

    this.smallWidth = event.newWidth <= this.smallBreakpoint && event.newWidth >= this.extraSmallBreakpoint;
    this.extraSmallWidth = event.newWidth < this.extraSmallBreakpoint;
  }
}
