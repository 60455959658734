<!-- <app-title [title]="title"></app-title> -->
<app-navbar (onMenuIconClick)="toggleMenu()" [menuOpened]="menuOpened$ | async"> </app-navbar>
<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer
    #drawer
    class="sidenav"
    fixedInViewport="true"
    fxLayout="column"
    fixedTopGap="64"
    (openedChange)="menuOpenedChanged($event)"
    [attr.role]="isHandset ? 'dialog' : 'navigation'"
    [mode]="isHandset ? 'over' : 'side'"
    [opened]="!isHandset"
    [autoFocus]="true"
  >
    <div class="menu-items-wrapper">
      <app-left-sidebar
        #sidebar
        [menu]="menuItems"
        (menuClicked)="onSidebarMenuClicked($event)"
        class="menu-items"
      ></app-left-sidebar>
      <app-devices class="devices-selector"></app-devices>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <h1 class="page-title" *ngIf="pageTitleMode === 'body'">{{ title$ | async }}</h1>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
