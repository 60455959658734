import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  supscription: Subscription
  currentRoute = '/'

  constructor(
    private router: Router,
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
      if (navEnd.urlAfterRedirects !== '/'
        && navEnd.urlAfterRedirects.startsWith('/dashboard')
        && navEnd.urlAfterRedirects.startsWith('/empty')) {
        this.currentRoute = navEnd.urlAfterRedirects;
      }
    });
  }

  reloadPage() {
    if (this.currentRoute !== '/'
      && this.currentRoute.startsWith('/dashboard')
      && this.currentRoute.startsWith('/empty')) {
      this.router.navigate(environment.reloadRoute, { skipLocationChange: true })
      this.router.navigate([this.currentRoute], { skipLocationChange: true });
    }
  }
}
