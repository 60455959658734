import * as tslib_1 from "tslib";
import { environment } from 'src/environments/environment';
import { getFromLocalStorage } from './local-storage';
export function languageLoader(translate) {
    return tslib_1.__awaiter(this, void 0, void 0, function* () {
        const code = getCurrentLanguageCode();
        translate.setDefaultLang(code);
        yield translate.use(code);
    });
}
export function getCurrentLanguageCode() {
    const code = getFromLocalStorage('lang') || environment.defaultLanguage;
    return code;
}
